import React, { useEffect, useState } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  Form
} from "reactstrap";
import SimpleHeader from 'components/Headers/SimpleHeader';
import Table from "components/common/CustomTable"
import { useForm, Controller } from "react-hook-form";
import Spinner from '../../components/Spinner';
import useApi from '../../hooks/useApi';
import env from "env";
import calculator from 'api/calculator';
import useAlert from 'hooks/useAlert';
import { useLocation, useParams } from 'react-router';
import TextPopup from 'components/common/TextModal';

const Caclulator = () => {
  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();

  const params = useParams();
  const location = useLocation();

  // states
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [rowId, setRowId] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);

  // apis
  const getAllCalculators = useApi(calculator.getClaculators);
  const createCalculator = useApi(calculator.createCalculator);
  const deleteCalculator = useApi(calculator.deleteCalculator);

  // form validation
  const { handleSubmit, formState: { errors }, control, watch, reset } = useForm();

  const headers = ["actions", "Country", "Price", "price value", "exchange", "price type"];

  const columns = ["countryText", "priceName", "priceValue", "priceExChange", "priceTypeName"];
  
  const preload = async () => {
    const res = await getAllCalculators.request('transfer');
    if (res.status === 200) {
      res.data.data = res.data.data.map(calc => editResponse(calc))
      setData(res.data.data);      
    } 
  }

  const createCalc = async formData => {
    setSpinner(true);
    const schema = {
      ...formData,
      priceValue: +formData.priceValue
    }

    const res = await createCalculator.request('transfer', schema);

    if (res.status === 200) {

      res.data.data = editResponse(res.data.data);
      const newData = [res.data.data, ...data];

      setData(newData);
      sweetAlert("Done");
    }
    setSpinner(false);
  }

  // This method create new instance with enum string value
  const editResponse = obj => {
    obj.priceTypeName = env.priceType[obj.priceType - 1];
    obj.priceName = env.price[obj.price - 1];
    obj.countryText = env.country[obj.country - 1];
    obj.unit = obj.shipmentType === 1 ? "kg" : "cbm";
    obj.shippingTypeText = env.shippingType[obj.shippingType - 1];
    obj.priceRangeText = env.priceRange[obj.priceRange - 1];
    obj.priceRangeMedicalText = env.priceRangeMedical[obj.priceRangeMedical - 1];
    return obj;
  }
  
  const handleDelete = obj => {
    setRowId(obj.id);
    setDeleteModal(true);
  }

  const handleCancel = () => {
    setRowId('');
    setDeleteModal(false);
  }

  const deleteRow = async () => {
    setDeleteModal(false);
    setSpinner(true);
    const res = await deleteCalculator.request('transfer', rowId)
    
    if (res.status === 200) {
      sweetAlert("Done");
      const newData = data.filter(item => item.id !== rowId)
      setData(newData);
    }
    setRowId('');
    setSpinner(false);
  }

  useEffect(() => {
    reset();
    preload();
  }, [location])

  return (
    <div>
      {alert}
      {getAllCalculators.errorAlert}
      {createCalculator.errorAlert}
      {deleteCalculator.errorAlert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      {getAllCalculators.loading ? <Spinner
        gate="#29bb64"
        bg="#fff"
      /> : <>
      <SimpleHeader parents={["Transaction"]} />
      <Container className="mt--6" fluid>
        <Card className="card-plain">
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>Transaction</h3>
            </div>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(createCalc)}>
              <Row>
                <Col md="6" lg='4'>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      Country
                    </label>
                    <Controller
                      control={control}
                      name="country"
                      rules={{ required: "Name field is required" }}
                      render={({ field: { ref, onChange, value, ...field } }) => (
                        <Input
                          {...field}
                          id="example3cols1Input"
                          placeholder="Name"
                          type="select"
                          className={errors.country && "error"}
                          defaultValue={true}
                          onChange={({ target: { value }}) => onChange(Number(value))}
                        >
                          <option value={true} disabled>-- select option --</option>
                          {env.country.map((item, i) => <option value={i+1} key={i}>{item}</option>)}
                        </Input>
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg='4'>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="price-type"
                    >
                      Price type
                    </label>
                    <Controller
                      control={control}
                      name="priceType"
                      rules={{ required: "Name field is required" }}
                      render={({ field: { ref, onChange, value, ...field } }) => (
                        <Input
                          {...field}
                          id="price-type"
                          placeholder="Name"
                          type="select"
                          className={errors.priceType && "error"}
                          defaultValue={true}
                          onChange={({ target: { value }}) => onChange(Number(value))}
                        >
                          <option value={true} disabled>-- select option --</option>
                          {env.priceType.map((item, i) => <option value={i+1} key={i}>{item}</option>)}
                        </Input>
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg='4'>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="price"
                    >
                      Price
                    </label>
                    <Controller
                      control={control}
                      name="price"
                      rules={{ required: "price field is required" }}
                      render={({ field: { ref, onChange, value, ...field } }) => (
                        <Input
                          {...field}
                          id="price"
                          type="select"
                          defaultValue={true}
                          className={errors.price && "error"}
                          onChange={({ target: { value }}) => onChange(Number(value))}
                        >
                          <option value={true} disabled>-- select option --</option>
                          {env.price.map((item, i) => <option value={i+1} key={i}>{item}</option>)}
                        </Input>
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg='4'>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="price-value"
                    >
                      Price value
                    </label>
                    <Controller
                      control={control}
                      name="priceValue"
                      rules={{
                        required: "Price value field is required",
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/
                        },
                      }}
                      render={({ field: { ref, onChange, value, ...field } }) => (
                        <Input
                          {...field}
                          id="price-value"
                          placeholder="price"
                          type="number"
                          className={errors.priceValue && "error"}
                          value={value || ""}
                          onChange={({ target: { value }}) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg='4'>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exchange"
                    >
                      Exchange
                    </label>
                    <Controller
                      control={control}
                      name="priceExChange"
                      rules={{
                        required: "exchange value field is required",
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/
                        },
                      }}
                      render={({ field: { ref, onChange, value, ...field } }) => (
                        <Input
                          {...field}
                          id="exchange"
                          placeholder="price"
                          type="number"
                          className={errors.priceExChange && "error"}
                          value={value || ""}
                          onChange={({ target: { value }}) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button 
                color="success" 
                type="submit"
              >
                <span className="btn-inner--icon mr-1">
                  <i className="ni ni-fat-add" />
                </span>
                <span>
                  Create
                </span>
              </Button>
            </Form>
          </CardBody>
        </Card>
        <Card>
          <Table 
            headers={headers}
            columns={columns}
            data={data}
            handleDelete={handleDelete}
          >
            <span className='me-1' fun="handleDelete">
              <div id="delete" className="table-action cursor-pointer">
                <i className="fas fa-trash hover-danger fa-lg" />
              </div>
              <UncontrolledTooltip
                delay={0}
                target="delete"
              >
                Delete
              </UncontrolledTooltip>
            </span>
          </Table>
        </Card>
        <TextPopup
          modal={deleteModal}
          text="Yes"
          handleCancel={handleCancel}
          fn={deleteRow}
          color='danger'
        >
          <Container>
            <h2>Are you sure you want to delete this invoice ?</h2>
          </Container>
        </TextPopup>
      </Container>
      </>}
    </div>
  )
}

export default Caclulator