import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import SimpleHeader from 'components/Headers/SimpleHeader';
import Table from "components/common/CustomTable";
import useApi from '../../hooks/useApi';
import Spinner from '../../components/Spinner';
import { useForm, Controller } from "react-hook-form";
import TextPopup from 'components/common/TextModal';
import PopupTable from "components/common/PopupTableUsed";
import usersPassword from 'api/usersPassword';
import Pagination from "components/common/Pagination";
import useAlert from 'hooks/useAlert';
import useSearchPopup from 'hooks/useSearchPopup';
import SearchModal from 'components/common/SearchModal';

function GeneratePassword() {

  const headers = ["name", "Customer name", "password", "date"];
  const columns = ["userName", "customerName", "password", "date"];

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();

  // States
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [tableModal, setTableModal] = useState(false);
  const [customer, setCustomer] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [docsPerPage, setDocsPerPage] = useState(10);

  // Apis
  const preload = useApi(usersPassword.preload);
  const generate = useApi(usersPassword.generatePass);
  const getByColumn = useApi(usersPassword.getByColumn);

  const editResponse = res => {
    res.date = res.date.split('T')[0];
    return res;
  }

  // Use form hook
  const { handleSubmit, formState: { errors }, control, setValue } = useForm();
  const searchPopup = useSearchPopup(getByColumn, headers, columns, data.result?.posts, editResponse);

  const userPasswordPreload = async () => {
    const res = await preload.request(pageNo, docsPerPage);
    if (res.status === 200) {
      const customers = res.data.data[0].result.posts;
      res.data.data[0].result.posts = customers.map(customer => editResponse(customer))

      setData(res.data.data[0])
    }
  }

  const generatePassword = async formData => {
    const schema = new FormData();
    schema.append("CustomerId", customer.cusId)
    schema.append("password", formData.password);
    // const schema = {
    //   CustomerId: user.current,
    //   ...formData,
    // }

    const res = await generate.request(schema)
    if (res.status === 200) 
      sweetAlert("Success");
  }

  const updateData = obj => {

    const editedResponse = obj.map(response => editResponse(response))

    setData(data => ({
      ...data,
      data: editedResponse
    }))
  }

  useEffect(() => {
    userPasswordPreload();
  }, [pageNo, docsPerPage])

  useEffect(() => {

    if (searchPopup.isSelected && searchPopup.searchField) {
      searchPopup.onSearch('', () => {});
    } else {
      searchPopup.setIsSelected(false);
    }
  }, [searchPopup.pageNo, searchPopup.docsPerPage])

  const handleCancel = () => {
    setTableModal(false);
  }

  useEffect(() => {
    if (generate.data?.success === true) {
      userPasswordPreload();
    }
  }, [generate.data])

  return (
    <div>
      {alert}
      {preload.errorAlert}
      {generate.errorAlert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      {preload.loading ? <Spinner
          gate="#29bb64"
          bg="#fff"
        />
        : <>
      <SimpleHeader parents={["Generate Password"]} />
      <Container className="mt--6" fluid>
        <Card className="card-plain">
          <div className="d-flex justify-content-between">
            <h3 className={`mb-0 mt-1 ms-2 text-md-left`}>Generate Password</h3>
          </div>
          <CardBody>
            <form onSubmit={handleSubmit(generatePassword)}>
              <Row>
              <Col md="6" lg='4'>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      Client
                    </label>
                    <Controller
                      control={control}
                      name="client"
                      rules={{ required: "Client field is required" }}
                      render={({ field: { ref, onChange, value, ...field } }) => (
                        <Input
                          {...field}
                          id="example3cols1Input"
                          placeholder="Client"
                          type="text"
                          className={errors.client && "error"}
                          value={value || ""}
                          onChange={({ target: { value }}) => onChange(value)}
                          onClick={() => setTableModal(true)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg='4'>
                <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      Password
                    </label>
                    <InputGroup
                      className={`input-group-merge input-group-alternative ${errors.password && "error"}`}
                    >
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Controller
                        control={control}
                        name="password"
                        rules={{ 
                          required: "Password field is required", 
                          minLength: {
                            value: 8,
                            message: "Password at least should be 8 characters"
                          }
                        }}
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <Input
                            placeholder="Password"
                            type={ showPassword ? "text" : "password"}
                            onChange={({ target: { value }}) => onChange(value)}
                          />
                        )}
                      />
                      <Button color="secondary" outline type="button" onClick={() => setShowPassword(!showPassword)} >
                      <i className={ showPassword ? "fas fa-eye" : "fas fa-eye-slash" }></i>
                      </Button>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Button 
                color="success" 
                type="submit"
                disabled={!customer}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="ni ni-fat-add" />
                </span>
                <span>
                  Generate
                </span>
              </Button>
            </form>
          </CardBody>
        </Card>
        <Card>
          <Pagination
            dataLength={data.result?.posts?.length}
            // when the user search using the popup and select a row
            // after then only use the pagination states from searchPopup hook
            pageNo={searchPopup.isSelected ? searchPopup.pageNo : pageNo} 
            setPageNo={searchPopup.isSelected ? searchPopup.setPageNo : setPageNo}
            docsPerPage={searchPopup.isSelected ? searchPopup.docsPerPage : docsPerPage}
            setDocsPerPage={searchPopup.isSelected ? searchPopup.setDocsPerPage : setDocsPerPage}
          >
            <Table 
              headers={headers}
              columns={columns}
              // when the user search using the popup and select a row
              // after then only use the hookData states from searchPopup hook
              data={searchPopup.isSelected ? searchPopup.hookData : data.result?.posts}
              onHeaderClick={searchPopup.handleHeaderClicked}
            />
          </Pagination>
        </Card>
      </Container>
      <TextPopup
        modal={tableModal}
        text="Next"
        handleCancel={handleCancel}
        fn={() => {}}
        color='primary'
        noBtn
      >
        <Container>
          <PopupTable 
            setCustomer={setCustomer}
            setTableModal={setTableModal}
            setValue={setValue}
            sweetAlert={sweetAlert}
          />
        </Container>
      </TextPopup>
      {searchPopup.openPopup && 
        <SearchModal
          data={data.result?.posts}
          setData={setData}
          updateData={updateData}
          searchPopup={searchPopup}
          editResponse={editResponse}
          page="generatePassword"
        />
      }
      </>}
    </div>
  )
}

export default GeneratePassword