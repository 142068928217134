import React, { useRef } from 'react'
import { Button, Input } from 'reactstrap';

const FilePicker = ({ images, setImages, multiple = true }) => {
  
  const imgRef = useRef();

  const handlePaste = e => {
    if (! e.clipboardData.files.length) return;
    if (!multiple) setImages(Object.keys(e.clipboardData.files).slice(0,1).map(key => ({[key]:e.clipboardData.files[key]})));
    else setImages(e.clipboardData.files);
  }

  return (
    <div className='input-label mb-3 pl-2 d-flex align-items-start'>
      <input 
        id="file-input"
        type="file" 
        multiple={multiple} 
        className='mb-4 d-none' 
        ref={imgRef}
        onChange={e => setImages(e.target.files)}
      />
      <Button 
        className='mr-2 mb-3' 
        onClick={() => imgRef.current.click()}
      >
        Choose file
      </Button>
      <span>
        <Input 
          id="file-picker"
          type="text" 
          onPaste={handlePaste}
          onChange={() => {}}
          value={`${multiple ? Object.keys(images).length : Object.keys(images).length } files selected`}
        />
      </span>
    </div>
  )
}

export default FilePicker