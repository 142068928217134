import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import SimpleHeader from 'components/Headers/SimpleHeader';
import Table from "components/common/CustomTable";
import Pagination from "components/common/Pagination";
import Spinner from '../../components/Spinner';
import useApi from '../../hooks/useApi';
import customer from 'api/customer';
import useAlert from 'hooks/useAlert';

function Customers() {

  // Apis
  const customers = useApi(customer.customers);
  const findByName = useApi(customer.findByName);

  // states
  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [client, setClient] = useState('');
  const [docsPerPage, setDocsPerPage] = useState(10);

  
  const getAllCustomers = async () => {
    const res = await customers.request(pageNo, docsPerPage);
    if (res.status === 200) {
      setData(res.data.data.data);
    }
  }

  const findClientByName = async () => {
    
    // if search filed is empty get the normal request
    if (!client) return getAllCustomers();

    const res = await findByName.request(client, pageNo, docsPerPage);
    if (res.status === 200) {
      setData(res.data.data.posts)
    }
  }

  const headers = ["code", "name", "phone"];
  const columns = ["cusCode", "cusName", "phone"];

  useEffect(() => {
    if (client.length) findClientByName();
    else getAllCustomers();
  }, [pageNo, docsPerPage])

  return (
    <div>
      {customers.errorAlert}
      {findByName.errorAlert}
      {customers.loading ? <Spinner
        gate="#29bb64"
        bg="#fff"
      />
      : <>
        <SimpleHeader parents={["Customers"]} />
        <Container className="mt--6" fluid>
          <Card>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 mt-1 ms-2 text-md-left`}>Customers</h3>
            </div>
            <div className='padding-around pb-0'>
            <Row>
              <Col xs="4" className='pb-0 mb-0 mt-4'>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    Customer name
                  </label>
                  <Input
                    id="example3cols1Input"
                    placeholder="search"
                    type="text"
                    value={client}
                    onChange={e => setClient(e.target.value) }
                    onKeyPress={(e) => e.key === 'Enter' && findClientByName()}
                  />
                </FormGroup>
              </Col>
              <span style={{paddingTop: 7, alignSelf: "center"}}>
                <Button 
                  color="success" 
                  type="button"
                  className='mt-4'
                  onClick={findClientByName}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-search" />
                  </span>
                  <span>
                    Search
                  </span>
                </Button>
              </span>
            </Row>
            </div>
            <Pagination
              pageNo={pageNo} 
              setPageNo={setPageNo}
              docsPerPage={docsPerPage}
              setDocsPerPage={setDocsPerPage}
              dataLength={data.length}
            >
              <Table 
                headers={headers}
                columns={columns}
                data={data || []}
              />
            </Pagination>
          </Card>
        </Container>
      </>}
    </div>
  )
}

export default Customers