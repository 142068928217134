import client from './client';

const getEndpoint = '/v1/News/Data/Calculater';
const endpoint = '/v1/News/Calculater';
const editEndPoint ="/v1/News/Calculate";

const calculators = (page, docsPerPage) => client.get(`${getEndpoint}?Page=${page}&PostsPerPage=${docsPerPage}`);

const createClaculator = schema => client.post(`${endpoint}`, schema);

const editCalculator = (id, schema) => client.put(`${editEndPoint}/${id}`, schema);

const getClaculators = shipmentType => client.get(`/v1/${shipmentType}price`);

const createCalculator = (shipmentType, schema) => client.post(`v1/${shipmentType}price`, schema);

const deleteCalculator = (shipmentType, id) => client.delete(`v1/${shipmentType}price/${id}`);

export default {
  calculators,
  createClaculator,
  editCalculator,
  getClaculators,
  createCalculator,
  deleteCalculator
}