export default
    {
        arLanguage: {
            ar: "العربية",
            en: "Ar"
        },

        enLanguage: {
            ar: "الإنجليزية",
            en: "En"
        },

        new: {
            ar: "جديد",
            en: "New",
        },

        folder: {
            ar: "مجلد",
            en: "Folder"
        },

        newFolder: {
            ar: "مجلد جديد",
            en: "New folder"
        },

        showDetails: {
            en: "Show details"
        },

        fileUpload: {
            ar: "تحميل ملف",
            en: "File upload"
        },

        update: {
            en: "Update"
        },

        folderUpload: {
            ar: "تحميل مجلد",
            en: "Folder upload"
        },

        search: {
            ar: "بحث",
            en: "Search"
        },

        align: {
            ar: "left",
            en: "right"
        },

        alignt: {
            ar: "right",
            en: "left"
        },

        close: {
            ar: "إغلاق",
            en: "Close"
        },

        cancel: {
            ar: "إلغاء",
            en: "Cancel"
        },

        create: {
            ar: "إنشاء",
            en: "Create"
        },

        folderName: {
            ar: "إسم المجلد",
            en: "Folder name"
        },

        fileName: {
            ar: "إسم الملف",
            en: "File name"
        },

        uploadTracking: {
            ar: "تتبع التحميل",
            en: "Upload tracking"
        },

        upload: {
            ar: " تحميل",
            en: "Upload"
        },

        file1: {
            ar: "ملف 1",
            en: "File 1"
        },

        file2: {
            ar: "ملف 2",
            en: "File 2"
        },

        chooseFile: {
            ar: "اختر ملف",
            en: "Choose file"
        },

        chooseFiles: {
            ar: "اختر ملف",
            en: "Choose files"
        },
        title: {
            ar: "ملفات قابلة للمشاركة",
            en: "Shareable Files"
        },

        alignt: {
            ar: "right",
            en: "left",
        },

        lead: {
            ar: "هذه الشاشة موجودة لحماية حسابك، في حالة خروجك مؤقتا من النظام او عدم استعماله لفترة من الزمن او الخروج منه، رجائا قم بإدخال كملة المرور مجددا اذا كنت تريد الدخول الى النظام، او الضغط على خيار لست انا ذا كنت تريد الدخول بحساب أخر",
            en: "This screen is there to protect your account, if you are temporarily logged out of the system or not using it for a period of time or exit from it, please re enter youre password if you want to enter the system, or click on the “Not me” option if you want to log in with another account"
        },

        fileName: {
            ar: "إسم الملف",
            en: "File name"
        },

        filesIveCreated: {
            ar: "ملفات قمت بإنشائها ",
            en: "Files I've Created"
        },

        datecreated: {
            ar: "تاريخ الإنشاء",
            en: "Date created",
        },

        size: {
            ar: "الحجم",
            en: "Size",
        },

        owner: {
            ar: "المنشئ",
            en: "Owner",
        },

        lastModified: {
            ar: "آخر تعديل",
            en: "Last modified",
        },

        language: {
            ar: "ar",
            en: "en",
        },

        align: {
            ar: "left",
            en: "right",
        },

        yes: {
            ar: "نعم",
            en: "Yes",
        },

        no: {
            ar: "لا",
            en: "No",
        },


        add: {
            ar: 'إضافة',
            en: 'Add'
        },
        search: {
            ar: 'بحث',
            en: 'search'
        },

        copy: {
            ar: 'نسخ',
            en: 'Copy',
        },

        print: {
            ar: 'طباعة',
            en: 'Print',
        },

        edit: {
            ar: 'تعديل',
            en: 'Edit',
        },

        deleting: {
            ar: 'حذف',
            en: 'Delete',
        },
        
        delete: {
            ar: 'حذف',
            en: 'Delete'    
        },

        move: {
            ar: 'نقل',
            en: 'Move'
        },

        copy: {
            ar: 'نسخ',
            en: 'Copy'    
        },

        download: {
            ar: 'تنزيل',
            en: 'Download'
        },

        done: {
            ar: "",
            en: "Done"
        },

        view: {
            ar: "",
            en: "View"
        },

        approve: {
            en: "Approve"
        },

        printTip: {
            ar: "سيؤدي هذا إلى فتح صفحة طباعة بالصفوف المرئية من الملف",
            en: "This will open a print page with the visible rows of the table."
        },

        copyTip: {
            ar: "سيؤدي هذا إلى نسخ الصفوف المرئية من الجدول",
            en: "This will copy to your clipboard the visible rows of the table.",
        },

        resetTip: {
            ar: "سيؤدي هذا إلى إعادة الخانات للوضع السابق",
            en: "This will reset all the fields above.",
          },

        reset: {
        ar: "إعادة تعيين",
        en: "Reset",
        },

        alertMes1: {
            ar: "! أحسنت",
            en: "Good job!",
        },

        alertMes2: {
            ar: "! تم النسخ",
            en: "Copied to clipboard!",
        },

        show: {
            ar: "عرض",
            en: "Show",
        },

        entries: {
            ar: "إدخالات",
            en: "entries",
        },
        
        title: {
            ar: "أنواع الملفات ",
            en: " File Types"
        },

        alignt: {
            ar: "right",
            en: "left",
        },

        lead: {
            ar: "هذه الشاشة موجودة لحماية حسابك، في حالة خروجك مؤقتا من النظام او عدم استعماله لفترة من الزمن او الخروج منه، رجائا قم بإدخال كملة المرور مجددا اذا كنت تريد الدخول الى النظام، او الضغط على خيار لست انا ذا كنت تريد الدخول بحساب أخر",
            en: "This screen is there to protect your account, if you are temporarily logged out of the system or not using it for a period of time or exit from it, please re enter youre password if you want to enter the system, or click on the “Not me” option if you want to log in with another account"
        },

        fileName: {
            ar: "إسم الملف",
            en: "File name"
        },

        filesIveCreated: {
            ar: "ملفات قمت بإنشائها ",
            en: "Files I've Created"
        },

        datecreated: {
            ar: "تاريخ الإنشاء",
            en: "Date created",
        },

        size: {
            ar: "الحجم",
            en: "Size",
        },

        owner: {
            ar: "المنشئ",
            en: "Owner",
        },

        lastModified: {
            ar: "آخر تعديل",
            en: "Last modified",
        },

        align: {
            ar: "left",
            en: "right",
        },

        yes: {
            ar: "نعم",
            en: "Yes",
        },

        no: {
            ar: "لا",
            en: "No",
        },


        add: {
            ar: 'إضافة',
            en: 'Add'
        },
        
        search: {
            ar: 'بحث',
            en: 'search'
        },

        copy: {
            ar: 'نسخ',
            en: 'Copy',
        },

        print: {
            ar: 'طباعة',
            en: 'Print',
        },

        deleting: {
            ar: 'حذف',
            en: 'Delete',
        },

        alertMes1: {
            ar: "! أحسنت",
            en: "Good job!",
        },

        alertMes2: {
            ar: "! تم النسخ",
            en: "Copied to clipboard!",
        },

        show: {
            ar: "عرض",
            en: "Show",
        },

        entries: {
            ar: "إدخالات",
            en: "entries",
        },

        ownerName: {
            ar: " إسم المالك",
            en: "Owner name",
        },

        fileType: {
            ar: "نوع الملف",
            en: "File type",
        },
        permissionType: {
            ar: 'نوع الصلاحيات',
            en:'Permission type'
        },
        file: {
            ar: "الملف",
            en: "File",
        },

        close: {
            ar:"إغلاق",
            en: "Close",
        },

        next: {
            ar: 'التالي',
            en: 'Next'
        },

        save: {
            ar: "حفظ",
            en: "Save",
        },

        language: {
			ar: "ar",
			en: "en",
		},
        has: {
            ar: "لديه",
            en: "has"
        },
        accessPermission: {
            ar: 'صلاحيات الوصول',
            en: 'Access permission'
        },
        editPermission: {
            ar: 'صلاحيات التعديل',
            en: 'Edit permission'
        },
        deletePermission: {
            ar: 'صلاحيات الازالة',
            en: 'Delete permission'
        },
        createPermission: {
            ar: 'صلاحيات الانشاء',
            en: 'Create Permission'
        },
        sharePermission: {
            ar: 'صلاحيات المشاركة',
            en: 'Share permission'
        },
        employees: {
            ar: 'الموظفين',
            en: 'Employees'
        },
        ok: {
            ar: 'موافق',
            en: 'Ok'
        },
        editForm: {
            ar: "نموذج التعديل",
            en: "Edit form"
        },
    }