export default {

    supplierId:{
        en: "Supplier ID"
    },
    invoice: {
        en: "Invoice Management"
    },
    customer: {
        en: "Customer Name"
    },
    Merchant: {
        en: "Merchant ID"
    },
}