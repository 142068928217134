import React from 'react';
import {
  Row,
  Col,
  FormGroup,
  Input
} from 'reactstrap';
import { Controller } from "react-hook-form";
import env from 'env';

const EditInvoice = ({ control, errors }) => {

  // Use form hook

  return (
    <div>
      <Row>
        <Col md="6" lg='6'>
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="value"
            >
              Supply contact
            </label>
            <Controller
              control={control}
              name="supplierContact"
              rules={{ required: "Client field is required" }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="value"
                  type="text"
                  min={0}
                  className={errors.value && "error"}
                  value={value}
                  onChange={({ target: { value }}) => onChange(value)}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6" lg='6'>
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="yuan"
            >
              Country
            </label>
            <Controller
              control={control}
              name="country"
              rules={{ required: "Country feild is required" }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="country"
                  placeholder="Weight"
                  type="select"
                  className={errors.country && "error"}
                  value={value || true}
                  onChange={({ target: { value }}) => onChange(Number(value))}
                >
                  <option disabled value> -- select an option -- </option>
                  {env.country.map((status, i) => <option key={i} value={i + 1}>{status}</option>)}
                </Input>
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          {/* <input 
            type="file" 
            onChange={e => setEditImgs(e.target.files)}
          /> */}
        </Col>
      </Row>
    </div>
  )
}

export default EditInvoice