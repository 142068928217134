import React , { useState, useEffect } from 'react';

// router 
import { useRouteMatch, useParams } from "react-router-dom";

// get culture from utils 
import getCulture from "utils/getCulture";

// reactstrap components
import {
   Card,
   CardHeader,
   CardBody,
   FormGroup,
   Input,
   Button,
   Container,
   Row,
   Col,
   UncontrolledTooltip
} from "reactstrap";
//content 
import content from './components/content';

//common
import commenContent from "components/common/content";

// core components
import SimpleHeader from 'components/Headers/SimpleHeader';

// test data
import env from 'env';
// BK
import Table from "components/common/CustomTable";
import useApi from '../../hooks/useApi';
import invoiveApi from "api/invoice";
import Spinner from '../../components/Spinner';
import { useForm, Controller } from "react-hook-form";
import PopupTable from 'components/common/PopupTableUsed';
import Pagination from "components/common/Pagination";
import TextPopup from 'components/common/TextModal';
import ContactedInvoice from "./components/ContactedInvoice";
import useAlert from 'hooks/useAlert';
import EditInvoice from "./components/EditInvoice";
import SearchModal from 'components/common/SearchModal';
import useSearchPopup from 'hooks/useSearchPopup';
import FilePicker from 'components/common/FilePicker';

function Invoice() {

   
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);

  const headers = [
    "Actions",
    "OrderId",
    "Customer",
    "Code",
    "follow up status",
    "Supply contact",
    "is Approved",
    "date",
  ]
  
  const columns = [
    "orderId",
    "cus_Name",
    "cus_Code",
    "followUpStatusText",
    "supplierContact",
    "isCheckeddName",
    "date",
  ]

  const params = useParams();

  // Apis
  const getInvoicePreload = useApi(invoiveApi.getAllInvoice);
  const savedContact = useApi(invoiveApi.savedContact);
  const approveInvoice = useApi(invoiveApi.approveInvoice);
  const createInvoice = useApi(invoiveApi.createInvoice);
  const getByColumn = useApi(invoiveApi.getByColumn);
  const deleteAir = useApi(invoiveApi.deleteAir);
  const deleteLcl = useApi(invoiveApi.deleteLcl);
  const updateAir = useApi(invoiveApi.updateAir);
  const updateLcl = useApi(invoiveApi.updateLcl);

  // States
  const [data, setData] = useState([]);
  const [imgs, setImgs] = useState({})
  const [spinner, setSpinner] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [pageNo, setPageNo] = useState(+params.pgNo);
  const [docsPerPage, setDocsPerPage] = useState(+params.docsPerPg);
  const [approveModal, setApproveModal] = useState(false)
  const [shipmentType, setShipmentType] = useState(+params.shipmentType);
  const [invoiceId, setInvoiceId] = useState('');
  const [customer, setCustomer] = useState({});
  const [tableModal, setTableModal] = useState(false);
  const [contactedModal, setContactedModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [rowId, setRowId] = useState('');

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();

  const editResponse = item => {
    // This code will split any date by checking it's key    
    Object.keys(item).forEach(key => {
      if (key.includes('date') || key.includes('Date')) {
        item[key] = item[key] ? item[key].split('T')[0] : "";
      }
    })
    
    // assign the id
    item.id = item.airCargoId || item.lclId || item.fclId;
    // Replace the normal isApprove with replacement text
    item.isCheckeddName = item.isChecked ? "Checked" : "Not Checked";
    // Replce the status number with the text
    item.followUpStatusText = env.followUpStatus.find(stats => stats.key === item.followUpStatus)?.text;

    return item;
  }

  // Use form hook
  const { reset, handleSubmit, formState: { errors }, control, setValue } = useForm();
  const searchPopup = useSearchPopup(getByColumn, headers, columns, data, editResponse);

  const { 
    handleSubmit: contactedInvoiceSubmit, 
    formState: { errors: contactedInvoiceError }, 
    control: contactedInvoiceControler, 
    setValue: contactedInvoiceSetValue 
  } = useForm();

  const { 
    handleSubmit: editInvoiceSubmit, 
    formState: { errors: editInvoiceError }, 
    control: editInvoiceControler, 
    setValue: editInvoiceSetValue 
  } = useForm();

  const getInvoice = async () => {
    const res = await getInvoicePreload.request(pageNo, docsPerPage, shipmentType);
    if (res.status === 200) {
      res.data.data = res.data.data.map(item => editResponse(item));
      
      setData(res.data?.data);
    }
  }

  const handleChage = e => {
    const value = env.shipmentType.findIndex(ship => ship === e.target.value) + 1;
    setShipmentType(value);
  }

  const handleCancel = () => {
    setEditModal(false);
    setTableModal(false);
    setApproveModal(false);
    setContactedModal(false);
    setDeleteModal(false);
    setInvoiceId('');
    setImgs({});
    setRowId('');
    reset();
  }

  const handleContacted = obj => {
    setContactedModal(true);
    contactedInvoiceSetValue("value", obj.value);
    contactedInvoiceSetValue("id", obj.id);
    contactedInvoiceSetValue("valueIwan", obj.valueIwan);
    contactedInvoiceSetValue("remittanceCommissionDinar", obj.remittanceCommissionDinar);
    contactedInvoiceSetValue("remittanceCommissionDollar", obj.remittanceCommissionDollar);
  }

  const handleEdit = obj => {
    setEditModal(true);
    editInvoiceSetValue("country", obj.country);
    editInvoiceSetValue("supplierContact", obj.supplierContact);
    setImgs({});
    setRowId(obj.id);
  }

  const contactedInvoice = async formData => {
    setContactedModal(false);
    setSpinner(true);
    const schema = {...formData, shipmentType}
    delete schema.id;

    const res = await savedContact.request(formData.id, schema);

    if (res.ok) {
      const newData = [...data];
      const index = newData.findIndex(item => item.id === formData.id);
      newData[index] = {
        ...newData[index],
        ...formData
      }
      setData(newData);
      sweetAlert("Done")
    } 
    setSpinner(false);
  }
  
  const handleApprove = obj => {
    setInvoiceId(obj.id);
    setApproveModal(true);
  }

  const approveInvoicement = async () => {
    setApproveModal(false);
    setSpinner(true);
    const res = await approveInvoice.request(invoiceId, shipmentType);

    if (res.ok) {
      const newData = [...data];
      const index = newData.findIndex(item => item.id === invoiceId);
      newData[index] = {
        ...newData[index],
        isChecked: true,
        isCheckeddName: "Checked"
      }
      setData(newData);   
      setInvoiceId('');
      sweetAlert("Done");
    }
    setSpinner(false);
  }

  const handleCreate = async formData => {
    setSpinner(true);
    // const imgs = imageRef.current.files
    const schema = new FormData();
    schema.append('phone',formData.phone)
    schema.append('country',formData.country)
    schema.append('ShipmentType', shipmentType);
    schema.append('client', customer?.cusId)
    // for(const key in imgs) {
    //   schema.append('Image', imgs[key]);
    // }
    for(let i = 0; i < imgs.length; i++ ) {
      schema.append('Image', imgs[i]);
    }

    const res = await createInvoice.request(schema);
    if (res.ok) {
      setImgs({});
      reset();
      getInvoice();
      sweetAlert("Done");
    }
    setSpinner(false)
  }

  const handleDelete = obj => {
    setRowId(obj.id);
    setDeleteModal(true);
  }

   useEffect(() => {
      getInvoice();
   }, [pageNo, docsPerPage, shipmentType])

   useEffect(() => {

    if (searchPopup.isSelected && searchPopup.searchField) {
      const shipmentTypeArr = ["aircargo", "lcl", "fcl"];
      searchPopup.onSearch(shipmentTypeArr[+shipmentType - 1], () => {});
    } else {
      searchPopup.setIsSelected(false);
    }
  }, [searchPopup.pageNo, searchPopup.docsPerPage, shipmentType])

  const editRenderCondition = child => child.followUpStatus === 1 ? true : false;
  const approveRenderCondition = child => !child.isChecked;
  
  const handleDeleteInvoice = async () => {
    let res;
    setSpinner(true);
    setDeleteModal(false);
    if (shipmentType == 1) {
      res = await deleteAir.request(rowId);
    } else {
      res = await deleteLcl.request(rowId);
    }
    if (res.status === 200) {
      const newData = data.filter(item => item.id !== rowId);
      setData(newData);
      sweetAlert("Done")
    }
    setSpinner(false);
  }

  const editInvoice = async formData => {

    setSpinner(true);
    setEditModal(false);
    let res;

    const schema = {
      country: formData.country,
      phone: formData.supplierContact
    };

    if (shipmentType == 1) {
      res = await updateAir.request(rowId, schema);
    } else {
      res = await updateLcl.request(rowId, schema);
    }

    if (res.status === 200) {
      const newData = [...data];
      const rowIndex = newData.findIndex(item => item.id === rowId);
      newData[rowIndex].supplierContact = formData.supplierContact;
      newData[rowIndex].country = formData.country;
      newData[rowIndex] = editResponse(newData[rowIndex]);
      setData(newData);
      sweetAlert("Done");
    }
    setSpinner(false);
    setRowId('');
  }

  const updateData = obj => {
    const editedResponse = obj.map(response => editResponse(response))
    setData(editedResponse);
  }

    return (
      <div>
      {alert}
      {approveInvoice.errorAlert}
      {savedContact.errorAlert}
      {getInvoicePreload.errorAlert}
      {createInvoice.errorAlert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      {getInvoicePreload.loading ? <Spinner
          gate="#29bb64"
          bg="#fff"
        />
        : <>
         <SimpleHeader parents={[content.invoice[culture]]} />
         <Container className="mt--6" fluid>
            <Card>
              <CardHeader>
                <h3 className={`mb-0 text-md-left`}>{content.invoice[culture]}</h3>
              </CardHeader>
              <CardBody>
                <form onSubmit={handleSubmit(handleCreate)}>
                  <Row>
                    <Col md="6" lg='4'>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          Client
                        </label>
                        <Controller
                          control={control}
                          name="client"
                          rules={{ required: "Client field is required" }}
                          render={({ field: { ref, onChange, value, ...field } }) => (
                            <Input
                              {...field}
                              id="example3cols1Input"
                              placeholder="Client"
                              type="text"
                              className={errors.client && "error"}
                              value={value || ""}
                              onChange={({ target: { value }}) => onChange(value)}
                              onClick={() => setTableModal(true)}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg='4'>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="phone"
                        >
                          Supply contact
                        </label>
                        <Controller
                          control={control}
                          name="phone"
                          rules={{ required: "phone feild is required" }}
                          render={({ field: { ref, onChange, value, ...field } }) => (
                            <Input
                              {...field}
                              id="phone"
                              type="text"
                              className={errors.phone && "error"}
                              value={value || ""}
                              onChange={({ target: { value }}) => onChange(value)}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg='4'>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="country"
                        >
                          Country
                        </label>
                        <Controller
                          control={control}
                          name="country"
                          rules={{ required: "Country feild is required" }}
                          render={({ field: { ref, onChange, value, ...field } }) => (
                            <Input
                              {...field}
                              id="country"
                              placeholder="Weight"
                              type="select"
                              className={errors.country && "error"}
                              value={value || true}
                              onChange={({ target: { value }}) => onChange(Number(value))}
                            >
                              <option disabled value> -- select an option -- </option>
                              {env.country.map((status, i) => <option key={i} value={i + 1}>{status}</option>)}
                            </Input>
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <FilePicker 
                          images={imgs}
                          setImages={setImgs}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button 
                    color="success" 
                    type="submit"
                    disabled={(!Object.keys(imgs).length) || false}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="ni ni-fat-add" />
                    </span>
                    <span>
                      Create
                    </span>
                  </Button>
                </form>
              </CardBody>
            </Card>
            <Card className="mb-4">
              <CardBody>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="shipmentType"
                      >
                        Shipment type
                      </label>
                      <Input 
                        type='select' 
                        id='shipmentType' 
                        onChange={handleChage}
                        value={env.shipmentType[shipmentType - 1]}
                      >
                        {env.shipmentType.filter(ship => ship !== '').map((ship, index) => {
                          return <option key={ship} value={ship}>{ship}</option>
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            <Pagination
              fun={getInvoice}
              dataLength={data.length}
              // when the user search using the popup and select a row
              // after then only use the pagination states from searchPopup hook
              pageNo={searchPopup.isSelected ? searchPopup.pageNo : pageNo} 
              setPageNo={searchPopup.isSelected ? searchPopup.setPageNo : setPageNo}
              docsPerPage={searchPopup.isSelected ? searchPopup.docsPerPage : docsPerPage}
              setDocsPerPage={searchPopup.isSelected ? searchPopup.setDocsPerPage : setDocsPerPage}
            >
              <Table 
                headers={headers}
                columns={columns}
                data={searchPopup.isSelected ? searchPopup.hookData : data}
                handleEdit={handleEdit}
                handleContacted={handleContacted}
                editRenderCondition={editRenderCondition}
                handleApprove={handleApprove}
                approveRenderCondition={approveRenderCondition}
                handleDelete={handleDelete}
                onHeaderClick={searchPopup.handleHeaderClicked}
              >
                <span to={`/en-admin/invoice-management/${pageNo}/${docsPerPage}/${shipmentType}`}>
                  <div id="details1" className="table-action cursor-pointer">
                    <i className="far fa-eye fa-lg hover-primary"></i>
                  </div>
                  <UncontrolledTooltip
                    delay={0}
                    target="details1"
                  >
                    {commenContent.showDetails[culture]}
                  </UncontrolledTooltip>
                </span>
                <span className="me-1" fun="handleContacted" condition="editRenderCondition">
                  <div id="contacted-value" className="table-action cursor-pointer">
                    <i className="fas fa-file-import fa-lg hover-info"></i>
                  </div>
                  <UncontrolledTooltip
                    delay={0}
                    target="contacted-value"
                  >
                    Contacted value
                  </UncontrolledTooltip>
                </span>
                <span className="me-1" fun="handleEdit" condition="editRenderCondition">
                  <div id="edit1" className="table-action cursor-pointer">
                    <i className="fas fa-pencil-alt fa-lg hover-success" />
                  </div>
                  <UncontrolledTooltip
                    delay={0}
                    target="edit1"
                  >
                    Edit
                  </UncontrolledTooltip>
                </span>
                <span className='me-1' fun="handleApprove" condition="approveRenderCondition">
                  <div id="check" className="table-action cursor-pointer">
                    <i className="far fa-check-circle hover-success fa-lg" />
                  </div>
                  <UncontrolledTooltip
                    delay={0}
                    target="check"
                  >
                    check
                  </UncontrolledTooltip>
                </span>
                <span className='me-1' fun="handleDelete" condition="editRenderCondition">
                  <div id="delete" className="table-action cursor-pointer">
                    <i className="fas fa-trash hover-danger fa-lg" />
                  </div>
                  <UncontrolledTooltip
                    delay={0}
                    target="delete"
                  >
                    Delete
                  </UncontrolledTooltip>
                </span>
              </Table>
            </Pagination>
          </Card>
          <TextPopup
            modal={contactedModal}
            text="Save"
            handleCancel={handleCancel}
            fn={contactedInvoiceSubmit(contactedInvoice)}
            color='primary'
          >
            <Container>
              <ContactedInvoice 
                control={contactedInvoiceControler}
                errors={contactedInvoiceError}
              />
            </Container>
          </TextPopup>
          <TextPopup
            modal={editModal}
            text="Save"
            handleCancel={handleCancel}
            fn={editInvoiceSubmit(editInvoice)}
            color='primary'
          >
            <Container>
              <EditInvoice 
                control={editInvoiceControler}
                errors={editInvoiceError}
              />
            </Container>
          </TextPopup>
          <TextPopup
            modal={approveModal}
            text="Yes"
            handleCancel={handleCancel}
            fn={approveInvoicement}
            color='primary'
          >
            <Container>
              <h2>Are you sure you want to check this invoicement ?</h2>
            </Container>
          </TextPopup>
          <TextPopup
            modal={deleteModal}
            text="Yes"
            handleCancel={handleCancel}
            fn={handleDeleteInvoice}
            color='danger'
          >
            <Container>
              <h2>Are you sure you want to delete this invoice ?</h2>
            </Container>
          </TextPopup>
          <TextPopup
            modal={tableModal}
            text="Next"
            handleCancel={handleCancel}
            fn={() => {}}
            color='primary'
            noBtn
          >
            <Container>
              <PopupTable 
                setCustomer={setCustomer}
                setTableModal={setTableModal}
                setValue={setValue}
                sweetAlert={sweetAlert}
              />
            </Container>
          </TextPopup>
          {searchPopup.openPopup && 
            <SearchModal
              data={data}
              setData={setData}
              updateData={updateData}
              searchPopup={searchPopup}
              editResponse={editResponse}
              shipmentType={shipmentType}
              page="Invoice"
            />
          }
        </Container>
      </>}     
   </div>
 )}


export default Invoice;
