import React from 'react';
import {
  Row,
  Col,
  FormGroup,
  Input
} from 'reactstrap';
import { Controller } from "react-hook-form";

const EditInvoice = ({ control, errors }) => {

  // Use form hook

  return (
    <div>
      <Row>
        <Col md="6" lg='6'>
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="value"
            >
              Value dollar
            </label>
            <Controller
              control={control}
              name="value"
              rules={{ required: "Client field is required" }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="value"
                  type="number"
                  min={0}
                  className={errors.value && "error"}
                  value={value}
                  onChange={({ target: { value }}) => onChange(Number(value))}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6" lg='6'>
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="yuan"
            >
              Value yuan
            </label>
            <Controller
              control={control}
              name="valueIwan"
              // rules={{ required: "Client field is required" }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="yuan"
                  type="number"
                  min={0}
                  // className={errors.value && "error"}
                  value={value}
                  onChange={({ target: { value }}) => onChange(Number(value))}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6" lg='6'>
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="dollar"
            >
              Remittance Commission Dollar
            </label>
            <Controller
              control={control}
              name="remittanceCommissionDollar"
              rules={{ required: "RCD field is required" }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="dollar"
                  type="number"
                  min={0}
                  className={errors.client && "error"}
                  value={value}
                  onChange={({ target: { value }}) => onChange(Number(value))}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6" lg='6'>
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="dinar"
            >
              Remittance Commission Dinar
            </label>
            <Controller
              control={control}
              name="remittanceCommissionDinar"
              rules={{ required: "Client field is required" }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="dinar"
                  type="number"
                  min={0}
                  className={errors.dinar && "error"}
                  value={value}
                  onChange={({ target: { value }}) => onChange(Number(value))}
                />
              )}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
}

export default EditInvoice