import client from './client';

const endpoint = '/v1';

const getFUS = (shipmentType, page, docsPerPage) => client.get(`${endpoint}/${shipmentType}/FollwoUpIndex?Page=${page}&PostsPerPage=${docsPerPage}`);
const getByColumn = (col, val, page, docsPerPage, shipmentType) => client.get(`${endpoint}/${shipmentType}/FollwoUpGetByCoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`);

export default {
  getFUS,
  getByColumn
}