import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import useApi from 'hooks/useApi';
import { useParams } from "react-router-dom";
import user from "api/user";
import Spinner from '../../components/Spinner';
import SimpleHeader from 'components/Headers/SimpleHeader';
import ReactBSAlert from "react-bootstrap-sweetalert";
import env from 'env';
import Gallery from 'components/common/Gallery';

function UserDetails() {
  // params
  const { id, pgNo, docsPerPg } = useParams();

  // apis
  const getUser = useApi(user.findById);

  // states
  const [data, setData] = useState({});
  const [alert, setAlert] = useState(null);
  const [spinner, setSpinner] = useState(false);

  const findUser = async () => {
    const res = await getUser.request(id);

    if (res.status === 200) {
      setData(res.data.data)
    }
  }

  useEffect(() => {
    findUser();
  }, [])

  const sweetAlert = (text, warning = false) => {
    setAlert(
      <ReactBSAlert
        show={true}
        warning={warning}
        success={!warning}
        style={{ display: "block", marginTop: "-100px" }}
        title={""}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle={warning ? "warning" : "info"}
        confirmBtnText="Ok"
        btnSize=""
      >
        {text}
      </ReactBSAlert>
    ); 
  }

  useEffect(() => (getUser.error && getUser.data) && sweetAlert(getUser.data ,true), [getUser.data])

  return (
    <div>
      {alert}
      {spinner && <Spinner gate="#29bb64" bg="#fff" opacity />}
      {getUser.loading ? <Spinner
        gate="#29bb64"
        bg="#fff"
      />
      : <>
        <SimpleHeader parents={["Approve user"]} backBtn={`/en-admin/approveusers/${pgNo}/${docsPerPg}`} />
        <Container className="mt--6" fluid>
          <Card className="card-plain">
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 mt-1 ms-2 text-md-left`}>Approve user</h3>
            </div>
            <CardBody>
              <form>
                <Row>
                  <Col md="6" lg='4'>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        Full name
                      </label>
                      <Input
                        id="example3cols1Input"
                        placeholder="Client"
                        type="text"
                        value={data.result?.fullName || ""}
                        disabled
                      />
                      </FormGroup>
                  </Col>
                  <Col md="6" lg='4'>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        Username
                      </label>
                      <Input
                        id="example3cols1Input"
                        placeholder="Client"
                        type="text"
                        value={data.result?.userName || ""}
                        disabled
                      />
                      </FormGroup>
                  </Col>
                  <Col md="6" lg='4'>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        Email
                      </label>
                      <Input
                        id="example3cols1Input"
                        placeholder="Weight"
                        type="text"
                        value={data.result?.email || ""}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg='4'>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        Whatsapp phone number
                      </label>
                      <Input
                        id="example3cols1Input"
                        placeholder="Number of packages"
                        type="text"
                        value={data.result?.whatsappPhone || ""}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg='4'>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        company name
                      </label>
                      <Input
                        id="example3cols1Input"
                        type="text"
                        value={data.result?.companyName || ""}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg='4'>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        User type
                      </label>
                      <Input
                        id="example3cols1Input"
                        type="text"
                        value={env.usersType[data.result?.userType - 1] || ""}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Container>
        <Gallery imgs={data.result?.imges} />
      </>}
    </div>
  )
}

export default UserDetails