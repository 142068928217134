import React , { useState, useEffect } from 'react';

// router 
import {
   useRouteMatch,
} from "react-router-dom";

// get culture from utils 
import getCulture from "utils/getCulture";

// reactstrap components
import {
   Card,
   CardHeader,
   CardBody,
   FormGroup,
   Form,
   Input,
   Button,
   Container,
   Row,
   Col,
   Modal,
   UncontrolledTooltip,
} from "reactstrap";
//content 
import content from './components/content';

//common
import commenContent from "components/common/content";

// core components
import SimpleHeader from 'components/Headers/SimpleHeader';

import TextPopup from 'components/common/TextModal';
import EditNews from "./components/EditNews";

// BK
import Table from "components/common/CustomTable";
import useApi from '../../hooks/useApi';
import newsApi from "api/newsBK";
import Spinner from '../../components/Spinner';
import { useForm, Controller } from "react-hook-form";
import Pagination from "components/common/Pagination";
import FilePicker from 'components/common/FilePicker';
import useAlert from 'hooks/useAlert';


function News() {

   let rout = useRouteMatch()
   let culture = getCulture(rout.url);

   // Apis
  const getNewsPreload = useApi(newsApi.getNews);
  const createNews = useApi(newsApi.createNews);
  const updateNews = useApi(newsApi.updateNews);

  // States
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editSchema, setEditSchema] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [viewModal, setViewModal] = useState(false);
  const [img, setImg] = useState();
  const [images, setImages] = useState({});

  const { alert, sweetAlert } = useAlert();

  // Use form hook
  const { register, reset, handleSubmit, formState: { errors }, control } = useForm();

  const getNews = async () => {
   const res = await getNewsPreload.request(pageNo, docsPerPage);
   if (res.status === 200) {
    res.data.data.forEach(post => {
      // This code will split any date by checking it's key    
      Object.keys(post).forEach(key => {
        if (key.includes('date') || key.includes('Date')) {
          post[key] = post[key] ? post[key].split('T')[0] : "";
        }
      })
   })
   setData(res.data.data);
  }   
   }

   useEffect(() => {
      getNews();
   }, [])

   const headers = [
     "actions",
      "Title",
      "Date",
      "Description",
    ]
  
    const columns = [
      "titel",
      "date",
      "descraption",
    ]

    const handleCreate = async formData => {
      setSpinner(true);
      const schema = {
        ...formData,
        image: images[0],
        isActive: true,
        path: ""
      }
      const res = await createNews.request(schema);
       
      if (res.status === 200) {
        const newData = [ ...data ];
        Object.keys(res.data.data).forEach(key => {
          if (key.includes('date') || key.includes('Date')) {
            res.data.data[key] = res.data.data[key] ? res.data.data[key].split('T')[0] : "";
          }
        })
        newData.unshift(res.data.data)
        setData(newData);
        setImages({});
        reset();
        sweetAlert(commenContent.done[culture])
      }
      setSpinner(false);
    }

    const editNews = async () => {
      setSpinner(true);
      const schema = {
        isActive: editSchema.isActive,
        titel: editSchema.titel,
        path: editSchema.path,
        date: editSchema.date,
        image: editSchema.image,
        descraption: editSchema.descraption
      }
  
      const res = await updateNews.request( editSchema.id, schema);
  
      if (res.status === 200) {
        const newData = [...data];
        const editedIndex = newData.findIndex(item => item.id === editSchema.id);
        Object.keys(res.data.data).forEach(key => {
          if (key.includes('date') || key.includes('Date')) {
            res.data.data[key] = res.data.data[key] ? res.data.data[key].split('T')[0] : "";
          }
        })
  
        newData[editedIndex] = res.data.data;
        
        setData(newData);
        sweetAlert(commenContent.done[culture])
      }
      setSpinner(false);
      setEditModal(false);
    }

    const handleEdit = obj => {
      setEditSchema(obj);
      setEditModal(true);
    }

    const handleView = obj => {
      setImg(obj.path);
      setViewModal(true);
     }

    const handleCancel = () => {
      setEditModal(false);
      setViewModal(false);
      setEditSchema({});
    }
 
   useEffect(() => {
    getNews();
 }, [pageNo, docsPerPage])
 
    return (
      <div>
      {alert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      {getNewsPreload.loading ? <Spinner
          gate="#29bb64"
          bg="#fff"
        />
        : <>
         <SimpleHeader parents={["News"]} />
         <Container className="mt--6" fluid>
            <Card className="card-plain">
               <CardHeader>
                  <h3 className={`mb-0 text-md-left`}>{content.news[culture]}</h3>
               </CardHeader>
               <CardBody>
               <form onSubmit={handleSubmit(handleCreate)}>
                  <Row>
                  <Col md="4" lg='4'>
                        <FormGroup>
                           <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                           >
                              {content.title[culture]}
                           </label>
                           <Controller
                              control={control}
                              name="titel"
                              rules={{ required: `${content.title[culture]} field is required` }}
                              render={({ field: { ref, onChange, value, ...field } }) => (
                                 <Input
                                 {...field}
                                 id="example3cols1Input"
                                 placeholder={content.title[culture]}
                                 type="text"
                                 className={errors.titel && "error"}
                                 ref={ref}
                                 value={value || ""}
                                 onChange={({ target: { value }}) => onChange(value)}
                                 />
                              )}
                           />
                        </FormGroup>
                     </Col>
                     <Col md="4" lg='4'>
                        <FormGroup>
                        <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                           >
                              {content.date[culture]}
                           </label>
                           <Controller
                              control={control}
                              name="date"
                              rules={{ required: `${content.date[culture]} field is required` }}
                              render={({ field: { ref, onChange, value, ...field } }) => (
                                 <Input
                                 {...field}
                                 id="example-date-input"
                                 type="date"
                                 className={errors.date && "error"}
                                 ref={ref}
                                 value={value || ""}
                                 onChange={({ target: { value }}) => onChange(value)}
                                 />
                              )}
                           />
                        </FormGroup>
                     </Col>
                     <Col md="4" lg='4'>
                        <FormGroup>
                           <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                           >
                              {content.description[culture]}
                           </label>
                           <Controller
                              control={control}
                              name="descraption"
                              rules={{ required: `${content.description[culture]} field is required` }}
                              render={({ field: { ref, onChange, value, ...field } }) => (
                                 <Input
                                 {...field}
                                 id="example3cols1Input"
                                 placeholder={content.description[culture]}
                                 type="textarea"
                                 className={errors.descraption && "error"}
                                 ref={ref}
                                 value={value || ""}
                                 onChange={({ target: { value }}) => onChange(value)}
                                 />
                              )}
                           />
                        </FormGroup>
                     </Col>
                     <Col md="12">
                         <FormGroup>
                         <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                           >
                              {content.image[culture]}
                           </label>
                           {/* <Form> */}
                          <FilePicker 
                            multiple={false}
                            images={images}
                            setImages={setImages}
                          />
                    {/* <div className="custom-file">
                       <input
                              {...register('image', { required: `${content.image[culture]} field is required` })}
                              id="customFileLang"
                              type="file"
                              className="custom-file-input"
                              onChange={(e) => console.log("")}
                            />
                      <label
                        className="custom-file-label"
                        htmlFor="customFileLang"
                      >
                        {content.select[culture]}
                      </label> 
                    </div> */}
                  {/* </Form> */}
                         </FormGroup>
                     </Col>
                     </Row>
                     <Row>
                     <Col className={`text-md-right`} lg="12" md='12' xs="12">
                     <Button 
                        color="success" 
                        type="submit"
                        disabled={!Object.keys(images).length}
                     >
                        Create
                     </Button>
                     </Col>
                     </Row>
                     
                     </form>
               </CardBody>
            </Card>
            <Card>
            <Pagination
            fun={getNews}
            pageNo={pageNo} 
            setPageNo={setPageNo}
            docsPerPage={docsPerPage}
            setDocsPerPage={setDocsPerPage}
            dataLength={data.length}
          >
            <Table 
            headers={headers}
            columns={columns}
            data={data}
            handleEdit={handleEdit}
            handleView={handleView}
          >
            <span className="me-1" fun="handleView">
              <div id="details1" className="table-action cursor-pointer">
                <i className="far fa-eye fa-lg hover-success"></i>
              </div>
              <UncontrolledTooltip
                delay={0}
                target="details1"
              >
                {commenContent.view[culture]}
              </UncontrolledTooltip>
            </span>
            <span className="me-1" fun="handleEdit">
              <div id="edit1" className="table-action cursor-pointer">
                <i className="fas fa-pencil-alt fa-lg hover-info"></i>
              </div>
              <UncontrolledTooltip
                delay={0}
                target="edit1"
                >
                {commenContent.edit[culture]}
              </UncontrolledTooltip>
            </span>
          </Table>
          </Pagination>
             <TextPopup
            modal={editModal}
            text="Update"
            handleCancel={handleCancel}
            fn={editNews}
            color='success'
          >
            <Container>
                <EditNews
                  editSchema={editSchema}
                  setEditSchema={setEditSchema}
                  culture={culture}
                  />
            </Container>
          </TextPopup>
           {/* view modal  */}
        <Modal className="modal-dialog-centered" isOpen={viewModal}>
              <div className="modal-header">
                <h2 className="modal-title" id="exampleModalLabel">
                  {content.image[culture]}
                </h2>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => {
                     setViewModal(false)
                     reset();
                  }}
                >
                  <span
                    className={culture === "ar" ? "text-md-right-bb" : ""}
                    aria-hidden={true}
                  >
                    ×
                  </span>
                </button>
              </div>
              <div className="modal-body">
              <img src={img} alt="preview image" style={{
                      marginLeft: "8em",
                      width: "400px",
                      height: "400px"}} />
              </div>
              <div className="modal-footer">
                <Button
                  className={
                    culture === "en" ? "text-md-right" : "text-md-left"
                  }
                  color="secondary"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => {
                     setViewModal(false)
                     reset();
                  }}
                >
                  {commenContent.close[culture]}
                </Button>
              </div>
            </Modal>
            </Card>
            </Container>
        </>
}
        </div>
               
 )}

export default News;
