/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// router 
import {
  //BrowserRouter as Router,
  useRouteMatch,
} from "react-router-dom";

// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import { Chart } from "chart.js";
// react plugin used to create charts
import { Line, Bar, Doughnut, Pie } from "react-chartjs-2";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import CardsHeader from "./CustomCardsHeader";

import {
  chartOptions,
  parseOptions,
  chartExample3,
  chartExample5,
} from "variables/charts.js";
import getCulture from "utils/getCulture";
import axios from 'axios';
import Spinner from '../../../components/Spinner';
import catchAsync from 'helper/catchAsync';
import env from 'env';
import filter from 'api/filter';
import content from "./content";
import useApi from "hooks/useApi";
import ReactBSAlert from "react-bootstrap-sweetalert";

function Dashboard() {
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [usersStatics, setUsersStatics] = useState([]);
  const [airStatics, setAirStatics] = useState([]);
  const [lclStatics, setLclStatics] = useState([]);
  const [fclStatics, setFclStatics] = useState([]);
  const [alert, setAlert] = useState(null);

  // Apis
  const dashboard = useApi(filter.dashboard);


  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const preload = async () => {
    setLoading(true);
    const res = await dashboard.request();

    if (res.status === 200) {
      calcStatic(res.data.data[0], 1, setUsersStatics);
      calcStatic(res.data.data[0], 0, setAirStatics);
      calcStatic(res.data.data[0], 2, setLclStatics);
      calcStatic(res.data.data[0], 3, setFclStatics);
      setData(res.data.data[0]);
    } else {
      sweetAlert(dashboard.data, true);
    }
    setLoading(false);
  }

  const calcStatic = (resData, index, setState) => {
    const arrayOfValues = resData.graphes[index].graphesValues.map(item => item.value);
    setState(arrayOfValues)
  }



  const chart1 = {
    ...chartExample3,
    data: {
      labels: content.months[culture],
      datasets: [
        {
          data: usersStatics,
          label: "Users",
        },
      ],
    }
  }

  const chart2 = {
    ...chartExample3,
    data: {
      labels: content.months[culture],
      datasets: [
        {
          data: airStatics,
          label: "Shipment",
        },
      ],
    }
  }

  const chart3 = {
    ...chartExample3,
    data: {
      labels: content.months[culture],
      datasets: [
        {
          data: lclStatics,
          label: "Shipment",
        },
      ],
    }
  }

  const chart4 = {
    ...chartExample3,
    data: {
      labels: content.months[culture],
      datasets: [
        {
          data: fclStatics,
          label: "Shipment",
        },
      ],
    }
  }

  // set sweet alert
  const sweetAlert = (text, warning = false) => {
    setAlert(
      <ReactBSAlert
        show={true}
        warning={warning}
        success={!warning}
        style={{ display: "block", marginTop: "-100px" }}
        title={""}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle={warning ? "warning" : "info"}
        confirmBtnText="Ok"
        btnSize=""
      >
        {text}
      </ReactBSAlert>
    ); 
  }

  useEffect(() => {
    preload();
  }, [])

  useEffect(() => (dashboard.error && dashboard.data) && sweetAlert(dashboard.data ,true), [dashboard.data])

  return (
    <>
    {alert}
    {loading ? <Spinner
      gate="#29bb64"
      bg="#fff"
    />
      :<>
      <CardsHeader name={content.title[culture]} parentName="" data={data} />
      <Container className="mt--6" fluid>
        <Row> 
          <Col xl="6">
          <Card>
            <CardHeader>
              <h5 className="h3 mb-0">{data.graphes[1].title}</h5>
            </CardHeader>
            <CardBody>
              <div className="chart">
                <Line
                  data={chart1.data}
                  options={chart1.options}
                  id="chart-sales"
                  className="chart-canvas"
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl="6">
          <Card>
            <CardHeader className="bg-transparent">
              <Row className="align-items-center">
                <div className="col">
                  <h5 className="h3 mb-0">{data.graphes[0].title}</h5>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <div className="chart">
                <Bar
                  data={chart2.data}
                  options={chart2.options}
                  className="chart-canvas"
                  id="chart-bars"
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl="6">
          <Card>
            <CardHeader>
              <h5 className="h3 mb-0">{data.graphes[2].title}</h5>
            </CardHeader>
            <CardBody>
              <div className="chart">
                <Bar
                  data={chart3.data}
                  options={chart3.options}
                  className="chart-canvas"
                  id="chart-doughnut"
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl="6">
          <Card>
            <CardHeader>
              <h5 className="h3 mb-0">{data.graphes[3].title}</h5>
            </CardHeader>
            <CardBody>
              <div className="chart">
                <Line
                  data={chart4.data}
                  options={chart4.options}
                  className="chart-canvas"
                  id="chart-pie"
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      </Container>
      </>}
    </>
  );
}

export default Dashboard;
