import client from './client';

const endpoint = '/v1/Customer';

const customers = (page, docPerPage) => client.get(`${endpoint}/Preload?Page=${page}&PostsPerPage=${docPerPage}`);

const findByName = (name, page, docPerPage) => client.get(`${endpoint}/FindByName?filter=${name}&Page=${page}&PostsPerPage=${docPerPage}`);

const createCustomer = schema => client.post(`${endpoint}/`, schema);

export default {
  customers,
  createCustomer,
  findByName
}