import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  FormGroup,
  Input
} from "reactstrap";
import env from "env";

function Pagination({ children, pageNo, setPageNo, docsPerPage, setDocsPerPage, dataLength = 0, disabled = false }) {

  const increment = e => {
    e.preventDefault();
    setPageNo(page => page + 1);
  }

  const decrement = e => {
    e.preventDefault();
    setPageNo(page => page - 1)
  };
  
  const handlePagination = e => setDocsPerPage(e.target.value);

  return (
    <div>
      <div className='padding-around'>
        <Row>
          <Col md="3">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="example3cols1Input"
              >
                Document per page
              </label>
              <Input
                id="example3cols1Input"
                placeholder="Weight"
                type="select"
                value={docsPerPage}
                onChange={handlePagination}
                disabled={disabled}
              >
                {env.pages.map((page, i) => <option key={i} value={page}>{page}</option>)}
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </div>
      {children}
      {!disabled && 
        <div className='padding-around d-flex justify-content-end align-items-center'>
          <button 
            className={`pagination-btn`} 
            onClick={decrement}
            disabled={pageNo < 2 ? true: false} 
          >
            &lt;
          </button>
          <span className='pagination-number'>{pageNo}</span>
          <button 
            className='pagination-btn' 
            disabled={docsPerPage != dataLength}
            onClick={increment}
          >
            &gt;
          </button>
        </div>
      }
    </div>
  )
}

export default Pagination;