import { create } from 'apisauce';

const apiClient = create({
    // baseURL: "https://gis.gw.ly/api",
    baseURL: "https://gw.shahen.shahen.ly/api"
}); 

apiClient.addAsyncRequestTransform(async (request) => {
    const authToken = await localStorage.getItem(btoa('token'));
    if (!authToken) return;
    request.headers["Authorization"] = "Bearer " + authToken;
});

export default apiClient;