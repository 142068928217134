import client from './client';

const endpoint = '/v1';

const getAllBulks = (shipmentType, page, docsPerPage) => client.get(`${endpoint}/${shipmentType}/GetAllBulks?Page=${page}&PostsPerPage=${docsPerPage}`)

const bulkDetails = (shipmentType, id) => client.get(`${endpoint}/${shipmentType}/FindBulkById?id=${id}`);

const getCustomers = (shipmentType, id, pageNo, docsPerPage) => client.get(`${endpoint}/${shipmentType}/GetCustmoreBulk?id=${id}&Page=${pageNo}&PostsPerPage=${docsPerPage}`);

const uploadImg = (shipmentType, id, imgs) => {
  const data = new FormData();

  for(let i = 0; i < imgs.length; i++) {
    data.append('Image', imgs[i])
  }

  return client.put(`${endpoint}/${shipmentType}/ImgBulk/${id}`, data)
}

const getClients = (shipmentType, id, pageNo, docsPerPage) => client.get(`${endpoint}/${shipmentType}/GetClientBulk?Page=${pageNo}&PostsPerPage=${docsPerPage}`);

const createBulk = (shipmentType, schema) => client.post(`${endpoint}/${shipmentType}/createbulk`, schema);

const findBulkByCode = (shipmentType, id, page, docsPerPage) => {
  return client.get(`${endpoint}/${shipmentType}/FindBulkClientByCode?id=${id}&Page=${page}&PostsPerPage=${docsPerPage}`)
}

const updateBulkArrivalDate = (shipmentType, id, date) => (
  client.put(`${endpoint}/${shipmentType}/UpdatBulk/${id}?date=${date}`)
)

const arrivedBulk = (shipmentType, id) => client.put(`${endpoint}/${shipmentType}/Arraived/${id}`)

const getByColumn = (col, val, page, docsPerPage, shipmentType) => (
  client.get(`${endpoint}/bulk/${shipmentType}/getbycoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`)
);

export default {
  getAllBulks,
  bulkDetails,
  uploadImg,
  getCustomers,
  getClients,
  createBulk,
  findBulkByCode,
  updateBulkArrivalDate,
  arrivedBulk,
  getByColumn
}