import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import SimpleHeader from 'components/Headers/SimpleHeader';
import { useForm, Controller } from "react-hook-form";
import Table from "components/common/CustomTable";
import Pagination from "components/common/Pagination";
import Spinner from '../../components/Spinner';
import useApi from '../../hooks/useApi';
import user from 'api/user';
import customer from 'api/customer';
import useAlert from 'hooks/useAlert';
import { useParams } from 'react-router';

function ApproveUsers() {

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();
  
  // Apis
  const notActiveUsers = useApi(user.notActiveUsers);
  const createCustomer = useApi(customer.createCustomer);
  
  const params = useParams();

  // states
  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(+params.pgNo);
  // const [alert, setAlert] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [docsPerPage, setDocsPerPage] = useState(+params.docsPerPg);
  const [currentUser, setCurrentUser] = useState({});

  // form validation
  const { handleSubmit, formState: { errors }, control, reset, setValue } = useForm();

  const headers = ["actions", "name", "phone"];

  const columns = ["fullName", "whatsappPhone"];

  const handleCreate = async formData => {
    setSpinner(true);
    const schema = {
      applicationUserId: currentUser.id,
      cusName: formData.fullName,
      phone: formData.whatsappPhone,
      cusCode: currentUser.cusCode
    }
    
    const res = await createCustomer.request(schema);

    // 200 or 201   
    if (res.ok) {
      const newData = data.filter(user => user.id !== currentUser.id)

      setData(newData);
      reset();
      sweetAlert("Done");
    } 
    setSpinner(false);
  }

  const getNotActiveUsers = async () => {
    const res = await notActiveUsers.request(pageNo, docsPerPage);
    if (res.status === 200) {
      res.data.data.forEach(person => {
        person.id = person.userId
      })
      setData(res.data.data);
    }
  }

  useEffect(() => {
    getNotActiveUsers()
  }, [pageNo, docsPerPage])

  const handleApprove = obj => {
    setCurrentUser(obj);
    setValue("fullName", obj.fullName)
    setValue("whatsappPhone", obj.whatsappPhone)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  
  return (
    <div>
      {alert}
      {createCustomer.errorAlert}
      {notActiveUsers.errorAlert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      {notActiveUsers.loading ? <Spinner
        gate="#29bb64"
        bg="#fff"
      />
      : <>
        <SimpleHeader parents={["Approve users"]} />
        <Container className="mt--6" fluid>
          {Object.keys(currentUser).length > 0 && 
            <Card className="card-plain">
              <div className="d-flex justify-content-between">
                <h3 className={`mb-0 mt-1 ms-2 text-md-left`}>Customers</h3>
              </div>
              <CardBody>
                <form onSubmit={handleSubmit(handleCreate)}>
                  <Row>
                    <Col md="6" lg='4'>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          Name
                        </label>
                        <Controller
                          control={control}
                          name="fullName"
                          rules={{ required: "Name field is required" }}
                          render={({ field: { ref, onChange, value, ...field } }) => (
                            <Input
                              {...field}
                              id="example3cols1Input"
                              placeholder="Name"
                              type="text"
                              className={errors.name && "error"}
                              value={value || ""}
                              onChange={({ target: { value }}) => onChange(value)}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg='4'>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          Phone
                        </label>
                        <Controller
                          control={control}
                          name="whatsappPhone"
                          rules={{ 
                            required: "Phone field is required",
                            pattern: {
                              value: "/^(0|[1-9]\d*)(\.\d+)?$/",
                              message: "this field accept only numbers"
                            },
                          }}
                          render={({ field: { ref, onChange, value, ...field } }) => (
                            <Input
                              {...field}
                              id="example3cols1Input"
                              placeholder="phone"
                              type="number"
                              className={errors.phone && "error"}
                              value={value || ""}
                              onChange={({ target: { value }}) => onChange(value)}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button 
                    color="success" 
                    type="submit"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="ni ni-fat-add" />
                    </span>
                    <span>
                      Create
                    </span>
                  </Button>
                </form>
              </CardBody>
            </Card>
          }
          <Card>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 mt-1 ms-2 text-md-left`}>Approve users</h3>
            </div>
              <Pagination
                pageNo={pageNo} 
                setPageNo={setPageNo}
                docsPerPage={docsPerPage}
                setDocsPerPage={setDocsPerPage}
                dataLength={data.length}
              >
                <Table 
                  headers={headers}
                  columns={columns}
                  data={data || []}
                  handleApprove={handleApprove}
                >
                  <span to={`/en-admin/userdetails/${pageNo}/${docsPerPage}`}>
                    <div id="edit1" className="table-action cursor-pointer">
                      <i className="fas fa-eye fa-lg hover-info"></i>
                    </div>
                    <UncontrolledTooltip
                      delay={0}
                      target="edit1"
                      >
                      Show details
                    </UncontrolledTooltip>
                  </span>
                  <span fun="handleApprove">
                    <div id="aprove1" className="table-action cursor-pointer">
                      <i className="far fa-check-circle hover-success fa-lg" />
                    </div>
                    <UncontrolledTooltip
                      delay={0}
                      target="aprove1"
                      >
                      Approve user
                    </UncontrolledTooltip>
                  </span>
                </Table>
              </Pagination>
          </Card>
        </Container>
      </>}
    </div>
  )
}

export default ApproveUsers;