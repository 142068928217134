import React, { useState } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";

// router
import { BrowserRouter as Router, useRouteMatch } from "react-router-dom";

// core components
import ProfileHeader from "components/Headers/profile/ProfileHeader";

import getCulture from "utils/getCulture";

import env from "env";
import axios from "axios";

import RequestHanlder from "helper/RequestHandler";
import Spinner from "components/Spinner";

import content from "./content";

function Profile() {
  var token = localStorage.getItem(btoa("token"));

  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

  let rout = useRouteMatch();
  let culture = getCulture(rout.url);

  //alert messages
  const [alert, setAlert] = useState(null);
  //

  //loading for edit delete
  const [action, setAction] = useState(false);

  //loading for the get request
  const [loading, setLoading] = useState(true);

  //

  let variables = {
    name: useState(""),
    image: useState(""),
    fullName: useState(""),
    email: useState(""),
    nationalty: useState(""),
    birthday: useState(""),
    jobTitleName: useState(""),
    phone: useState(""),
  };

  function setter(key, val) {
    variables[key][1](val);
  }

  const id = atob(localStorage.getItem(btoa('employeeId')));
  const image = atob(localStorage.getItem(btoa("imgUrl")));

  return (
    <>
      {alert}

      {/* loading for edit and delete request */}
      {action ? <Spinner opacity={1} bg="#fff" gate="#f4365c" /> : null}

      {/* Loading until the get request is done */}
      {loading ? (
        <RequestHanlder
          fn={async () => {
            let data = await axios.get(`${env.employee()}/${id}`);
            var employe = data.data.data.employee;
            console.log({employe})
            setter(
              "name",
              culture === "ar" ? employe.firstName : employe.englishFirstName
            );
            setter(
              "fullName",
              culture === "ar" ? employe.fullName : employe.englishFullName
            );
            setter("image", employe.imageURL);
            setter(
              "nationalty",
              employe.nationality
                ? employe.nationality
                : content.notFound[culture]
            );
            setter(
              "jobTitleName",
              employe.jobTitleName
                ? employe.jobTitleName
                : content.notFound[culture]
            );
            setter(
              "birthday",
              employe.birthday
                ? employe.birthday.split("T")[0]
                : content.notFound[culture]
            );
            setter(
              "phone",
              employe.phone ? employe.phone : content.notFound[culture]
            );
          }}
          setLoading={setLoading}
          setAlert={setAlert}
          gate="#29bb64"
          bg="#fff"
        />
      ) : (
        <>
          <ProfileHeader name={variables.name[0]} />
          <Container className="mt--9" fluid>
            <Row className="justify-content-center mt--500">
              <Col
                className="order-xl-2"
                xl="4"
                style={culture === "en" ? { marginRight: 394 } : {}}
              >
                <Card
                  className="card-profile "
                  style={{
                    width: 800,
                    justifyContent: "center",
                    marginRight: -250,
                    marginTop: 50,
                  }}
                >
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={`${image}`}
                            // {variables.image[0]}
                          />
                        </a>
                      </div>
                    </Col>
                  </Row>
                  <CardHeader className="text-center border-0 pt-9 pt-md-4 pb-0 pb-md-4"></CardHeader>
                  <CardBody className="pt-0">
                    <div className="text-center mt-5">
                      <h5 className="h3">{variables.fullName[0]}</h5>
                      <div className="h5 font-weight-300">
                        <i className="ni location_pin mr-2" />
                        {content.nationalty[culture]}
                        {variables.nationalty[0]}
                      </div>
                      <div className="h5 font-weight-300">
                        <i className="ni location_pin mr-2" />
                        {content.birthday[culture]}: {"    "}
                        {variables.birthday[0]}
                      </div>
                      <div className="h5 mt-4">
                        <i className="ni business_briefcase-24 mr-2" />
                        {content.jobTitleName[culture]}: {"    "}
                        {variables.jobTitleName[0]}
                      </div>
                      <div>
                        <i className="ni education_hat mr-2" />
                        {content.phone[culture]}: {"    "}
                        {variables.phone[0]}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
}

export default Profile;
