import React, { Fragment, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import env from 'env';
import bulks from 'api/bulks';
import useApi from '../../../hooks/useApi';
import Spinner from '../../../components/Spinner';
import Table from "components/common/CustomTable";
import CreateBulkTable from '../components/CreateBulkTable';
import Pagination from 'components/common/Pagination';

const CustomerPopup = ({ setClients, clients = [], shipmentType, id, create = false, sweetAlert }) => {

  // Apis
  const getCustomers = useApi(bulks.getCustomers);
  const getClients = useApi(bulks.getClients);
  const findBulkByCode = useApi(bulks.findBulkByCode);

  // States
  const [pageNo, setPageNo] = useState(1);
  const [data, setData] = useState([]);
  const [docsPerPage, setDocsPerPage] = useState(5);
  const [clientCode, setClientCode] = useState('');

  const headers = ["Name", "Code", "Order Number", "No pkg", "No of actual pkg"];
  const columns = ['cus_Name', 'cus_Code', 'orderId', "noOfPkgs", "actualNoPackg"]

  if (shipmentType === 'aircargo') {
    headers.push('weight');
    headers.push('Actuall weight');
    columns.push('weight');
    columns.push('actualWeight')
  }

  if (shipmentType === 'lcl') {
    headers.push('volume');
    headers.push('Actual volume')
    columns.push('volume');
    columns.push('actualVolume');
  }

  headers.push("action");
  
  const getAllCustomers = async () => {
    const response = await getCustomers.request(shipmentType, id, pageNo, docsPerPage);

    if (response.ok) {
      setData(response.data.data)
    }
  }

  const getAllClients = async () => {
    const response = await getClients.request(shipmentType, id, pageNo, docsPerPage);

    if (response.ok) {
      response.data.data.forEach(item => {
        item.id = item.orderId
        item.actualNoPackg = 0;
        if (shipmentType === 'aircargo') {
          item.actualWeight = 0;
        } else {
          item.actualVolume = 0;
        }
      })

      setData(response.data.data)
    }
  }

  const getBulkByCode = async () => {

    // if search filed is empty get the normal request
    if (!clientCode) return getAllClients();

    const res = await findBulkByCode.request(shipmentType, clientCode, pageNo, docsPerPage);
    if (res.ok) {

      const editedResponse = [...res.data.data][0].data;
      editedResponse.forEach(item => {
        item.id = item.client
        item.actualNoPackg = 0;
        if (shipmentType === 'aircargo') {
          item.actualWeight = 0;
        } else {
          item.actualVolume = 0;
        }
      })

      setData(editedResponse);
    }
  }

  const handleSelect = obj => {

    // if multipe is true the 'customer' state should be an array to store multiple customers
    setClients(client => [...client, obj]);
  }
  
  // this function render approve icon
  // always shows approve icon if the 'multiple' prop if false
  // if 'multiple' prop is true toggle approve icon with 'x'
  const renderApprove = child => {
    return !clients.find(user => user.orderId === child.orderId)
  }

  // this function render 'x' icon
  // but this will only call if multiple is false
  const renderCancel = child => {
    return clients.find(user => user.orderId === child.orderId)
  }

  const handleUnselect = obj => {
    setClients(customers => customers.filter(cus => cus.orderId !== obj.orderId))
  }
  
  const handleChange = (obj, event) => {

    const { name, value } = event.target;

    // check if the name is actualNoPackg
    if (name === "actualNoPackg") {
      // The 'actualNoPackg' can't be more than the 'noOfPkgs'
      if (+event.target.value > obj.noOfPkgs) {
        // if it's more make it equal to the noOfPkgs
        obj.actualNoPackg = obj.noOfPkgs;
      } else {
        // else just update it
        obj.actualNoPackg = parseInt(+event.target.value) || 0;
      }
      // the same for the 'actualWeight' and 'weight' 
    } else if (name === "actualWeight") {
      if (+event.target.value > obj.weight) {
        obj.actualWeight = obj.weight;
      } else {
        obj.actualWeight = +event.target.value || 0;
      }
    } else if (name === 'actualVolume') {
      if (+event.target.value > obj.volume) {
        obj.actualVolume = obj.volume;
      } else {
        obj.actualVolume = +event.target.value || 0;
      }
    } else {
      obj[name] = value;
    }
    
    setClients(client => {
      const newClients = [...client];
      const index = newClients.findIndex(cli => cli.id === obj.id);
      newClients[index] = obj;
      return newClients;
    })
  }


  useEffect(() => {
    if (clientCode) return getBulkByCode();
    if (create) return getAllClients();
    getAllCustomers();
  }, [pageNo, docsPerPage])

  useEffect(() => (getCustomers.error && getCustomers.data) && sweetAlert(getCustomers.data ,true), [getCustomers.data])
  useEffect(() => (getClients.error && getClients.data) && sweetAlert(getClients.data ,true), [getClients.data])

  return (
    <div className='popup-bigger'>
      {getCustomers.loading || getClients.loading? <Spinner
          gate="#29bb64"
          bg="#fff"
        />
      : 
      <div>
        <div className='pd-sides-1'>
          <Row>
            {create && 
              <Fragment>
                <Col md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="client"
                    >
                      {create ? "Client code" : "Customer code"}
                    </label>
                    <Input
                      id="client"
                      type="text"
                      onChange={e => setClientCode(e.target.value)}
                      onKeyPress={(e) => e.key === 'Enter' && getBulkByCode()}
                    />
                  </FormGroup>
                </Col>
                <span style={{paddingTop: 7}}>
                  <Button 
                    color="success" 
                    type="button"
                    className='mt-4'
                    onClick={getBulkByCode}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-search" />
                    </span>
                    <span>
                      Search
                    </span>
                  </Button>
                </span>
              </Fragment>
            }
          </Row>
        </div>
        <ul className='popup-customer-list pd-sides-1'>
          {clients.map(user => (
            <li 
              key={user.id}
            >
              <i className="fas fa-times hover-danger" onClick={handleUnselect.bind(null, user)} />
              {user.cus_Name}
            </li>
          ))}
        </ul>
        <Pagination
          pageNo={pageNo}
          setPageNo={setPageNo}
          docsPerPage={docsPerPage}
          setDocsPerPage={setDocsPerPage}
          dataLength={data?.length || 0}
        >
          {!create ?
            <Table 
              data={data}
              headers={headers}
              columns={columns}
            />
          :
            <CreateBulkTable 
              data={data}
              headers={headers}
              columns={columns}
              renderCancel={renderCancel}
              handleSelect={handleSelect}
              renderApprove={renderApprove}
              handleUnselect={handleUnselect}
              handleChange={handleChange}
            >
              <span className="me-1" fun="handleSelect" condition="renderApprove">
                <div id="approve" className="table-action cursor-pointer">
                  <i className="fas fa-check fa-lg hover-info"></i>
                </div>
              </span>
              <span className="me-1" fun="handleUnselect" condition="renderCancel">
                <div id="remove" className="table-action cursor-pointer">
                  <i className="fas fa-times fa-lg hover-danger padding-half" />
                </div>
              </span>
            </CreateBulkTable>
          }
        </Pagination>
      </div>
      }
    </div>
  )
}

export default CustomerPopup