import client from './client';

const endpoint = '/v1/AirCargo';

const airCargoPreload = (page, docPerPage) => client.get(`${endpoint}/preload?Page=${page}&PostsPerPage=${docPerPage}`);

const createAirCargo = schema => client.post(`${endpoint}`, schema);

const updateCargo = (id, schema) => client.put(`${endpoint}/${id}`, schema);

const getAirCargo = id => client.get(`${endpoint}/GetById?id=${id}`)

const uploadImg = (id, imgs) => {
  const data = new FormData();
  for(let i = 0; i < imgs.length; i++) {
    data.append('Image', imgs[i]);
  }
  
  return client.put(`${endpoint}/img/${id}`, data)
}

const approveShipment = id => client.put(`${endpoint}/approve/${id}`)

const getOrderByCode = (id, page, docsPerPage) => (
  client.get(`${endpoint}/GetOrderByCode?id=${id}&Page=${page}&PostsPerPage=${docsPerPage}`)
);

const getByColumn = (col, val, page, docsPerPage) => (
  client.get(`${endpoint}/getbycoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`)
)

export default {
  airCargoPreload,
  createAirCargo,
  updateCargo,
  getAirCargo,
  uploadImg,
  approveShipment,
  getOrderByCode,
  getByColumn
}