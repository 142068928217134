import client from './client';

const endpoint = '/v1/News';

const getNews = (page, docPerPage) => client.get(`${endpoint}?Page=${page}&PostsPerPage=${docPerPage}`);

const createNews = (schema) => {
  const data = new FormData();
  data.append("titel", schema?.titel);
  // data.append("path", schema?.path);
  data.append("date", schema?.date);
  // data.append("isActive", schema?.isActive);
  data.append("descraption", schema?.descraption);
  data.append('image', schema.image[0]);
  return client.post(`${endpoint}`, data);
}

const updateNews = (id, schema) => client.put(`${endpoint}/${id}`, schema);


export default {
  getNews,
  createNews,
  updateNews
}