import { useState } from "react";

const useSearchPopup = (searchRequest, headers, columns, data = [], editResponse) => {

  const [hookData, setHookData] = useState([]);
  const [header, setHeader] = useState('');
  const [column, setColumn] = useState('');
  const [pageNo, setPageNo] = useState(1);
  const [docsPerPage, setDocsPerPage] = useState(5);
  const [openPopup, setOpenPopup] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [searchField, setSearchField] = useState('');

  // This function triggers when the user click on header in the table
  const handleHeaderClicked = (index) => {
    console.log(index);
    // 1) Open the popup
    setOpenPopup(true);
    // 2) Store the header name to use it for search
    setHeader(headers[index])
    console.log(columns)
    // 3) Store column name to display it in the popup
    /*
      if the header the first item is 'actions' that means we should decreace 1 from the columns[index]
      because then the headers will have more item than the columns by 1
    */
    setColumn(headers[0].toLowerCase() === 'actions' ? columns[index - 1] : columns[index])
    // console.log(data)
    setHookData(data.length ? [...data].splice(0, 5) : []);
  }

  // if the column name has 'text' in the name that means it's an enum
  // remove 'text' 
  const handleColumnName = () => column?.includes("Text") ? column.split("Text")[0] : column;
  
  const onSearch = async (args, fun) => {
    // Request The data 
    // 'searchRequest' is an useApi hook

    const res = await searchRequest.request(handleColumnName(), searchField, pageNo, docsPerPage, args);
    if (res.status === 200) {
      res.data.data = res.data.data?.map(item => editResponse(item)) || [];
      setHookData(res.data.data);
      fun();
    }
  }

  const handleClose = () => {
    setOpenPopup(false);
  }

  const initPagination = () => {
    setPageNo(1);
    setDocsPerPage(5);
    setSearchField('');
  }

  return {
    hookData,
    header, 
    column,
    onSearch,
    openPopup,
    isSelected,
    setIsSelected,
    pageNo,
    setPageNo,
    docsPerPage,
    setDocsPerPage,
    searchField,
    setSearchField,
    handleClose,
    initPagination,
    handleColumnName,
    handleHeaderClicked,
  }
}

export default useSearchPopup;