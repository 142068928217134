import React, { useState, useEffect, useRef, Fragment } from 'react'
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  CardHeader
} from "reactstrap";
import SimpleHeader from 'components/Headers/SimpleHeader';
import { useParams } from 'react-router';
import Gallery from 'components/common/Gallery';
import useApi from 'hooks/useApi';
import bulks from 'api/bulks';
import Spinner from '../../../components/Spinner';
import CustomerPopup from '../components/CustomerPopup';
import TextPopup from 'components/common/TextModal';
import useAlert from 'hooks/useAlert';
import FilePicker from 'components/common/FilePicker';

const BulkDetails = () => {

  // params  
  const { shipmentType, id, pgNo, docsPerPg } = useParams();

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();

  //Apis
  const bulkDetails = useApi(bulks.bulkDetails);
  const uploadImages = useApi(bulks.uploadImg);
  const updateBulkArrivalDate = useApi(bulks.updateBulkArrivalDate);
  const arrivedBulk = useApi(bulks.arrivedBulk);

  // states
  const [data, setData] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [customerModal, setCustomerModal] = useState(false);
  const [imgs, setImgs] = useState({});
  const [arrivalDate, setArrivalDate] = useState('');
  const [arrivalDateModal, setArrivalDateModal] = useState(false);
  const [arrivedModal, setArrivedModal] = useState(false);

  const getBulkDetails = async () => {
    const response = await bulkDetails.request(shipmentType, id);
    if (response.ok) {
      setData(response.data.data)
    } else {
      sweetAlert(bulkDetails.data, true);
    }
  }
 
  const handleSubmit = e => {
    e.preventDefault();
    setArrivalDateModal(true);
  }

  const handleArrived = async () => {
    setArrivedModal(false);
    setSpinner(true);
    const res = await arrivedBulk.request(shipmentType, id);

    if (res.ok) {
      setData({
        ...data,
        followUpStatus: 4
      })
      sweetAlert("Done")
    }

    setSpinner(false);
  }

  const handleUpdate = async () => {
    setArrivalDateModal(false);
    setSpinner(true);
    const res = await updateBulkArrivalDate.request(shipmentType, id, arrivalDate)

    if (res.ok) {
      setData({
        ...data,
        daDate: `${arrivalDate}T00:00:00`
      })
      setArrivalDate('');
      sweetAlert("Done");
    }

    setSpinner(false);
  }

  const handleUploadImages = async e => {
    e.preventDefault();
    setSpinner(true);
    // const imgs = imgs;
    const response = await uploadImages.request(shipmentType, id, imgs);

    if (response.ok) {
      setImgs({});
      sweetAlert("Done");
      getBulkDetails();
    } else {
      sweetAlert(uploadImages.data, true);
    }
    setSpinner(false);
  }

  const handleCancel = () => {
    setCustomerModal(false);
    setArrivalDateModal(false);
    setArrivedModal(false);
  }

  useEffect(() => {
    getBulkDetails();
  }, [])
  
  return (
    <div>
      {alert}
      {bulkDetails.errorAlert}
      {uploadImages.errorAlert}
      {arrivedBulk.errorAlert}
      {updateBulkArrivalDate.errorAlert}
      {spinner && <Spinner gate="#29bb64" bg="#fff" opacity />}
      {bulkDetails.loading ? <Spinner
          gate="#29bb64"
          bg="#fff"
        />
      : <>
        <SimpleHeader parents={["Bulk"]} backBtn={`/en-admin/bulk/${shipmentType}/${pgNo}/${docsPerPg}`} />
        <Container className="mt--6" fluid>
        <Card className="card-plain">
            <CardHeader>
              <div className="d-flex justify-content-between">
                <h3 className={`mb-0 text-md-left`}>Bulk details</h3>
              </div>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="client"
                      >
                        Client
                      </label>
                      <div className='bulk-clients'>
                        <span>Click to view clients</span>
                        <span onClick={() => setCustomerModal(true)}>
                          <div id="details1" className="table-action cursor-pointer mt-1 me-1">
                            <i className="far fa-eye fa-lg hover-success" />
                          </div>
                        </span>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="client"
                      >
                        Code
                      </label>
                      <Input
                        id="client"
                        type="text"
                        defaultValue={data.bulkPckgNum}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="client"
                      >
                        Number of packages
                      </label>
                      <Input
                        id="client"
                        type="text"
                        defaultValue={data.noOfPkgs}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="weight"
                      >
                        {shipmentType === 'aircargo' ? 'Weight' : 'Volume'}
                      </label>
                      <Input
                        id="weight"
                        type="text"
                        defaultValue={shipmentType === 'aircargo' ? data.weight : data.volume}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg='4'>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="client"
                      >
                        Arrival date (weekly update)
                      </label>
                      <Input
                        id="client"
                        type="date"
                        disabled={data.followUpStatus === 4 ? true : false}
                        value={arrivalDate || data.daDate?.split('T')[0] || ""}
                        onChange={e => setArrivalDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                  {data.followUpStatus !== 4 &&
                    <>
                      <Button 
                        color="success" 
                        type="button"
                        className='mt-4'
                        onClick={() => setArrivedModal(true)}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-check"></i>
                        </span>
                        <span>
                          Arrived
                        </span>
                      </Button>
                      <Button 
                        color="primary" 
                        type="submit"
                        className='mt-4'
                        disabled={!arrivalDate}
                      >
                        <span>
                          Update Date
                        </span>
                      </Button>
                    </>
                  }
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <div className="d-flex justify-content-between">
                <h3 className={`mb-0 text-md-left`}>Upload images</h3>
              </div>
            </CardHeader>   
            <CardBody>
              <form onSubmit={handleUploadImages}>
                <Row>
                  <Col>
                    <FilePicker images={imgs} setImages={setImgs} />
                  </Col>
                </Row>
                <Button 
                  color="success" 
                  type="submit"
                  className='mt-4'
                  // disabled={false}
                  onClick={() => {}}
                  disabled={!Object.keys(imgs).length}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-long-arrow-alt-up"></i>
                  </span>
                  <span>
                    Upload
                  </span>
                </Button>
              </form>
            </CardBody>
            <TextPopup
              modal={customerModal}
              text="Update"
              handleCancel={handleCancel}
              fn={() => {}}
              color='success'
              noBtn
            >
              <CustomerPopup 
                sweetAlert={sweetAlert}
                shipmentType={shipmentType}
                id={id}
              />
            </TextPopup>
            <TextPopup
              modal={arrivalDateModal}
              text="Update"
              handleCancel={handleCancel}
              fn={handleUpdate}
              color='success'
            >
              <Container>
                <h3>
                  Are you sure you want to update the arrival date to
                  <span style={{color: 'var(--primary)'}}> {arrivalDate}</span>
                  ?
                </h3>
              </Container>
            </TextPopup>
            <TextPopup
              modal={arrivedModal}
              text="Yes"
              handleCancel={handleCancel}
              fn={handleArrived}
              color='success'
            >
              <Container>
                <h3>
                  Are you sure you want to change shipment state to arrived ?
                </h3>
              </Container>
            </TextPopup>
          </Card>
        </Container>
        <Gallery imgs={data.imges} />
      </>}
    </div>
  )
}

export default BulkDetails