import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import SimpleHeader from 'components/Headers/SimpleHeader';
import Table from "components/common/CustomTable";
import useApi from 'hooks/useApi';
import Spinner from '../../../components/Spinner';
import env from 'env';
import { useForm, Controller } from "react-hook-form";
import Pagination from "components/common/Pagination";
import TextPopup from 'components/common/TextModal';
import CustomerPopup from '../components/CustomerPopup';
import { useLocation, useParams } from 'react-router';
import bulks from 'api/bulks';
import useAlert from 'hooks/useAlert';
import SearchModal from 'components/common/SearchModal';
import useSearchPopup from 'hooks/useSearchPopup';
import FilePicker from 'components/common/FilePicker';

function BulkActions() {

  const headers = [ "actions", "Code", "Date", "Status", "NO of pkgs"];

  const columns = ["bulkPckgNum", "daDate", "followUpStatusText", 'noOfPkgs'];
  
  // Params
  const { shipmentType, pgNo, docsPerPg } = useParams(); 

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();

  // Apis
  const getAllBulks = useApi(bulks.getAllBulks);
  const createBulk = useApi(bulks.createBulk);
  const uploadImages = useApi(bulks.uploadImg);
  const getByColumn = useApi(bulks.getByColumn);

  // states
  const [data, setData] = useState({});
  const [images, setImages] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [pageNo, setPageNo] = useState(+pgNo);
  const [clients, setClients] = useState([]);
  const [tableModal, setTableModal] = useState(false);
  const [docsPerPage, setDocsPerPage] = useState(+docsPerPg);

  const editResponse = post => {
    post.id = post.bulkId;
    if (post.daDate) {
      post.daDate =  post.daDate.split('T')[0];
    } else {
      post.daDate = post.dl ? post.dl.split('T')[0] : null
    }
            
    const thisStatusIndex = env.followUpStatusBulk.findIndex(status => status.key === post.followUpStatus);
    post.followUpStatusText = env.followUpStatusBulk[thisStatusIndex]?.text;

    post.bulkTypeText = env.bulkType[post.bulkType - 1];
    
    return post
  }

  // use form hook
  const { handleSubmit, formState: { errors }, control, setValue, reset } = useForm();
  const searchPopup = useSearchPopup(getByColumn, headers, columns, data.posts, editResponse);

  const location = useLocation();

  if (shipmentType === 'aircargo') {
    headers.push('weight');
    columns.push('weight');
  } else if (shipmentType === 'lcl') {
    headers.push('volume');
    columns.push('volume');
  }

  const postClient = clients.map(client => {

    const newClient =  {
      actualNoPackg: client.actualNoPackg,
      orderId: client.orderId,
      cus_Code: client.cus_Code,
    }

    if (shipmentType === "aircargo"){
      newClient.actualWeight = client.actualWeight;
    } else {
      newClient.actualVolume = client.actualVolume;
    }

    return newClient;
  })
  
  const noOfPkgs = clients.reduce((total, current) => {
    if (current.actualNoPackg) return current.actualNoPackg + total;
    return current.noOfPkgs + total;
  }, 0)

  setValue('noOfPkgs', noOfPkgs);

  const totalWeight = clients.reduce((total, current) => {
    if (current.actualWeight) return current.actualWeight + total;
    return current.weight + total;
  }, 0) || 0;

  setValue("weight", totalWeight);
  
  const totalVolume = clients.reduce((total, current) => {
    if (current.actualVolume) return current.actualVolume + total;
    return current.volume + total;
  }, 0) || 0;

  setValue("volume", totalVolume);

  const handleCancel = () => {
    setTableModal(false);
  }

  const allBulksPreload = async () => {
    const res = await getAllBulks.request(shipmentType, pageNo, docsPerPage);
    if (res.ok) {
      const editedResponse = res.data.data.posts.map(post => editResponse(post));
      res.data.data.posts = editedResponse;
      setData(res.data.data);
    }
  }

  const handleUploadImages = async (bulkId) => {

    const response = await uploadImages.request(shipmentType, bulkId, images);
    return response.ok || false;
  }

  const handleCreate = async formData => {
    setSpinner(true);

    const schema = {
      orderId: '',
      ...formData,
      bulk: postClient,
    }
    
    const res = await createBulk.request(shipmentType, schema);

    if (res.ok) {

      if (images.length) {
        await handleUploadImages(res.data.data.bulkId);
      }
      setImages({});
      reset();
      allBulksPreload();
      // setData(data => ({
      //   ...data,
      //   posts: [
      //     res.data.data,
      //     ...data.posts
      //   ]
      // }))
      sweetAlert("Done")
    }
    setSpinner(false);
  }

  const handleDisabled = () => {
    const clientsCopy = [...clients];

    for (let i = 0; i < clientsCopy.length; i++) {
      if (clientsCopy[i].actualNoPackg > 0 && clientsCopy[i].actualWeight <= 0 ) return true;
      if (clientsCopy[i].actualNoPackg > 0 && clientsCopy[i].actualVolume <= 0) return true;
    }

    return false;
  }

    
  const updateData = obj => {
    const editedResponse = obj.map(response => editResponse(response))
    setData(data => ({
      ...data,
      posts: editedResponse
    }))
  }

  useEffect(() => {
    if (searchPopup.isSelected && searchPopup.searchField) {
      searchPopup.onSearch(shipmentType, () => {});
    } else {
      searchPopup.setIsSelected(false);
      allBulksPreload();
    }  
  }, [pageNo, docsPerPage, location])

  useEffect(() => {
    // if 
    if (searchPopup.isSelected && searchPopup.searchField) {
      searchPopup.onSearch('aircargo', () => {});
    } else {
      searchPopup.setIsSelected(false);
    }
  }, [searchPopup.pageNo, searchPopup.docsPerPage, location])

  return (
    <div>
      {alert}
      {getAllBulks.errorAlert}
      {createBulk.errorAlert}
      {uploadImages.errorAlert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      {getAllBulks.loading ? <Spinner
        gate="#29bb64"
        bg="#fff"
      />
      : <>
        <SimpleHeader parents={["Bulk"]} />
        <Container className="mt--6" fluid>
        <Card className="card-plain">
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 mt-1 ms-2 text-md-left`}>Bulk</h3>
            </div>
            <CardBody>
              <form onSubmit={handleSubmit(handleCreate)}>
                <Row>
                  <Col md="6" lg='4'>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        Client
                      </label>
                      <Controller
                        control={control}
                        name="client"
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <Input
                            {...field}
                            id="example3cols1Input"
                            placeholder="Client"
                            type="text"
                            className={errors.noOfPkgs && "error"}
                            value={`${postClient.length} selected`}
                            onClick={() => setTableModal(true)}
                            onChange={({ target: { value }}) => onChange(value)}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg='4'>
                    {shipmentType === "aircargo" ?
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          Weight
                        </label>
                        <Controller
                          control={control}
                          name="weight"
                          // rules={{ 
                          //   required: "weight field is required",
                          //   valueAsNumber: true,
                          //   validate: (value) => value > 0 
                          // }}
                          render={({ field: { ref, onChange, value, ...field } }) => (
                            <Input
                              {...field}
                              id="example3cols1Input"
                              placeholder="Code"
                              type="number"
                              disabled
                              className={errors.weight && "error"}
                              value={value || 0}
                            />
                          )}
                        />
                      </FormGroup>
                    :
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          Volume
                        </label>
                        <Controller
                          control={control}
                          name="volume"
                          // rules={{ 
                          //   required: "Voluem field is required",
                          //   valueAsNumber: true,
                          //   validate: (value) => value > 0 
                          // }}
                          render={({ field: { ref, onChange, value, ...field } }) => (
                            <Input
                              {...field}
                              id="example3cols1Input"
                              type="number"
                              disabled
                              className={errors.volume && "error"}
                              value={value || 0}
                              onChange={({ target: { value }}) => onChange(+value)}
                            />
                          )}
                        />
                      </FormGroup>
                    }
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="noOfPkgs"
                      >
                        Number of packages
                      </label>
                      <Controller
                          control={control}
                          name="noOfPkgs"
                          // rules={{ 
                          //   required: "noOfPkgs field is required",
                          //   valueAsNumber: true,
                          //   validate: (value) => value > 0 
                          // }}
                          render={({ field: { ref, onChange, value, ...field } }) => (
                            <Input
                              {...field}
                              id="noOfPkgs"
                              placeholder="Code"
                              type="number"
                              disabled
                              className={errors.noOfPkgs && "error"}
                              value={value}
                              onChange={({ target: { value }}) => onChange(+value)}
                            />
                          )}
                        />
                      {/* <Input
                        id="example3cols1Input"
                        type="number"
                        value={noOfPkgs}
                        disabled
                      /> */}
                    </FormGroup>
                  </Col>
                  <Col md="6" lg='4'>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        Country
                      </label>
                      <Controller
                        control={control}
                        name="country"
                        // rules={{ required: "Country feild is required" }}
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <Input
                            {...field}
                            id="example3cols1Input"
                            placeholder="Weight"
                            type="select"
                            className={errors.country && "error"}
                            value={value || true}
                            onChange={({ target: { value }}) => onChange(Number(value))}
                          >
                            <option disabled value> -- select an option -- </option>
                            {env.country.map((status, i) => <option key={i} value={i + 1}>{status}</option>)}
                          </Input>
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <FilePicker images={images} setImages={setImages} />
                    </FormGroup>
                  </Col>
                </Row>
                <Button 
                  color="success" 
                  type="submit"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="ni ni-fat-add" />
                  </span>
                  <span>
                    Create
                  </span>
                </Button>
              </form>
            </CardBody>
          </Card>
          <Card className="card-plain">
            {/* <div className='padding-around pb-0d'>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      Shipment number
                    </label>
                    <Input
                      id="example3cols1Input"
                      placeholder="search"
                      type="text"
                      // value={client}
                      // onChange={e => setClient(e.target.value) }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div> */}
            <Pagination
              dataLength={data.posts?.length}
              // when the user search using the popup and select a row
              // after then only use the pagination states from searchPopup hook
              pageNo={searchPopup.isSelected ? searchPopup.pageNo : pageNo} 
              setPageNo={searchPopup.isSelected ? searchPopup.setPageNo : setPageNo}
              docsPerPage={searchPopup.isSelected ? searchPopup.docsPerPage : docsPerPage}
              setDocsPerPage={searchPopup.isSelected ? searchPopup.setDocsPerPage : setDocsPerPage}

              >
              <Table
                headers={headers}
                columns={columns}
                // when the user search using the popup and select a row
                // after then only use the hookData states from searchPopup hook
                data={searchPopup.isSelected ? searchPopup.hookData : data.posts}
                onHeaderClick={searchPopup.handleHeaderClicked}
              >
                <span to={`/en-admin/bulk/${shipmentType}/${pageNo}/${docsPerPage}`}>
                  <div id="details1" className="table-action cursor-pointer">
                    <i className="far fa-eye fa-lg hover-success"></i>
                  </div>
                  <UncontrolledTooltip
                    delay={0}
                    target="details1"
                  >
                    show details
                  </UncontrolledTooltip>
                </span>
              </Table>
            </Pagination>
            <TextPopup
              modal={tableModal}
              text="Next"
              disabled={handleDisabled()}
              handleCancel={handleCancel}
              fn={handleCancel}
              color='primary'
            >
              <CustomerPopup 
                multiple
                create
                shipmentType={shipmentType}
                setValue={setValue}
                clients={clients}
                sweetAlert={sweetAlert}
                setClients={setClients}
                setTableModal={setTableModal}
              />
            </TextPopup>
            {searchPopup.openPopup && 
              <SearchModal
                data={data.posts}
                setData={setData}
                updateData={updateData}
                searchPopup={searchPopup}
                editResponse={editResponse}
                page="Bulk"
              />
            }
          </Card>
        </Container>
      </>}
    </div>
  )
}

export default BulkActions