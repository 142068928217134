import client from './client';

const endpoint = '/v1/Invoice';

const getAllInvoice = (page, docPerPage, shipment) => client.get(`${endpoint}?Page=${page}&PostsPerPage=${docPerPage}&shipment=${shipment}`);

const InvoicePreload = (page, docPerPage) => client.get(`${endpoint}/Preload?Page=${page}&PostsPerPage=${docPerPage}`);

const createInvoice = schema => client.post(`${endpoint}/invoiceWithClient`, schema);

const updateInvoice = (id, schema) => client.put(`${endpoint}/${id}`, schema);

const approveContacted = (id, schema) => client.put(`${endpoint}/approvecontacted/${id}`, schema);

const getInvoice = id => client.get(`${endpoint}/GetById?id=${id}`);

const approveInvoice = (id, shipmentType) => client.put(`${endpoint}/Approve/${id}?shipment=${shipmentType}`);

const wrongContact = (id) => client.put(`${endpoint}/ApproveWrong/${id}?shipment=1`);

const savedContact = (id, schema) => client.put(`${endpoint}/saved/${id}`, schema)

const approveSwift = (id, shipmentType, imgs) => {
  const data = new FormData();
  for(const key in imgs) {
    data.append('Image', imgs[key]);
  }
  
  return client.put(`${endpoint}/ApproveSwift/${id}?shipment=${shipmentType}`, data)
}

const getByColumn = (col, val, page, docsPerPage, shipmentType) => (
  client.get(`${endpoint}/${shipmentType}/getbycoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`)
)

const deleteAir = id => client.delete(`${endpoint}/deleteAir/${id}`);

const deleteLcl = id => client.delete(`${endpoint}/deleteLcl/${id}`);

const updateAir = (id, schema) => client.put(`${endpoint}/updateAir/${id}`, schema);

const updateLcl = (id, schema) => client.put(`${endpoint}/updateLcl/${id}`, schema);

export default {
  InvoicePreload,
  createInvoice,
  updateInvoice,
  getInvoice,
  getAllInvoice,
  approveInvoice,
  approveContacted,
  approveSwift,
  wrongContact,
  savedContact,
  getByColumn,
  deleteAir,
  deleteLcl,
  updateAir,
  updateLcl
}