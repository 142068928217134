import client from './client';

const endpoint = '/User';

const notActiveUsers = (page, docPerPage) => client.get(`${endpoint}/UserActive?Page=${page}&PostsPerPage=${docPerPage}`);

const findById = id => client.get(`${endpoint}/UserById?id=${id}`);

export default {
  notActiveUsers,
  findById
}