import React from "react";

// reactstrap components
import { 
  Table,
  UncontrolledTooltip, 
  FormGroup, 
  Input
} from "reactstrap";

import { Link } from 'react-router-dom';

function Tables(props) {

  const passDataToChildren = obj => {

    // loop though the children elements
    return React.Children.map(props.children, child => {

      // if the child attach to a condition function
      if (child.props.hasOwnProperty('condition')) {
        // Call the function and if it's false don't show the 'Element'
        if (!props[child.props.condition](obj)) return;
      }

      // if the child has "to" attibute wrap it in "Link" element
      if (child.props.hasOwnProperty('to')) {
        return React.cloneElement(<span className="me-1"><Link {...child.props} to={`${child.props.to}/${obj.id || obj.fclId || obj.lclId}`}>{child}</Link></span>)
      }
      
      // if the child attach to a function add the function to click event
      if (child.props.hasOwnProperty('fun')) {
        // the function comes from table props and the name of the child funtion comes from "fun" attribute
        return React.cloneElement(child, {onClick: () => props[child.props.fun](obj)})
      }

      // otherwise only clone the element
      return React.cloneElement(child)
    })
  }

  const nestedObj = (object, columns) => {
    // 1) get all the keys as an array 
    const keys = columns.split('.');
    // 2) loop throw the keys with the object parameters as an default value
    const nestedVal = keys.reduce((obj, prop) => obj[prop] ? obj[prop] : " " , object)
    // 3) return the nested value
    return nestedVal;
  }

  const CheckLength = content => {
    // 1) check if the content length is less 25 return the content
    if (String(content)?.length <= 25 || !content)
      return <>{content}</>;
    
    // 2) else return the substring content with tooltip
    return (
      <span>
        <div id="long-string">
          {content.substring(0, 25) + "..."}
        </div>
        <UncontrolledTooltip
          delay={0}
          target="long-string"
        >
          {content}
        </UncontrolledTooltip>
      </span>
    )    
  }

  return (
    <Table className="align-items-center" responsive>
      <thead className="thead-light">
          <tr>
            {props.headers.map((item, index) => (
              <th scope="col" key={index}>{item}</th>
            ))}
          </tr>
      </thead>
      <tbody>
        {props.data && props.data.map((item, index) => (
          <tr className='table-row' key={index}>
            {props.columns.map((column, index) => (
              column === "actualNoPackg" ?
                <th scope="row" className="bulk-pkg" key={column}>
                  <span>
                    <Input
                      id="example3cols1Input"
                      type="number"
                      name="actualNoPackg"
                      value={item.actualNoPackg}
                      max={item.noOfPkgs}
                      onChange={props.handleChange.bind(null, item)}
                      // value={client}
                      // onChange={e => setClient(e.target.value) }
                    />
                  </span>
                </th>
              : column === "actualWeight" ? (
                <th scope="row" className="bulk-pkg" key={column}>
                  <span>
                    <Input
                      className={item.actualNoPackg > 0 && item.actualWeight <= 0  ? "error" : ""}
                      name="actualWeight"
                      type="number"
                      disabled={item.actualNoPackg === 0}
                      min={0}
                      max={item.weight}
                      value={item.actualWeight || 0}
                      onChange={props.handleChange.bind(null, item)}
                      // value={client}
                      // onChange={e => setClient(e.target.value) }
                    />
                  </span>
                </th>
              )
              : column === "actualVolume" ? (
                <th scope="row" className="bulk-pkg" key={column}>
                  <span>
                    <Input
                      className={item.actualNoPackg > 0 && item.actualVolume <= 0  ? "error" : ""}
                      name="actualVolume"
                      type="number"
                      disabled={item.actualNoPackg === 0}
                      min={0}
                      max={item.weight}
                      value={item.actualVolume || 0}
                      onChange={props.handleChange.bind(null, item)}
                      // value={client}
                      // onChange={e => setClient(e.target.value) }
                    />
                  </span>
                </th>
              ) : <th scope="row" key={column}>
                  <span className="mb-0 text-sm custom-table-cell">
                    {/* if the key has "." it's means it is a nested like `item.chidl1.child2`  */}
                    {column.includes(".") ? CheckLength(nestedObj(item, column)) : CheckLength(item[column])}
                  </span>
                </th>
            ))}
            {/* {props.children ? <td>{passDataToChildren(item)}</td>: null} */}
            {props.children ? <td>
              <div className="d-flex align-items-center justify-content-start">
                {passDataToChildren(item)}
              </div>
            </td>: null}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default Tables