import React, { useEffect, useState } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  Form
} from "reactstrap";
import SimpleHeader from 'components/Headers/SimpleHeader';
import Table from "components/common/CustomTable"
import { useForm, Controller } from "react-hook-form";
import Spinner from '../../components/Spinner';
import useApi from '../../hooks/useApi';
import env from "env";
import calculator from 'api/calculator';
import useAlert from 'hooks/useAlert';
import { useLocation, useParams } from 'react-router';
import TextPopup from 'components/common/TextModal';

const Caclulator = () => {
  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();

  const params = useParams();
  const location = useLocation();

  // states
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [rowId, setRowId] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);

  // apis
  const getAllCalculators = useApi(calculator.getClaculators);
  const createCalculator = useApi(calculator.createCalculator);
  const deleteCalculator = useApi(calculator.deleteCalculator);

  // form validation
  const { handleSubmit, formState: { errors }, control, watch, reset } = useForm();

  const headers = ["actions", "Country", "shipping Type", "Price Range", "Price medical range", "price", "price type"];

  const columns = ["countryText", "shippingTypeText", "priceRangeText", "priceRangeMedicalText", "priceValue", "priceTypeName"];
  
  const preload = async () => {
    const res = await getAllCalculators.request(params.shipmentType);
    if (res.status === 200) {
      res.data.data = res.data.data.map(calc => editResponse(calc))
      setData(res.data.data);      
    } 
  }

  const createCalc = async formData => {
    setSpinner(true);
    const schema = {
      ...formData,
      priceValue: +formData.priceValue
    }

    if (formData.shippingType === 2) {
      delete schema.priceRange
    } else {
      delete schema.priceRangeMedical
    }

    const res = await createCalculator.request(params.shipmentType, schema);

    if (res.status === 200) {
      res.data.data = editResponse(res.data.data);
      const newData = [res.data.data, ...data];

      setData(newData);
      sweetAlert("Done");
    }
    setSpinner(false);
  }

  // This method create new instance with enum string value
  const editResponse = obj => {
    obj.priceTypeName = env.priceType[obj.priceType - 1];
    obj.priceName = env.price[obj.price - 1];
    obj.countryText = env.country[obj.country - 1];
    obj.unit = obj.shipmentType === 1 ? "kg" : "cbm";
    obj.shippingTypeText = env.shippingType[obj.shippingType - 1];
    obj.priceRangeText = env.priceRange[obj.priceRange - 1];
    obj.priceRangeMedicalText = env.priceRangeMedical[obj.priceRangeMedical - 1];
    return obj;
  }
  
  const handleDelete = obj => {
    setRowId(obj.id);
    setDeleteModal(true);
  }

  const handleCancel = () => {
    setRowId('');
    setDeleteModal(false);
  }

  const deleteRow = async () => {
    setDeleteModal(false);
    setSpinner(true);
    const res = await deleteCalculator.request(params.shipmentType, rowId)
    
    if (res.status === 200) {
      sweetAlert("Done");
      const newData = data.filter(item => item.id !== rowId)
      setData(newData);
    }
    setRowId('');
    setSpinner(false);
  }

  useEffect(() => {
    reset();
    preload();
  }, [location])

  // No field should be rendered in lcl after selecting shipping type
  const shippingType = params.shipmentType == 'air' ? watch("shippingType") : 0 ;

  return (
    <div>
      {alert}
      {getAllCalculators.errorAlert}
      {createCalculator.errorAlert}
      {deleteCalculator.errorAlert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      {getAllCalculators.loading ? <Spinner
        gate="#29bb64"
        bg="#fff"
      /> : <>
      <SimpleHeader parents={["Calculator"]} />
      <Container className="mt--6" fluid>
        <Card className="card-plain">
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>Caclulator {params.shipmentType}</h3>
            </div>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(createCalc)}>
              <Row>
                <Col md="6" lg='4'>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="price-range"
                    >
                      Shipping type
                    </label>
                    <Controller
                      control={control}
                      name="shippingType"
                      rules={{ required: "shipping Type field is required" }}
                      render={({ field: { ref, onChange, value, ...field } }) => (
                        <Input
                          {...field}
                          id="price-range"
                          type="select"
                          className={errors.shippingType && "error"}
                          defaultValue={true}
                          onChange={({ target: { value }}) => {
                            // setPriceValue(value)
                            onChange(Number(value))
                          }}
                        >
                          <option value={true} disabled>-- select option --</option>
                          {env.shippingType.map((item, i) =>  (
                            // shipping type 'machine CNC' shouldn't appear in 'Air' calculator drop list
                            params.shipmentType === 'air' && i === 2 ? 
                              <React.Fragment key={i}></React.Fragment>
                            : <option value={i+1} key={i}>{item}</option>
                          ))}
                        </Input>
                      )}
                    />
                  </FormGroup>
                </Col>
                {shippingType == 2 && 
                  <Col md="6" lg='4'>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="price-medical"
                      >
                        Price range medical
                      </label>
                      <Controller
                        control={control}
                        name="priceRangeMedical"
                        rules={{ required: "Price range medical field is required" }}
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <Input
                            {...field}
                            id="price-medical"
                            type="select"
                            className={errors.priceRangeMedical && "error"}
                            defaultValue={true}
                            onChange={({ target: { value }}) => {
                              onChange(Number(value))
                            }}
                          >
                            <option value={true} disabled>-- select option --</option>
                            {env.priceRangeMedical.map((item, i) => <option value={i+1} key={i}>{item}</option>)}
                          </Input>                        
                        )}
                      />
                    </FormGroup>
                  </Col>
                } 
                {shippingType > 0 && shippingType !== 2 &&
                 <Col md="6" lg='4'>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="priceRange"
                      >
                        Price range
                      </label>
                      <Controller
                        control={control}
                        name="priceRange"
                        rules={{ required: "Price Range field is required" }}
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <Input
                            {...field}
                            id="priceRange"
                            type="select"
                            className={errors.priceRange && "error"}
                            defaultValue={true}
                            onChange={({ target: { value }}) => {
                              onChange(Number(value))
                            }}
                          >
                            <option value={true} disabled>-- select option --</option>
                            {env.priceRange.map((item, i) => <option value={i+1} key={i}>{item}</option>)}
                          </Input>
                        )}
                      />
                    </FormGroup>
                  </Col>
                }
                <Col md="6" lg='4'>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="price-type"
                    >
                      Price type
                    </label>
                    <Controller
                      control={control}
                      name="priceType"
                      rules={{ required: "Name field is required" }}
                      render={({ field: { ref, onChange, value, ...field } }) => (
                        <Input
                          {...field}
                          id="price-type"
                          placeholder="Name"
                          type="select"
                          className={errors.priceType && "error"}
                          defaultValue={true}
                          onChange={({ target: { value }}) => onChange(Number(value))}
                        >
                          <option value={true} disabled>-- select option --</option>
                          {env.priceType.map((item, i) => <option value={i+1} key={i}>{item}</option>)}
                        </Input>
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="5" lg='3'>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="price-value"
                    >
                      Price value
                    </label>
                    <Controller
                      control={control}
                      name="priceValue"
                      rules={{
                        required: "Price value field is required",
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/
                        },
                      }}
                      render={({ field: { ref, onChange, value, ...field } }) => (
                        <Input
                          {...field}
                          id="price-value"
                          placeholder="price"
                          type="number"
                          className={errors.priceValue && "error"}
                          value={value || ""}
                          onChange={({ target: { value }}) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col xs="1" className='align-self-center mt-1'>
                  <Input 
                    type="text"
                    disabled
                    value={params.shipmentType == 'air' ? 'kg' : 'cbm'}
                    onChange={() => {}}
                  />
                </Col>
                <Col md="6" lg='4'>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      Country
                    </label>
                    <Controller
                      control={control}
                      name="country"
                      rules={{ required: "Name field is required" }}
                      render={({ field: { ref, onChange, value, ...field } }) => (
                        <Input
                          {...field}
                          id="example3cols1Input"
                          placeholder="Name"
                          type="select"
                          className={errors.country && "error"}
                          defaultValue={true}
                          onChange={({ target: { value }}) => onChange(Number(value))}
                        >
                          <option value={true} disabled>-- select option --</option>
                          {env.country.map((item, i) => <option value={i+1} key={i}>{item}</option>)}
                        </Input>
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button 
                color="success" 
                type="submit"
              >
                <span className="btn-inner--icon mr-1">
                  <i className="ni ni-fat-add" />
                </span>
                <span>
                  Create
                </span>
              </Button>
            </Form>
          </CardBody>
        </Card>
        <Card>
          <Table 
            headers={headers}
            columns={columns}
            data={data}
            handleDelete={handleDelete}
          >
            <span className='me-1' fun="handleDelete">
              <div id="delete" className="table-action cursor-pointer">
                <i className="fas fa-trash hover-danger fa-lg" />
              </div>
              <UncontrolledTooltip
                delay={0}
                target="delete"
              >
                Delete
              </UncontrolledTooltip>
            </span>
          </Table>
        </Card>
        <TextPopup
          modal={deleteModal}
          text="Yes"
          handleCancel={handleCancel}
          fn={deleteRow}
          color='danger'
        >
          <Container>
            <h2>Are you sure you want to delete this Row ?</h2>
          </Container>
        </TextPopup>
      </Container>
      </>}
    </div>
  )
}

export default Caclulator