import React, { useEffect, useState } from 'react'

// router 
import {
    useRouteMatch,
 } from "react-router-dom";
 
 // get culture from utils 
 import getCulture from "utils/getCulture";

import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  CardHeader,
} from "reactstrap";
import useApi from 'hooks/useApi';
import { useParams } from "react-router-dom";
import lclApi from "api/LCLBK";
import Spinner from '../../../components/Spinner';
import SimpleHeader from 'components/Headers/SimpleHeader';
import env from 'env';
import Gallery from 'components/common/Gallery';
import DocsCard from 'components/common/DocsCard';
//content 
import content from './components/content';

//common
import commenContent from "components/common/content";
import TextPopup from 'components/common/TextModal';
import useAlert from 'hooks/useAlert';
import FilePicker from 'components/common/FilePicker';


function DetailLCL() {

  let rout = useRouteMatch()
  let culture = getCulture(rout.url);
    
  // params
  const { id, pgNo, docsPerPg } = useParams();

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();

  // apis
  const getOneLCL = useApi(lclApi.getLCL);
  const uploadImgLCL = useApi(lclApi.uploadImgLCL);
  const approveLCL = useApi(lclApi.approveLCL);

  // states
  const [data, setData] = useState({});
  const [imgs, setImgs] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [approveModal, setApproveModal] = useState(false);

  const thisStatusIndex = env.followUpStatus.findIndex(status => status.key === data.followUpStatus);

  const nextStatus = env.followUpStatus[thisStatusIndex + 1];

  const isFile = file => {

    const extension = file.substr(file.lastIndexOf('.') - file.length + 1);

    if (extension === 'pdf')
        return true;
    if (extension === 'docx' || extension === 'dot')
      return true;
    if (extension === 'pptx' || extension === 'ppt' || extension === 'ppsx')
      return true;
    if (extension === 'xlsx' || extension === 'xlsm' || extension === 'xls' || extension === 'xlsb')
      return true;
    return false;
  }

  const files = [...data?.imges || ""].filter(item => isFile(item));
  const imges = [...data?.imges || ""].filter(img => !isFile(img))
  const swiftFiles = [...data?.swiftImges || ""].filter(item => isFile(item));
  const swiftImges = [...data?.swiftImges || ""].filter(img => !isFile(img));

  const getLCL = async () => {
    const res = await getOneLCL.request(id);
    
    Object.keys(res.data.data).forEach(key => {
      if (key.includes('date') || key.includes('Date')) {
        res.data.data[key] = res.data.data[key] ? res.data.data[key].split('T')[0] : "";
      }
    })

    if (res.status === 200) {
      setData(res.data.data)
    }

  }

  const handleUpload = async e => {
    e.preventDefault();
    setSpinner(true);
    const res = await uploadImgLCL.request(id, imgs);
    const newData = {...data};
    newData.imges = res.data.data.imges;
    setData(newData);
    
    if (res.status === 200) {
      sweetAlert("Done");
      setImgs({});
    }
    setSpinner(false);
  }

  const approvePopupHandler = e => {
    e.preventDefault();
    setApproveModal(true);
  }

  const approveHandler = async e => {
    e.preventDefault();
    setApproveModal(false);
    setSpinner(true);

    const res = await approveLCL.request(data.lclId);
    if (res.ok) {
      sweetAlert("Done")
      setData(data => ({
        ...data,
        followUpStatus: res.data.data.followUpStatus
      }))
    }
    setSpinner(false);
  }

  const handleCancel = () => setApproveModal(false);

  useEffect(() => {
    getLCL();
  }, [])

  return (
    <div>
      {alert}
      {getOneLCL.errorAlert}
      {uploadImgLCL.errorAlert}
      {spinner && <Spinner gate="#29bb64" bg="#fff" opacity />}
      {getOneLCL.loading ? <Spinner
        gate="#29bb64"
        bg="#fff"
      />
      : <>
        <SimpleHeader parents={[content.lcl[culture]]} backBtn={`/en-admin/lcl/${pgNo}/${docsPerPg}`} />
        <Container className="mt--6" fluid>
          <Card className="card-plain">
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 mt-1 ms-2 text-md-left`}>{content.lcl[culture]}</h3>
            </div>
            <CardBody>
              <form onSubmit={approvePopupHandler}>
                <Row>
                  <Col md="6" lg='4'>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        Order Id  
                      </label>
                      <Input
                        id="example3cols1Input"
                        type="text"
                        disabled
                        value={data.orderId || ""}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg='4'>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        Customer code
                      </label>
                      <Input
                        id="example3cols1Input"
                        placeholder="Client"
                        type="text"
                        value={data.cus_Code || ""}
                        disabled
                      />
                      </FormGroup>
                  </Col>
                  <Col md="6" lg='4'>
                    <FormGroup>
                        <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                        >
                        {content.volume[culture]}
                        </label>
                        <Input
                        id="example3cols1Input"
                        type="number"
                        value={data.volume || ""}
                        disabled
                        />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg='4'>
                    <FormGroup>
                        <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                        >
                        {content.noOfPkgs[culture]}
                        </label>
                        <Input
                        id="example3cols1Input"
                        type="number"
                        value={data.noOfPkgs || ""}
                        disabled
                        />
                    </FormGroup>
                  </Col>
                    <Col md="6" lg='4'>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        {content.country[culture]}
                      </label>
                      <Input
                        id="example3cols1Input"
                        placeholder="Follow up status"
                        type="select"
                        value={data.country || ""}
                        disabled
                      >
                        <option disabled value> -- select an option -- </option>
                        {env.country.map((status, i) => <option key={i} value={i + 1}>{status}</option>)}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="6" lg='4'>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        Follow up status
                      </label>
                      <Input
                        id="example3cols1Input"
                        type="text"
                        value={env.followUpStatus[thisStatusIndex]?.text || ""}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="commidityType"
                      >
                        Commodity type
                      </label>
                      <Input
                        id="commidityType"
                        placeholder="Follow up status"
                        type="select"
                        value={data.commidityType}
                        disabled
                      >
                        <option disabled value> -- select an option -- </option>
                        {env.commidityType.map((status, i) => <option key={i} value={i + 1}>{status}</option>)}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="commodity"
                      >
                        Commodity
                      </label>
                      <Input
                        id="commodity"
                        type="textarea"
                        disabled
                        value={data.commodity}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {/* {env.followUpStatus[thisStatusIndex]?.key < 6 && 
                  <Button 
                    color="success" 
                    type="submit"
                    className='mt-4'
                    onClick={() => {}}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-check" />
                    </span>
                    <span>
                      {nextStatus?.text || ""}
                    </span>
                  </Button>
                } */}
              </form>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <div className="d-flex justify-content-between">
                <h3 className={`mb-0 text-md-left`}>Upload images</h3>
              </div>
            </CardHeader>
            <CardBody>
              <form>
                <Row>
                  <Col>
                    <FilePicker images={imgs} setImages={setImgs} />
                  </Col>
                </Row>
                <Button 
                  color="success" 
                  type="submit"
                  className='mt-4'
                  disabled = {imgs.length===0}
                  onClick={handleUpload}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-long-arrow-alt-up"></i>
                  </span>
                  <span>
                    {commenContent.update[culture]}
                  </span>
                </Button>
              </form>
            </CardBody>
            <TextPopup
              modal={approveModal}
              text="Update"
              handleCancel={handleCancel}
              fn={approveHandler}
              color='success'
            >
              <Container>
                <h2>Are you sure you want to change the status to {nextStatus?.text || ""}</h2>
              </Container>
            </TextPopup>
          </Card>
          {swiftFiles.length ? <DocsCard title="Swift attachments" files={swiftFiles} /> : ""}
        </Container>
        <Gallery title="Swift image" imgs={swiftImges || []} />
        <Container>
          {files.length ? <DocsCard files={files} /> : ""}
        </Container>
        <Gallery imgs={imges} />
      </>}
    </div>
  )
}

export default DetailLCL