import React, { forwardRef } from 'react'
import {
  Col,
  FormGroup,
  Input,
  Row
} from "reactstrap";
import env from "env";
import FilePicker from 'components/common/FilePicker';

const EditAirshipment = ({ editSchema, setEditSchema, setFormIsEdited }) => {

  const handleChange = (key, val) => {

    // check if any field is edited except the 'imgs'
    if (key !== 'imgs') setFormIsEdited(true);

    setEditSchema({
      ...editSchema,
      [key]: val
    })
  }

  return (
    <div>
      <Row>
        <Col md="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="example3cols1Input"
            >
              Weight
            </label>
            <Input
              id="example3cols1Input"
              type="number"
              value={editSchema.weight || ""}
              onChange={e => handleChange('weight', Number(e.target.value))}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="example3cols1Input"
            >
              Number of packages
            </label>
            <Input
              id="example3cols1Input"
              type="number"
              value={editSchema.noOfPkgs || ""}
              onChange={e => handleChange('noOfPkgs', Number(e.target.value))}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="country"
            >
              Country
            </label>
            <Input
              id="country"
              placeholder="Follow up status"
              type="select"
              value={editSchema.country || ""}
              onChange={e => handleChange('country', e.target.value)}
            >
              <option disabled value> -- select an option -- </option>
              {env.country.map((status, i) => <option key={i} value={i + 1}>{status}</option>)}
            </Input>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="commidityType"
            >
              Commodity type
            </label>
            <Input
              id="commidityType"
              placeholder="Follow up status"
              type="select"
              value={editSchema.commType  || ""}
              onChange={e => handleChange('commType', +e.target.value)}
            >
              <option disabled value> -- select an option -- </option>
              {env.commidityType.map((status, i) => <option key={i} value={i + 1}>{status}</option>)}
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
        <FormGroup>
            <label
              className="form-control-label"
              htmlFor="commodity"
            >
              Commodity
            </label>
            <Input
              id="commodity"
              type="textarea"
              style={{resize: 'none'}}
              value={editSchema.commodity || ""}
              onChange={e => handleChange('commodity', e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col md="12">
          <label
            className='form-control-label'
            htmlFor='img'
          >
            upload img
          </label>
          <FilePicker images={editSchema.imgs} setImages={files => handleChange('imgs', files)} />
        </Col>
      </Row>
    </div>
  )
}

export default EditAirshipment