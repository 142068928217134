import client from './client';

const endpoint = '/v1/Filter';

const filter = (number, search, page, docsPerPage) => client.put(`${endpoint}?Number=${number}&Search=${search}&Page=${page}&PostsPerPage=${docsPerPage}`);

const dashboard = () => client.get(`${endpoint}/dashboard`);

export default {
  filter,
  dashboard
}