import React , { useState, useEffect } from 'react';

// router 
import {
   useRouteMatch,
} from "react-router-dom";

// get culture from utils 
import getCulture from "utils/getCulture";

// reactstrap components
import {
   Card,
   CardHeader,
   CardBody,
   FormGroup,
   Input,
   Button,
   Container,
   Row,
   Col,
   UncontrolledTooltip
} from "reactstrap";
//content 
import content from './components/content';

//common
import commenContent from "components/common/content";

// core components
import SimpleHeader from 'components/Headers/SimpleHeader';

// BK
import Table from "components/common/CustomTable";
import useApi from '../../../hooks/useApi';
import fclApi from "api/FCLBK";
import Spinner from '../../../components/Spinner';
import env from 'env';
import { useForm, Controller } from "react-hook-form";
import Pagination from "components/common/Pagination";
import TextPopup from 'components/common/TextModal';
import EditFCL from "./components/EditFCL"
import filter from 'api/filter';
import PopupTable from 'components/common/PopupTableUsed';
import useAlert from 'hooks/useAlert';

function Fcl() {

   
    let rout = useRouteMatch()
    let culture = getCulture(rout.url);

   // useAlert custom hook
   const { alert, sweetAlert } = useAlert();
   
   // Apis
   const getFCLPreload = useApi(fclApi.FCLPreload);
   const createFCL = useApi(fclApi.createFCL);
   const updateFCL = useApi(fclApi.updateFCL);
   const approveFCL = useApi(fclApi.approveFCL);
   const uploadImgFCL = useApi(fclApi.uploadImgFCL);
   const filterShipment = useApi(filter.filter);
 
   // States
   const [data, setData] = useState([]);
   const [spinner, setSpinner] = useState(false);
   const [editModal, setEditModal] = useState(false);
   const [editSchema, setEditSchema] = useState({});
   const [pageNo, setPageNo] = useState(1);
   const [docsPerPage, setDocsPerPage] = useState(10);
   const [search, setSearch] = useState("");
   const [formIsEdited, setFormIsEdited] = useState(false);
   const [customer, setCustomer] = useState({});
   const [tableModal, setTableModal] = useState(false);

   // Use form hook
  const { reset, handleSubmit, formState: { errors }, control, setValue } = useForm();

  const getFCL = async () => {
   const res = await getFCLPreload.request(pageNo, docsPerPage);
   if (res.status === 200) {
   res?.data.data[0].data.forEach(post => {
      // This code will split any date by checking it's key    
      Object.keys(post).forEach(key => {
        if (key.includes('date') || key.includes('Date')) {
          post[key] = post[key] ? post[key].split('T')[0] : "";
        }
      })

      // Get this shipment status index
      const thisStatusIndex = env.followUpStatus.findIndex(status => status.key === post.followUpStatus);
      // Replace the normal status with the text from env variable
      post.followUpStatusName = env.followUpStatus[thisStatusIndex]?.text || "";
      // Replace the normal country with the replacement text from env variable
      post.countryName = env.country[post.country - 1];
   })
   
   setData(res.data.data[0]);
  }   
  }

   const handleCreate = async formData => {
    setSpinner(true);
    
    const schema = {
      ...formData,
      client: customer.cusId,
      orderId: ''
    }

    const res = await createFCL.request(schema);
    if (res.status === 200) {
      // const newData = { ...data };
      // Object.keys(res.data.data).forEach(key => {
      //   if (key.includes('date') || key.includes('Date')) {
      //     res.data.data[key] = res.data.data[key] ? res.data.data[key].split('T')[0] : "";
      //   }
      // })
      // // Replace the normal status with the text from env variable
      // res.data.data.followUpStatusName = env.status.en[res.data.data.followUpStatus - 1];
      // // Replace the normal country with the replacement text from env variable
      // res.data.data.countryName = env.country[res.data.data.country - 1];
      
      // newData.data.unshift(res.data.data)
      // setData(newData);
      getFCL();
      reset();
      sweetAlert(commenContent.done[culture])
    }
    setSpinner(false);

  }

    const handleUpload = async () => {
      const res = await uploadImgFCL.request(editSchema.fclId, editSchema.imgs);
      return res?.ok || false;
    }

    const editFCL = async () => {
      setSpinner(true);
      setEditModal(false);
      const schema = {
        dateEstArrival: editSchema.dateEstArrival,
        billLading: editSchema.billLading,
        country: editSchema.country,
        contNum: editSchema.contNum,
        sealNum: editSchema.sealNum,
      }
      
      // Assing 'uploadSucceed' varibale with treu default value
      let uploadSucceed = true;

      // if the 'editSchema' has 'imgs' attribute and the 'imgs' attribute has a value 
      // 1) upload the imgs
      // 2) override the 'uploadSucceed' varibale
      if (editSchema?.imgs) 
         uploadSucceed = await handleUpload();

      // if the upload fails don't continue to the next request
      if (!uploadSucceed) return setSpinner(false);
      // if no other filed edited don't continue to the next request
      if (!formIsEdited) {
         sweetAlert("Done");
         return setSpinner(false);
      }

      const res = await updateFCL.request( editSchema.fclId, schema);
  
      if (res.status === 200) {
        const newData = {...data};
        const editedIndex = newData.data.findIndex(item => item.fclId === editSchema.fclId);
        Object.keys(res.data.data).forEach(key => {
          if (key.includes('date') || key.includes('Date')) {
            res.data.data[key] = res.data.data[key] ? res.data.data[key].split('T')[0] : "";
          }
        })
        // Replace the normal status with the text from env variable
        res.data.data.followUpStatusName = env.status.en[res.data.data.followUpStatus - 1];
        // Replace the normal country with the replacement text from env variable
        res.data.data.countryName = env.country[res.data.data.country - 1];
  
        // Append id filed to use it in the table component
        res.data.data.id = res.data.data.fclId;
  
        newData.data[editedIndex] = res.data.data;
        
        setData(newData);
        setEditSchema({});
        setFormIsEdited(false);
        setEditModal(false);
        sweetAlert(commenContent.done[culture])
      }      
      setSpinner(false);
    }

    const handleApprove = async (obj) => {
      setSpinner(true);
      const res = await approveFCL.request( obj.fclId );

      if (res.status === 200) {
         const newData = {...data};
         const editedIndex = newData.data.findIndex(item => item.fclId === obj.fclId);
         Object.keys(res.data.data).forEach(key => {
           if (key.includes('date') || key.includes('Date')) {
             res.data.data[key] = res.data.data[key] ? res.data.data[key].split('T')[0] : "";
           }
         })
         // Replace the normal status with the text from env variable
         res.data.data.followUpStatusName = env.status.en[res.data.data.followUpStatus - 1];
         // Replace the normal country with the replacement text from env variable
         res.data.data.countryName = env.country[res.data.data.country - 1];
   
         // Append id filed to use it in the table component
         res.data.data.id = res.data.data.fclId;
   
         newData.data[editedIndex] = res.data.data;
         
         setData(newData);
         sweetAlert(commenContent.done[culture])
       }

      setSpinner(false);
      
   }

   const headers = [
      "order Id",
      "Customer code",
      "Customer name",
      "follow Up Status",
      "Deprature date",
      "seal No.",
      "cont No.",
      "country",
      "billLading",
      "actions"
    ]
  
    const columns = [
      "orderId",
      "cus_Code",
      "cus_Name",
      "followUpStatusName",
      "date",
      "sealNum",
      "contNum",
      "countryName",
      "billLading",
    ]

    const handleEdit = obj => {
      setEditSchema(obj);
      setEditModal(true);
      setFormIsEdited(false);
    }

    const handleCancel = () => {
      setEditModal(false);
      setEditSchema({});
      setFormIsEdited(false);
      setTableModal(false);
    }

    const handleSearch = async () => {

      // if search filed is empty get the normal request
      if (!search) return getFCL();

      const res = await filterShipment.request(search , 1, pageNo, docsPerPage);
      if (res.status === 200) {
        const editedResponse = res.data.data.map(response => editResponse(response))
        setData(data => ({
          ...data,
          data: editedResponse
        }))
      } 
    }

    const editResponse = res => {
      // This code will split any date by checking it's key    
      Object.keys(res).forEach(key => {
        if (key.includes('date') || key.includes('Date')) {
          res[key] = res[key] ? res[key].split('T')[0] : "";
        }
      })
      // Replace the normal status with the text from env variable
      res.followUpStatusName = env.status.en[res.followUpStatus - 1];
  
      // Replace the normal country with the replacement text from env variable
      res.countryName = env.country[res.country - 1];
      // Append id filed to use it in the table component
      res.id = res.fclId;
  
      return res;
    }

   const condition = child => child.followUpStatus < 8 ? true : false; 


   useEffect(() => {
      if (search) handleSearch();
      else getFCL();
   }, [pageNo, docsPerPage])
   
    return (
      <div>
      {alert}
      {getFCLPreload.errorAlert}
      {createFCL.errorAlert}
      {updateFCL.errorAlert}
      {approveFCL.errorAlert}
      {filterShipment.errorAlert}
      {uploadImgFCL.errorAlert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      {getFCLPreload.loading ? <Spinner
          gate="#29bb64"
          bg="#fff"
        />
        : <>
         <SimpleHeader parents={[content.fcl[culture]]} />
         <Container className="mt--6" fluid>
            <Card>
              <CardHeader>
                <h3 className={`mb-0 text-md-left`}>{content.fcl[culture]}</h3>
              </CardHeader>
              <CardBody>
               <form onSubmit={handleSubmit(handleCreate)}>
                  <Row>
                    <Col md="6" lg='4'>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="client"
                        >
                          Client
                        </label>
                        <Controller
                          control={control}
                          name="client"
                          rules={{ required: "Client field is required" }}
                          render={({ field: { ref, onChange, value, ...field } }) => (
                            <Input
                              {...field}
                              id="client"
                              placeholder="Client"
                              type="text"
                              className={errors.client && "error"}
                              value={value || ""}
                              onChange={({ target: { value }}) => onChange(value)}
                              onClick={() => setTableModal(true)}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                     <Col md="4" lg='4'>
                        <FormGroup>
                        <label
                              className="form-control-label"
                              htmlFor="dateDeprature"
                           >
                              {content.dateDeprature[culture]}
                           </label>
                           <Controller
                              id="dateDeprature"
                              control={control}
                              name="dateDeprature"
                              rules={{ required: `${content.dateDeprature[culture]} field is required` }}
                              render={({ field: { ref, onChange, value, ...field } }) => (
                                 <Input
                                 {...field}
                                 id="example-date-input"
                                 type="date"
                                 className={errors.dateDeprature && "error"}
                                 value={value || ""}
                                 onChange={({ target: { value }}) => onChange(value)}
                                 />
                              )}
                           />
                        </FormGroup>
                     </Col>
                     <Col md="4" lg='4'>
                        <FormGroup>
                        <label
                              className="form-control-label"
                              htmlFor="dateEstArrival"
                           >
                              {content.dateEstArrival[culture]}
                           </label>
                           <Controller
                              id="dateEstArrival"
                              control={control}
                              name="dateEstArrival"
                              rules={{ required: `${content.dateEstArrival[culture]} field is required` }}
                              render={({ field: { ref, onChange, value, ...field } }) => (
                                 <Input
                                 {...field}
                                 id="example-date-input"
                                 type="date"
                                 className={errors.dateEstArrival && "error"}
                                 value={value || ""}
                                 onChange={({ target: { value }}) => onChange(value)}
                                 />
                              )}
                           />
                        </FormGroup>
                     </Col>
                     <Col md="4" lg='4'>
                        <FormGroup>
                           <label
                              className="form-control-label"
                              htmlFor="sealNum"
                           >
                              {content.sealNum[culture]}
                           </label>
                           <Controller
                              id="sealNum"
                              name="sealNum"
                              control={control}
                              rules={{ required: `${content.sealNum[culture]} field is required` }}
                              render={({ field: { ref, onChange, value, ...field } }) => (
                                 <Input
                                 {...field}
                                 id="example3cols1Input"
                                 placeholder={content.sealNum[culture]}
                                 type="text"
                                 className={errors.sealNum && "error"}
                                 value={value || ""}
                                 onChange={({ target: { value }}) => onChange(value)}
                                 />
                              )}
                           />
                        </FormGroup>
                     </Col>
                     <Col md="4" lg='4'>
                        <FormGroup>
                           <label
                              className="form-control-label"
                              htmlFor="contNum"
                           >
                              {content.contNum[culture]}
                           </label>
                           <Controller
                              control={control}
                              name="contNum"
                              rules={{ required: `${content.contNum[culture]} field is required` }}
                              render={({ field: { ref, onChange, value, ...field } }) => (
                                 <Input
                                 {...field}
                                 id="contNum"
                                 placeholder={content.contNum[culture]}
                                 type="text"
                                 className={errors.contNum && "error"}
                                 value={value || ""}
                                 onChange={({ target: { value }}) => onChange(value)}
                                 />
                              )}
                           />
                        </FormGroup>
                     </Col>
                     <Col md="4" lg='4'>
                        <FormGroup>
                           <label
                              className="form-control-label"
                              htmlFor="country"
                           >
                              {content.country[culture]}
                           </label>
                           <Controller
                              control={control}
                              name="country"
                              rules={{ required: "Country feild is required" }}
                              render={({ field: { ref, onChange, value, ...field } }) => (
                                 <Input
                                 {...field}
                                 id="country"
                                 type="select"
                                 className={errors.country && "error"}
                                 value={value || true}
                                 onChange={({ target: { value }}) => onChange(+value)}
                                 >
                                 <option disabled value> -- select an option -- </option>
                                 {env.country.map((status, i) => <option key={i} value={i + 1}>{status}</option>)}
                                 </Input>
                              )}
                           />
                        </FormGroup>
                     </Col><Col md="4" lg='4'>
                        <FormGroup>
                           <label
                              className="form-control-label"
                              htmlFor="bill"
                           >
                              {content.billLading[culture]}
                           </label>
                           <Controller
                              control={control}
                              name="billLading"
                              rules={{ required: `${content.billLading[culture]} field is required` }}
                              render={({ field: { ref, onChange, value, ...field } }) => (
                                 <Input
                                 {...field}
                                 id="bill"
                                 placeholder={content.billLading[culture]}
                                 type="number"
                                 className={errors.billLading && "error"}
                                 value={value || ""}
                                 onChange={({ target: { value }}) => onChange(+value)}
                                 />
                              )}
                           />
                        </FormGroup>
                     </Col>
                    </Row>
                      <Button 
                        color="success" 
                        type="submit"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-fat-add" />
                        </span>
                        <span>
                          Create
                        </span>
                      </Button>
                     </form>
               </CardBody>
            </Card>
            <Card>
              <div className='padding-around pb-0'>
              <Row>
                <Col md="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="search">
                        Order ID
                      </label>
                      <Input 
                        type="number" 
                        placeholder='Search' 
                        id="search" 
                        onChange={e => setSearch(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                      />
                    </FormGroup>
                </Col>
                <span style={{paddingTop: 7}}>
                  <Button 
                    color="success" 
                    type="button"
                    className='mt-4'
                    onClick={handleSearch}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-search" />
                    </span>
                    <span>
                      Search
                    </span>
                  </Button>
                </span>
              </Row>
            </div>
            <Pagination
            fun={getFCL}
            pageNo={pageNo} 
            setPageNo={setPageNo}
            docsPerPage={docsPerPage}
            setDocsPerPage={setDocsPerPage}
          >
            <Table 
            headers={headers}
            columns={columns}
            data={data.data}
            handleEdit={handleEdit}
            handleApprove={handleApprove}
            condition={condition}
          >
             <span to={`/en-admin/fcl`}>
                <div id="details1" className="table-action cursor-pointer">
                  <i className="far fa-eye fa-lg hover-success"></i>
                </div>
                <UncontrolledTooltip
                  delay={0}
                  target="details1"
                >
                  {commenContent.showDetails[culture]}
                </UncontrolledTooltip>
              </span>
            <span className="me-1" fun="handleEdit">
              <div id="edit1" className="table-action cursor-pointer">
                <i className="fas fa-pencil-alt fa-lg hover-info"></i>
              </div>
              <UncontrolledTooltip
                delay={0}
                target="edit1"
                >
                {commenContent.edit[culture]}
              </UncontrolledTooltip>
            </span>
          </Table>
          </Pagination> 
          <TextPopup
            modal={tableModal}
            text="Next"
            handleCancel={handleCancel}
            fn={() => {}}
            color='success'
            noBtn
          >
            <Container>
              <PopupTable 
                setCustomer={setCustomer}
                setTableModal={setTableModal}
                setValue={setValue}
                sweetAlert={sweetAlert}
              />
            </Container>
          </TextPopup> 
          <TextPopup
            modal={editModal}
            text="Update"
            handleCancel={handleCancel}
            fn={editFCL}
            color='success'
            disabled={!formIsEdited && !editSchema?.imgs}
          >
            <Container>
                <EditFCL
                  editSchema={editSchema}
                  setEditSchema={setEditSchema}
                  culture={culture}
                  setFormIsEdited={setFormIsEdited}
                />
            </Container>
          </TextPopup>
             </Card>
            </Container>
        </>
      }     
   </div>
 )}

export default Fcl;
