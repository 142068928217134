import React, { useEffect, useState, useRef } from 'react'
import {
  Card,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  CardHeader,
  CardBody
} from "reactstrap";
import SimpleHeader from 'components/Headers/SimpleHeader';
import Table from "components/common/CustomTable";
import useApi from '../../hooks/useApi';
import shipmentApi from "api/shipment";
import Spinner from '../../components/Spinner';
import env from 'env';
import { useForm, Controller } from "react-hook-form";
import TextPopup from 'components/common/TextModal';
import EditAirshipment from "./components/EditAirshipment";
import Pagination from "components/common/Pagination";
import PopupTable from 'components/common/PopupTableUsed';
import useAlert from 'hooks/useAlert';
import { useParams } from 'react-router';
import SearchModal from 'components/common/SearchModal';
import useSearchPopup from 'hooks/useSearchPopup';
import FilePicker from 'components/common/FilePicker';

function Airshipment() {

  const headers = [
    "actions",
    "Order id", 
    "customer code", 
    "customer name", 
    "Weight", 
    "NO of packages", 
    "follow up status", 
    "country", 
    "date",
    "commodity",
  ]

  const columns = [
    "orderId",
    "cus_Code",
    "cus_Name",
    "weight",
    "noOfPkgs",
    "followUpStatusText",
    "countryText",
    "date",
    "commodity",
  ]

  // useParams
  const params = useParams();

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();
  
  // Apis
  const getShipmentPreload = useApi(shipmentApi.airCargoPreload);
  const createShipment = useApi(shipmentApi.createAirCargo);
  const updateCargo = useApi(shipmentApi.updateCargo);
  const approveShipment = useApi(shipmentApi.approveShipment);
  const uploadImg = useApi(shipmentApi.uploadImg);
  const getOrderByCode = useApi(shipmentApi.getOrderByCode);
  const getByColumn = useApi(shipmentApi.getByColumn);

  // States
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editSchema, setEditSchema] = useState({});
  const [pageNo, setPageNo] = useState(+params.pageNo);
  const [docsPerPage, setDocsPerPage] = useState(+params.docsPerPage);
  const [search, setSearch] = useState("");
  const [customer, setCustomer] = useState({});
  const [tableModal, setTableModal] = useState(false);
  const [formIsEdited, setFormIsEdited] = useState(false);
  const [images, setImages] = useState({});

  const editResponse = res => {

    // This code will split any date by checking it's key    
    Object.keys(res).forEach(key => {
      if (key.includes('date') || key.includes('Date')) {
        res[key] = res[key] ? res[key].split('T')[0] : "";
      }
    })
    // Replace the normal status with the text from env variable
    const thisStatusIndex = env.followUpStatus.findIndex(status => status.key === res.followUpStatus);

    res.followUpStatusText = env.followUpStatus[thisStatusIndex].text;

    // Replace the normal country with the replacement text from env variable
    res.countryText = env.country[res.country - 1];
    // Append id filed to use it in the table component
    res.id = res.airCargoId;

    return res;
  }
  
  // Use form hook
  const { handleSubmit, formState: { errors }, control, setValue, reset } = useForm();
  const searchPopup = useSearchPopup(getByColumn, headers, columns, data.data, editResponse);

  const getShipment = async () => {
    const res = await getShipmentPreload.request(pageNo, docsPerPage);
    if (res.ok) {
      res?.data.data[0].data.forEach(post => {
        
        // This code will split any date by checking it's key    
        Object.keys(post).forEach(key => {
          if (key.includes('date') || key.includes('Date')) {
            post[key] = post[key] ? post[key].split('T')[0] : "";
          }
        })

        // Get this shipment status index
        const thisStatusIndex = env.followUpStatus.findIndex(status => status.key === post.followUpStatus);
        // Replace the normal status with the text from env variable
        post.followUpStatusText = env.followUpStatus[thisStatusIndex].text;
        // Replace the normal country with the replacement text from env variable
        post.countryText = env.country[post.country - 1];
        // Append id filed to use it in the table component
        post.id = post.airCargoId;
      })
      
      setData(res.data.data[0]);
    }
    
  }

  const handleCreate = async formData => {
    setSpinner(true);
    
    const schema = new FormData();
    const imgs = images;

    // schema.append('orderId', '');
    schema.append('client', +customer.cusId);
    delete formData.client;

    Object.keys(formData).forEach(key => {
      schema.append(key, formData[key]);
    })

    for(let i = 0; i < imgs.length; i++) {
      schema.append('Image', imgs[i]);
    }

  
    const res = await createShipment.request(schema);
    if (res.status === 200) {
      // const newData = { ...data };
      // console.log({res})
      // Object.keys(res.data.data).forEach(key => {
      //   if (key.includes('date') || key.includes('Date')) {
      //     res.data.data[key] = res.data.data[key] ? res.data.data[key].split('T')[0] : "";
      //   }
      // })

      // res.data.data = editResponse(res.data.data)
      // newData.data.unshift(res.data.data)
      // setData(newData);
      sweetAlert("Done")
      reset();
      getShipment();
      setImages({});
    }

    setSpinner(false);
  }

  const handleEdit = obj => {
    setEditSchema({...obj, imgs: {}});
    setEditModal(true);
    setFormIsEdited(false);
  }

  const handleCancel = () => {
    setEditModal(false);
    setEditSchema({imgs: {}});
    setTableModal(false);
    setFormIsEdited(false);
    setImages({});
  }

  
  const handleUpload = async () => {
    const res = await uploadImg.request(editSchema.airCargoId, editSchema.imgs); 
    return res?.ok || false;   
  }

  const editAirCargo = async () => {
    setSpinner(true);
    setEditModal(false);

    // Assing 'uploadSucceed' varibale with treu default value
    let uploadSucceed = true;

    // if the 'editSchema' has 'imgs' attribute and the 'imgs' attribute has a value 
    // 1) upload the imgs
    // 2) override the 'uploadSucceed' varibale
    if (editSchema?.imgs) 
      uploadSucceed = await handleUpload();

    // if the upload fails don't continue to the next request
    if (!uploadSucceed) return setSpinner(false);
    // if no other filed edited don't continue to the next request      
    if (!formIsEdited) {
      // and show success for the upload field
      sweetAlert("Done");
      return setSpinner(false);
    }
    const schema = {
      orderId: "",
      weight: +editSchema.weight,
      noOfPkgs: editSchema.noOfPkgs,
      country: editSchema.country,
      commodity: editSchema.commodity,
      commType: +editSchema.commType
    }

    const res = await updateCargo.request( editSchema.airCargoId, schema);

    if (res.status === 200) {
      const newData = {...data};
      const editedIndex = newData.data.findIndex(item => item.airCargoId === editSchema.airCargoId);

      res.data.data = editResponse(res.data.data);
      newData.data[editedIndex] = res.data.data;
      
      setData(newData);
      sweetAlert("Done");
      setFormIsEdited(false);
      setEditSchema({});
    }
    
    setSpinner(false);
  }

  const handleApprove = async obj => {
    setSpinner(true);

    const res = await approveShipment.request(obj.id);
    if (res.status === 200) {
      
      const editedResponse = editResponse(res.data.data);

      const newData = {...data};
      const index = newData.data.findIndex(item => item.id === obj.id);
      newData.data[index] = editedResponse;

    }
    setSpinner(false);
  }

  const handleSearch = async () => {

    // if search filed is empty get the normal request
    if (!search) return getShipment();

    const res = await getOrderByCode.request(search, pageNo, docsPerPage);

    if (res.status === 200) {

      const editedResponse = res.data.data[0].data.map(response => editResponse(response))
      setData(data => ({
        ...data,
        data: editedResponse
      }))
    }
  }

  const condition = child => child.followUpStatus < 8 ? true : false; 
  
  const updateData = obj => {

    const editedResponse = obj.map(response => editResponse(response))

    setData(data => ({
      ...data,
      data: editedResponse
    }))
  }

  useEffect(() => {
    if (search) handleSearch();
    else getShipment();
  }, [pageNo, docsPerPage])

  useEffect(() => {

    if (searchPopup.isSelected && searchPopup.searchField) {
      searchPopup.onSearch('aircargo', () => {});
    } else {
      searchPopup.setIsSelected(false);
    }
  }, [searchPopup.pageNo, searchPopup.docsPerPage])

  return (
    <div>
      {alert}
      {getShipmentPreload.errorAlert}
      {createShipment.errorAlert}
      {updateCargo.errorAlert}
      {approveShipment.errorAlert}
      {uploadImg.errorAlert}
      {getOrderByCode.errorAlert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      {getShipmentPreload.loading ? <Spinner
          gate="#29bb64"
          bg="#fff"
        />
        : <>
      <SimpleHeader parents={["Airshipment"]} />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>Airshipment</h3>
            </div>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit(handleCreate)}>
              <Row>
                <Col md="6" lg='4'>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      Client
                    </label>
                    <Controller
                      control={control}
                      name="client"
                      rules={{ required: "Client field is required" }}
                      render={({ field: { ref, onChange, value, ...field } }) => (
                        <Input
                          {...field}
                          id="example3cols1Input"
                          placeholder="Client"
                          type="text"
                          className={errors.client && "error"}
                          value={value || ""}
                          onChange={({ target: { value }}) => onChange(value)}
                          onClick={() => setTableModal(true)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg='4'>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="weight"
                    >
                      Weight
                    </label>
                    <Controller
                      control={control}
                      name="weight"
                      rules={{ required: "Weight feild is required" }}
                      render={({ field: { ref, onChange, value, ...field } }) => (
                        <Input
                          {...field}
                          id="weight"
                          placeholder="Weight"
                          type="number"
                          className={errors.weight && "error"}
                          value={value || ""}
                          onChange={({ target: { value }}) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg='4'>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      Number of packages
                    </label>
                    <Controller
                      control={control}
                      name="noOfPkgs"
                      rules={{ required: "Number of packages feild is required" }}
                      render={({ field: { ref, onChange, value, ...field } }) => (
                        <Input
                          {...field}
                          id="example3cols1Input"
                          placeholder="Number of packages"
                          type="text"
                          className={errors.noOfPkgs && "error"}
                          value={value || ""}
                          onChange={({ target: { value }}) => onChange(+value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg='4'>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      Country
                    </label>
                    <Controller
                      control={control}
                      name="country"
                      rules={{ required: "Country feild is required" }}
                      render={({ field: { ref, onChange, value, ...field } }) => (
                        <Input
                          {...field}
                          id="example3cols1Input"
                          placeholder="Weight"
                          type="select"
                          className={errors.country && "error"}
                          value={value || true}
                          onChange={({ target: { value }}) => onChange(+value)}
                        >
                          <option disabled value> -- select an option -- </option>
                          {env.country.map((status, i) => <option key={i} value={i + 1}>{status}</option>)}
                        </Input>
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="commidityType"
                    >
                      Commodity type
                    </label>
                    <Controller
                      control={control}
                      name="commType"
                      render={({ field: { ref, onChange, value, ...field } }) => (
                        <Input
                          {...field}
                          id="commidityType"
                          type="select"
                          defaultValue="disabled"
                          onChange={({ target: { value }}) => onChange(+value)}
                        >
                          <option disabled value="disabled"> -- select an option -- </option>
                          {env.commidityType.map((status, i) => <option key={i} value={i + 1}>{status}</option>)}
                        </Input>
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="commodity"
                      >
                        Commodity
                      </label>
                      <Controller
                        control={control}
                        name="commodity"
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <Input
                            {...field}
                            id="commodity"
                            type="textarea"
                            style={{resize: 'none'}}
                            onChange={({ target: { value }}) => onChange(value)}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FilePicker images={images} setImages={setImages} />
              <Button 
                color="success" 
                type="submit"
              >
                <span className="btn-inner--icon mr-1">
                  <i className="ni ni-fat-add" />
                </span>
                <span>
                  Create
                </span>
              </Button>
            </form>
          </CardBody>
        </Card>
        <Card>
          <div className='padding-around pb-0'>
            <Row>
              <Col md="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="search">
                    Customer code
                  </label>
                  <Input 
                    type="number" 
                    placeholder='Search' 
                    id="search" 
                    onChange={e => setSearch(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                  />
                </FormGroup>
              </Col>
              <span style={{paddingTop: 7}}>
                <Button 
                  color="success" 
                  type="button"
                  className='mt-4'
                  onClick={handleSearch}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-search" />
                  </span>
                  <span>
                    Search
                  </span>
                </Button>
              </span>
            </Row>
          </div>
          <Pagination
            dataLength={data.data?.length}
            // when the user search using the popup and select a row
            // after then only use the pagination states from searchPopup hook
            pageNo={searchPopup.isSelected ? searchPopup.pageNo : pageNo} 
            setPageNo={searchPopup.isSelected ? searchPopup.setPageNo : setPageNo}
            docsPerPage={searchPopup.isSelected ? searchPopup.docsPerPage : docsPerPage}
            setDocsPerPage={searchPopup.isSelected ? searchPopup.setDocsPerPage : setDocsPerPage}
          >
            <Table 
              headers={headers}
              columns={columns}
              // when the user search using the popup and select a row
              // after then only use the hookData states from searchPopup hook
              data={searchPopup.isSelected ? searchPopup.hookData : data.data}
              handleEdit={handleEdit}
              handleApprove={handleApprove}
              condition={condition}
              onHeaderClick={searchPopup.handleHeaderClicked}
            >
              <span to={`/en-admin/airshipment/${pageNo}/${docsPerPage}`}>
                <div id="details1" className="table-action cursor-pointer">
                  <i className="far fa-eye fa-lg hover-success"></i>
                </div>
                <UncontrolledTooltip
                  delay={0}
                  target="details1"
                >
                  show details
                </UncontrolledTooltip>
              </span>
              <span fun="handleEdit">
                <div id="edit1" className="table-action cursor-pointer">
                  <i className="fas fa-pencil-alt fa-lg hover-info"></i>
                </div>
                <UncontrolledTooltip
                  delay={0}
                  target="edit1"
                  >
                  Edit
                </UncontrolledTooltip>
              </span>
            </Table>
          </Pagination>   
          <TextPopup
            modal={editModal}
            text="Update"
            handleCancel={handleCancel}
            fn={editAirCargo}
            color='success'
            disabled={!formIsEdited && !editSchema?.imgs}
          >
            <Container>
              <EditAirshipment
                editSchema={editSchema}
                setEditSchema={setEditSchema}
                setFormIsEdited={setFormIsEdited}
              />
            </Container>
          </TextPopup>
          <TextPopup
            modal={tableModal}
            text="Next"
            handleCancel={handleCancel}
            fn={() => {}}
            color='primary'
            noBtn
          >
            <Container>
              <PopupTable 
                setCustomer={setCustomer}
                setTableModal={setTableModal}
                setValue={setValue}
                sweetAlert={sweetAlert}
              />
            </Container>
          </TextPopup>
          {searchPopup.openPopup && 
            <SearchModal
              data={data.data}
              setData={setData}
              updateData={updateData}
              searchPopup={searchPopup}
              editResponse={editResponse}
              page="Recived"
            />
          }
        </Card>
      </Container>
      </>}
    </div>
  )
}

export default Airshipment