import React , { useState, useEffect } from 'react';

// router 
import {
   useRouteMatch,
   useParams
} from "react-router-dom";

// get culture from utils 
import getCulture from "utils/getCulture";

// reactstrap components
import {
   Card,
   CardHeader,
   CardBody,
   FormGroup,
   Input,
   Button,
   Container,
   Row,
   Col,
   UncontrolledTooltip
} from "reactstrap";
//content 
import content from './components/content';

//common
import commenContent from "components/common/content";

// core components
import SimpleHeader from 'components/Headers/SimpleHeader';

// BK
import Table from "components/common/CustomTable";
import useApi from '../../../hooks/useApi';
import lclApi from "api/LCLBK";
import Spinner from '../../../components/Spinner';
import env from 'env';
import Pagination from "components/common/Pagination";
import TextPopup from 'components/common/TextModal';
import EditLCL from "./components/EditLCL";
import { useForm, Controller } from "react-hook-form";
import PopupTable from 'components/common/PopupTableUsed';
import useAlert from 'hooks/useAlert';
import SearchModal from 'components/common/SearchModal';
import useSearchPopup from 'hooks/useSearchPopup';
import FilePicker from 'components/common/FilePicker';

function LCL() {

   let rout = useRouteMatch();
   let culture = getCulture(rout.url);
   
   const headers = [
      "actions",
      content.orderId[culture],
      "Customer code",
      "Customer name",
      content.volume[culture],
      content.noOfPkgs[culture],
      content.followUpStatus[culture],
      content.date[culture],
      content.country[culture],
      "commodity",
   ]
  
   const columns = [
      "orderId",
      "cus_Code",
      "cus_Name",
      "volume",
      "noOfPkgs",
      "followUpStatusText",
      "date",
      "countryText",
      "commodity"
   ]

   // Refs
   const params = useParams();

   // useAlert custom hook
   const { alert, sweetAlert } = useAlert();

   // Apis
   const getLCLPreload = useApi(lclApi.LCLPreload);
   const createLCL = useApi(lclApi.createLCL);
   const updateLCL = useApi(lclApi.updateLCL);
   const approveLCL = useApi(lclApi.approveLCL);
   const uploadImgLCL = useApi(lclApi.uploadImgLCL);
   const getOrderByCode = useApi(lclApi.getOrderByCode);
   const getByColumn = useApi(lclApi.getByColumn);
 
   // States
   const [data, setData] = useState([]);
   const [spinner, setSpinner] = useState(false);
   const [editModal, setEditModal] = useState(false);
   const [editSchema, setEditSchema] = useState({});
   const [pageNo, setPageNo] = useState(+params.pgNo);
   const [docsPerPage, setDocsPerPage] = useState(+params.docsPerPg);
   const [search, setSearch] = useState("");
   const [formIsEdited, setFormIsEdited] = useState(false);
   const [tableModal, setTableModal] = useState(false);
   const [customer, setCustomer] = useState({});
   const [images, setImages] = useState({});

   const editResponse = res => {
      // This code will split any date by checking it's key    
      Object.keys(res).forEach(key => {
        if (key.includes('date') || key.includes('Date')) {
          res[key] = res[key] ? res[key].split('T')[0] : "";
        }
      })

      // Replace the normal status with the text from env variable
      const thisStatusIndex = env.followUpStatus.findIndex(status => status.key === res.followUpStatus);

      res.followUpStatusText = env.followUpStatus[thisStatusIndex].text;
  
      // Replace the normal country with the replacement text from env variable
      res.countryText = env.country[res.country - 1];
      // Append id filed to use it in the table component
      res.id = res.lclId;
  
      return res;
    }

   // Use form hook
  const { handleSubmit, formState: { errors }, control, setValue, reset } = useForm();
  const searchPopup = useSearchPopup(getByColumn, headers, columns, data.data, editResponse);

  const getLCL = async () => {
   
   const res = await getLCLPreload.request(pageNo, docsPerPage);
   if (res.status === 200) {
   res?.data.data[0].data.forEach(post => {
      // This code will split any date by checking it's key    
      Object.keys(post).forEach(key => {
        if (key.includes('date') || key.includes('Date')) {
          post[key] = post[key] ? post[key].split('T')[0] : "";
        }
      })
      // Get this shipment status index
      const thisStatusIndex = env.followUpStatus.findIndex(status => status.key === post.followUpStatus);
      // Replace the normal status with the text from env variable
      post.followUpStatusText = env.followUpStatus[thisStatusIndex]?.text || "";
      // Replace the normal country with the replacement text from env variable
      post.countryText = env.country[post.country - 1];
   })
   
   setData(res.data.data[0]);
}

   
   }

   const handleCreate = async formData => {
      setSpinner(true);
      const schema = new FormData();
      // const imgs = imgRef.current.files;
  
      // schema.append('orderId', '');
      schema.append('client', +customer.cusId);
      delete formData.client;
  
      Object.keys(formData).forEach(key => {
        schema.append(key, formData[key]);
      })
  
      for(let i = 0; i < images.length; i++) {
        schema.append('Image', images[i]);
      }
  
      const res = await createLCL.request(schema);
      if (res.status === 200) {
         setImages({});
      //   const newData = { ...data };
      //   Object.keys(res.data.data).forEach(key => {
      //     if (key.includes('date') || key.includes('Date')) {
      //       res.data.data[key] = res.data.data[key] ? res.data.data[key].split('T')[0] : "";
      //     }
      //    })
      //     // Replace the normal status with the text from env variable
      // res.data.data.followUpStatusText = env.status.en[res.data.data.followUpStatus - 1];
      // // Replace the normal country with the replacement text from env variable
      // res.data.data.countryText = env.country[res.data.data.country - 1];
        
      //   newData.data.unshift(res.data.data)
      //   setData(newData);
        getLCL();
        reset();
        sweetAlert(commenContent.done[culture])

      }
      setSpinner(false);
    }

    const handleUpload = async e => {
      const res = await uploadImgLCL.request(editSchema.lclId, editSchema.imgs);
      return res?.ok || false;
    }

    const editLCL = async () => {
      setEditModal(false);
      setSpinner(true);
      const schema = {
         volume: editSchema.volume,
         noOfPkgs: editSchema.noOfPkgs,
         country: editSchema.country,
         commodity: editSchema.commodity,
         commidityType: editSchema.commidityType
      }
  
      // Assing 'uploadSucceed' varibale with treu default value
      let uploadSucceed = true;

      // if the 'editSchema' has 'imgs' attribute and the 'imgs' attribute has a value 
      // 1) upload the imgs
      // 2) override the 'uploadSucceed' varibale
      if (editSchema?.imgs) 
         uploadSucceed = await handleUpload();

      // if the upload fails don't continue to the next request
      if (!uploadSucceed) return setSpinner(false);
      // if no other filed edited don't continue to the next request
      if (!formIsEdited) {
         sweetAlert("Done");
         return setSpinner(false);
      }
      
      const res = await updateLCL.request( editSchema.lclId, schema);
  
      if (res.status === 200) {
        const newData = {...data};
        const editedIndex = newData.data.findIndex(item => item.lclId === editSchema.lclId);
        Object.keys(res.data.data).forEach(key => {
          if (key.includes('date') || key.includes('Date')) {
            res.data.data[key] = res.data.data[key] ? res.data.data[key].split('T')[0] : "";
          }
        })
        // Replace the normal status with the text from env variable
        res.data.data.followUpStatusText = env.status.en[res.data.data.followUpStatus - 1];
        // Replace the normal country with the replacement text from env variable
        res.data.data.countryText = env.country[res.data.data.country - 1];
  
        // Append id filed to use it in the table component
        res.data.data.id = res.data.data.lclId;
  
        newData.data[editedIndex] = res.data.data;
        
        setData(newData);
        setEditSchema({});
        setEditModal(false);
        setFormIsEdited(false);
        sweetAlert(commenContent.done[culture])
      }
      
      setSpinner(false);
    }

    const handleApprove = async (obj) => {
      setSpinner(true);
      const res = await approveLCL.request( obj.lclId );

      if (res.status === 200) {
         const newData = {...data};
         const editedIndex = newData.data.findIndex(item => item.lclId === obj.lclId);
         Object.keys(res.data.data).forEach(key => {
           if (key.includes('date') || key.includes('Date')) {
             res.data.data[key] = res.data.data[key] ? res.data.data[key].split('T')[0] : "";
           }
         })
         // Replace the normal status with the text from env variable
         res.data.data.followUpStatusText = env.status.en[res.data.data.followUpStatus - 1];
         // Replace the normal country with the replacement text from env variable
         res.data.data.countryText = env.country[res.data.data.country - 1];
   
         // Append id filed to use it in the table component
         res.data.data.id = res.data.data.lclId;
   
         newData.data[editedIndex] = res.data.data;
         
         setData(newData);
         sweetAlert(commenContent.done[culture])
       }

      setSpinner(false);
      
   }

   const updateData = obj => {
      const editedResponse = obj.map(response => editResponse(response))
      setData(data => ({
        ...data,
        data: editedResponse
      }))
    }

    const handleEdit = obj => {
      setEditSchema({...obj, imgs: {}});
      setEditModal(true);
      setFormIsEdited(false);
    }

    const handleCancel = () => {
      setEditModal(false);
      setEditSchema({ imgs: {} });
      setFormIsEdited(false);
      setTableModal(false);
    }

    const handleSearch = async () => {

      // if search filed is empty get the normal request
      if (!search) return getLCL();

      const res = await getOrderByCode.request(search, pageNo, docsPerPage);
      if (res.status === 200) {
        const editedResponse = res.data.data[0].data.map(response => editResponse(response))
        setData(data => ({
          ...data,
          data: editedResponse
        }))
      }
    }

    const condition = child => child.followUpStatus < 8 ? true : false; 

    useEffect(() => {
      if (search) handleSearch();
      else getLCL();
    }, [pageNo, docsPerPage])

    useEffect(() => {

      if (searchPopup.isSelected && searchPopup.searchField) {
        searchPopup.onSearch('lcl', () => {});
      } else {
        searchPopup.setIsSelected(false);
      }
    }, [searchPopup.pageNo, searchPopup.docsPerPage])

    return (
       <div>
      {alert}
      {getLCLPreload.errorAlert}
      {createLCL.errorAlert}
      {updateLCL.errorAlert}
      {approveLCL.errorAlert}
      {getOrderByCode.errorAlert}
      {uploadImgLCL.errorAlert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      {getLCLPreload.loading ? <Spinner
          gate="#29bb64"
          bg="#fff"
        />
        : <>
         <SimpleHeader parents={[content.lcl[culture]]} />
         <Container className="mt--6" fluid>
            <Card>
               <CardHeader>
                  <h3 className={`mb-0 text-md-left`}>{content.lcl[culture]}</h3>
               </CardHeader>
               <CardBody>
               <form onSubmit={handleSubmit(handleCreate)}>
                  <Row>
                     <Col md="4" lg='4'>
                        <FormGroup>
                           <label
                              className="form-control-label"
                              htmlFor="client"
                           >
                              {content.client[culture]}
                           </label>
                           <Controller
                              control={control}
                              name="client"
                              rules={{ required: `${content.client[culture]} field is required` }}
                              render={({ field: { ref, onChange, value, ...field } }) => (
                                 <Input
                                 {...field}
                                 id="client"
                                 placeholder={content.client[culture]}
                                 type="text"
                                 className={errors.client && "error"}
                                 value={value || ""}
                                 onChange={({ target: { value }}) => onChange(value)}
                                 onClick={() => setTableModal(true)}
                                 />
                              )}
                           />
                        </FormGroup>
                     </Col>
                     <Col md="4" lg='4'>
                        <FormGroup>
                           <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                           >
                              {content.country[culture]}
                           </label>
                           <Controller
                              control={control}
                              name="country"
                              rules={{ required: "Country feild is required" }}
                              render={({ field: { ref, onChange, value, ...field } }) => (
                                 <Input
                                 {...field}
                                 id="example3cols1Input"
                                 type="select"
                                 className={errors.country && "error"}
                                 value={value || true}
                                 onChange={({ target: { value }}) => onChange(+value)}
                                 >
                                 <option disabled value> -- select an option -- </option>
                                 {env.country.map((status, i) => <option key={i} value={i + 1}>{status}</option>)}
                                 </Input>
                              )}
                           />
                        </FormGroup>
                     </Col>
                     <Col md="4" lg='4'>
                        <FormGroup>
                           <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                           >
                              {content.volume[culture]}
                           </label>
                           <Controller
                              control={control}
                              name="volume"
                              rules={{ required: `${content.volume[culture]} field is required` }}
                              render={({ field: { ref, onChange, value, ...field } }) => (
                                 <Input
                                 {...field}
                                 id="example3cols1Input"
                                 placeholder={content.volume[culture]}
                                 type="number"
                                 className={errors.volume && "error"}
                                 value={value || ""}
                                 onChange={({ target: { value }}) => onChange(value)}
                                 />
                              )}
                           />
                        </FormGroup>
                     </Col>
                     <Col md="4" lg='4'>
                        <FormGroup>
                           <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                           >
                              {content.noOfPkgs[culture]}
                           </label>
                           <Controller
                              control={control}
                              name="noOfPkgs"
                              rules={{ required: `${content.noOfPkgs[culture]} field is required` }}
                              render={({ field: { ref, onChange, value, ...field } }) => (
                                 <Input
                                 {...field}
                                 id="example3cols1Input"
                                 placeholder={content.noOfPkgs[culture]}
                                 type="number"
                                 className={errors.noOfPkgs && "error"}
                                 value={value || ""}
                                 onChange={({ target: { value }}) => onChange(value)}
                                 />
                              )}
                           />
                        </FormGroup>
                     </Col>
                     <Col md="4">
                        <FormGroup>
                        <label
                           className="form-control-label"
                           htmlFor="commidityType"
                        >
                           Commodity type
                        </label>
                        <Controller
                           control={control}
                           name="commType"
                           render={({ field: { ref, onChange, value, ...field } }) => (
                              <Input
                              {...field}
                              id="commidityType"
                              type="select"
                              defaultValue="disabled"
                              onChange={({ target: { value }}) => onChange(+value)}
                              >
                              <option disabled value="disabled"> -- select an option -- </option>
                              {env.commidityType.map((status, i) => <option key={i} value={i + 1}>{status}</option>)}
                              </Input>
                           )}
                        />
                        </FormGroup>
                     </Col>
                  </Row>
                  <Row>
                     <Col xs="12">
                        <FormGroup>
                           <label
                              className="form-control-label"
                              htmlFor="commodity"
                           >
                              Commodity
                           </label>
                           <Controller
                              control={control}
                              name="commodity"
                              render={({ field: { ref, onChange, value, ...field } }) => (
                              <Input
                                 {...field}
                                 id="commodity"
                                 type="textarea"
                                 style={{resize: 'none'}}
                                 onChange={({ target: { value }}) => onChange(value)}
                              />
                              )}
                           />
                        </FormGroup>
                        </Col>
                     </Row>
                     <div>
                        <FilePicker images={images} setImages={setImages} />
                     </div>
                     <Button 
                        color="success" 
                        type="submit"
                     >
                        <span className="btn-inner--icon mr-1">
                           <i className="ni ni-fat-add" />
                        </span>
                        <span>
                           Create
                        </span>
                     </Button>
                  </form>
               </CardBody>
            </Card>
            <Card>
               <div className='padding-around pb-0'>
               <Row>
                  <Col md="4">
                     <FormGroup>
                        <label className="form-control-label" htmlFor="search">
                           Customer code
                        </label>
                        <Input 
                        type="number" 
                        placeholder='Search' 
                        id="search" 
                        onChange={e => setSearch(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                        />
                     </FormGroup>
                  </Col>
                  <span style={{paddingTop: 7}}>
                     <Button 
                        color="success" 
                        type="button"
                        className='mt-4'
                        onClick={handleSearch}
                     >
                        <span className="btn-inner--icon mr-1">
                        <i className="fas fa-search" />
                        </span>
                        <span>
                        Search
                        </span>
                     </Button>
                  </span>
               </Row>
            </div>
            <Pagination
               fun={getLCL}
               dataLength={data.data?.length}
               // when the user search using the popup and select a row
               // after then only use the pagination states from searchPopup hook
               pageNo={searchPopup.isSelected ? searchPopup.pageNo : pageNo} 
               setPageNo={searchPopup.isSelected ? searchPopup.setPageNo : setPageNo}
               docsPerPage={searchPopup.isSelected ? searchPopup.docsPerPage : docsPerPage}
               setDocsPerPage={searchPopup.isSelected ? searchPopup.setDocsPerPage : setDocsPerPage}
            >
               <Table 
               headers={headers}
               columns={columns}
               // when the user search using the popup and select a row
               // after then only use the hookData states from searchPopup hook
               data={searchPopup.isSelected ? searchPopup.hookData : data.data}
               handleEdit={handleEdit}
               handleApprove={handleApprove}
               condition={condition}
               onHeaderClick={searchPopup.handleHeaderClicked}
            >
               <span to={`/en-admin/lcl/${pageNo}/${docsPerPage}`}>
                  <div id="details1" className="table-action cursor-pointer">
                     <i className="far fa-eye fa-lg hover-success"></i>
                  </div>
                  <UncontrolledTooltip
                     delay={0}
                     target="details1"
                  >
                     {commenContent.showDetails[culture]}
                  </UncontrolledTooltip>
               </span>
               <span className="me-1" fun="handleEdit">
               <div id="edit1" className="table-action cursor-pointer">
                  <i className="fas fa-pencil-alt fa-lg hover-info"></i>
               </div>
               <UncontrolledTooltip
                  delay={0}
                  target="edit1"
                  >
                  {commenContent.edit[culture]}
               </UncontrolledTooltip>
               </span>
            </Table>
          </Pagination> 
          <TextPopup
            modal={editModal}
            text="Update"
            handleCancel={handleCancel}
            fn={editLCL}
            color='success'
          >
            <Container>
                <EditLCL
                  editSchema={editSchema}
                  setEditSchema={setEditSchema}
                  culture={culture}
                  setFormIsEdited={setFormIsEdited}
                />
            </Container>
          </TextPopup>
          <TextPopup
            modal={tableModal}
            text="Next"
            handleCancel={handleCancel}
            fn={() => {}}
            color='primary'
            noBtn
          >
            <Container>
              <PopupTable 
                setCustomer={setCustomer}
                setTableModal={setTableModal}
                setValue={setValue}
                sweetAlert={sweetAlert}
              />
            </Container>
          </TextPopup>
          {searchPopup.openPopup && 
            <SearchModal
              data={data.data}
              setData={setData}
              updateData={updateData}
              searchPopup={searchPopup}
              editResponse={editResponse}
              page='Recived'
            />
          }
      </Card>
   </Container>
   </>
   }     
   </div>
 )}

export default LCL;
