export default {

    news: {
        en: "News Settings"
    },
    title: {
        en: "Title"
    },
    description: {
        en: "Description"
    },
    date: {
        en: "Date"
    },
    image: {
        en: "Image"
    }, 
    select: {
        en: "Select file"
    },

}