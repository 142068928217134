import React, { useState, useEffect } from 'react';
import { 
  Card,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  CardHeader,
  CardBody
} from 'reactstrap';
import SimpleHeader from 'components/Headers/SimpleHeader';
import Table from "components/common/CustomTable";
import Pagination from "components/common/Pagination";
import useSearchPopup from 'hooks/useSearchPopup';
import env from 'env';
import followUpStatus from 'api/followUpStatus';
import useApi from 'hooks/useApi';
import useAlert from 'hooks/useAlert';
import { useLocation, useParams } from 'react-router';
import SearchModal from 'components/common/SearchModal';
import Spinner from '../../components/Spinner';

const FollowUpStatus = () => {

  const headers = [
    "actions",
    "Order id", 
    "customer code", 
    "customer name", 
    "Weight", 
    "NO of packages", 
    "follow up status", 
    "country", 
    "date",
    "commodity",
  ]

  const columns = [
    "orderId",
    "cus_Code",
    "cus_Name",
    "weight",
    "noOfPkgs",
    "followUpStatusText",
    "countryText",
    "date",
    "commodity",
  ]

  // useParams
  const params = useParams();
  const location = useLocation();

  // useAlert custom hook
  const { alert } = useAlert();

  // Apis
  const getFUS = useApi(followUpStatus.getFUS);
  const getByColumn = useApi(followUpStatus.getByColumn);

  // sttates
  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(+params.pgNo);
  const [docsPerPage, setDocsPerPage] = useState(+params.docsPerPg);

  const editResponse = res => {
    // This code will split any date by checking it's key    
    Object.keys(res).forEach(key => {
      if (key.includes('date') || key.includes('Date')) {
        res[key] = res[key] ? res[key].split('T')[0] : "";
      }
    })
    // Replace the normal status with the text from env variable
    const thisStatusIndex = env.followUpStatus.findIndex(status => status.key === res.followUpStatus);

    res.followUpStatusText = env.followUpStatus[thisStatusIndex].text;

    // Replace the normal country with the replacement text from env variable
    res.countryText = env.country[res.country - 1];
    // Append id filed to use it in the table component
    res.id = res.airCargoId;

    return res;
  }

  const searchPopup = useSearchPopup(getByColumn, headers, columns, data, editResponse);

  const getData = async () => {
    const res = await getFUS.request(params.shipmentType, pageNo, docsPerPage);
    if (res.status === 200) {
      res.data.data = res.data.data.map(item => editResponse(item));
      setData(res.data.data);
    }
  }

  const updateData = obj => {

    const editedResponse = obj.map(response => editResponse(response))

    setData(data => ({
      ...data,
      data: editedResponse
    }))
  }

  useEffect(() => {
    getData();
  }, [pageNo, docsPerPage, location])

  return (
    <div>
      {alert}
      {getFUS.errorAlert}
      {getFUS.loading ? <Spinner
          gate="#29bb64"
          bg="#fff"
        />
      : <>
      <SimpleHeader parents={["Airshipment"]} />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>Airshipment</h3>
            </div>
          </CardHeader>
          <Pagination
            dataLength={data.length}
            // when the user search using the popup and select a row
            // after then only use the pagination states from searchPopup hook
            pageNo={searchPopup.isSelected ? searchPopup.pageNo : pageNo} 
            setPageNo={searchPopup.isSelected ? searchPopup.setPageNo : setPageNo}
            docsPerPage={searchPopup.isSelected ? searchPopup.docsPerPage : docsPerPage}
            setDocsPerPage={searchPopup.isSelected ? searchPopup.setDocsPerPage : setDocsPerPage}
          >
            <Table 
              headers={headers}
              columns={columns}
              // when the user search using the popup and select a row
              // after then only use the hookData states from searchPopup hook
              data={searchPopup.isSelected ? searchPopup.hookData : data}
              onHeaderClick={searchPopup.handleHeaderClicked}
            >
              <span to={`/en-admin/${params.shipmentType === 'aircargo' ? 'airshipment' : 'lcl'}/${pageNo}/${docsPerPage}`}>
                <div id="details1" className="table-action cursor-pointer">
                  <i className="far fa-eye fa-lg hover-success"></i>
                </div>
                <UncontrolledTooltip
                  delay={0}
                  target="details1"
                >
                  show details
                </UncontrolledTooltip>
              </span>
            </Table>
          </Pagination>
          {searchPopup.openPopup && 
            <SearchModal
              data={data.data}
              setData={setData}
              updateData={updateData}
              searchPopup={searchPopup}
              editResponse={editResponse}
              page=""
            />
          }
        </Card>
      </Container>
      </>}
    </div>
  )
}

export default FollowUpStatus