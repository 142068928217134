import React, { useEffect, useState } from 'react'
import Table from "components/common/CustomTable";
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import customerApi from 'api/customer';
import useApi from '../../hooks/useApi';
import Spinner from '../Spinner';
import Pagination from 'components/common/Pagination';

function PopupTable({ setCustomer, setTableModal, setValue, sweetAlert, multiple = false, customer = [] }) {
  
  // States
  const [data, setData] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [client, setClient] = useState('');
  const [shipmentNo, setShipmentNo] = useState('');
  const [docsPerPage, setDocsPerPage] = useState(5);
  
  // Apis
  const customers = useApi(customerApi.customers);
  const findByName = useApi(customerApi.findByName);

  const getAllCustomers = async () => {

    const res = await customers.request(pageNo, docsPerPage);
    if (res.status === 200)
      setData(res.data.data.data)
  }

  const findClientByName = async () => {

    if (!client.trim()) return getAllCustomers();
    const res = await findByName.request(client, pageNo, docsPerPage);
    if (res.status === 200) {
      setData(res.data.data.posts)
    }
  }

  const handleSelect = obj => {
    // if multipe is true the 'customer' state should be an array to store multiple customers
    if (multiple) {
      setCustomer(customer => [...customer, obj]);
    } else {
      // otherwise store an object with the customer
      setCustomer(obj);
      setTableModal(false);
      setValue("client", obj.cusName)
    }
  }

  // this function render approve icon
  // if multiple attribute is true that means you can select multliple customers
  // always shows approve icon if the multiple if false
  // if multiple is true toggle approve icon with 'x'
  const renderApprove = !multiple ? () => true : child => {
    return !customer.find(user => user.cusId === child.cusId)
  }

  // this function render 'x' icon
  // but this will only call if multiple is false
  const renderCancel = child => {
    return customer.find(user => user.cusId === child.cusId)
  }

  const handleUnselect = obj => {
    setCustomer(customers => customers.filter(cus => cus.cusId !== obj.cusId))
  }

  useEffect(() => {
    if (client.trim().length) findClientByName();
    if (shipmentNo.trim().length) findClientByName();
    else getAllCustomers();
  }, [pageNo, docsPerPage, multiple && shipmentNo])

  useEffect(() => (customers.error && customers.data) && sweetAlert(customers.data ,true), [customers.data])
  useEffect(() => (findByName.error && findByName.data) && sweetAlert(findByName.data ,true), [findByName.data])

  return (
    <div>
      {customers.loading ? <Spinner
        gate="#29bb64"
        bg="#fff"
      />
      : <>
      {!multiple ? 
        <div className='padding-around pb-0'>
          <Row>
            <Col md="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example3cols1Input"
                >
                  Customer code
                </label>
                <Input
                  id="example3cols1Input"
                  placeholder="search"
                  type="text"
                  value={client}
                  onChange={e => setClient(e.target.value) }
                  onKeyPress={(e) => e.key === 'Enter' && findClientByName()}
                />
              </FormGroup>
            </Col>
            <span style={{paddingTop: 7}}>
              <Button 
                color="success" 
                type="button"
                className='mt-4'
                onClick={findClientByName}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-search" />
                </span>
                <span>
                  Search
                </span>
              </Button>
            </span>
          </Row>
        </div>
      : 
        <FormGroup>
          <label
            className="form-control-label"
            htmlFor="example3cols1Input"
          >
            Shipment number
          </label>
          <Input
            id="example3cols1Input"
            placeholder="search"
            type="text"
            value={shipmentNo}
            onChange={e => setShipmentNo(e.target.value) }
          />
        </FormGroup>
      }
      {multiple && 
        <ul className='popup-customer-list'>
          {customer.map(user => (
            <li 
              key={user.cusId}
            >
              <i className="fas fa-times hover-danger" onClick={handleUnselect.bind(null, user)} />
              {user.cusName}
            </li>
          ))}
        </ul>
      }
      <Pagination 
        pageNo={pageNo} 
        setPageNo={setPageNo} 
        docsPerPage={docsPerPage} 
        setDocsPerPage={setDocsPerPage}
        dataLength={data?.length || 0}
      >
        <Table
          headers={["name", "code", "actions"]}
          columns={["cusName", "cusCode"]}
          data={data}
          renderCancel={renderCancel}
          handleSelect={handleSelect}
          renderApprove={renderApprove}
          handleUnselect={handleUnselect}
        >
          <span className="me-1" fun="handleSelect" condition="renderApprove">
            <div id="approve" className="table-action cursor-pointer">
              <i className="fas fa-check fa-lg hover-info"></i>
            </div>
          </span>
          <span className="me-1" fun="handleUnselect" condition="renderCancel">
            <div id="remove" className="table-action cursor-pointer">
              <i className="fas fa-times fa-lg hover-danger padding-half" />
            </div>
          </span>
        </Table>
      </Pagination>
      </>}
    </div>
  )
}

export default PopupTable