import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  CardHeader,
} from "reactstrap";
import useApi from 'hooks/useApi';
import { useParams } from "react-router-dom";
import InvoiceApi from "api/invoice";
import Spinner from '../../../components/Spinner';
import SimpleHeader from 'components/Headers/SimpleHeader';
import Gallery from 'components/common/Gallery';
import TextPopup from 'components/common/TextModal';
import env from 'env';
import DocsCard from 'components/common/DocsCard';
import useAlert from 'hooks/useAlert';
import FilePicker from 'components/common/FilePicker';

function InvoiceDetails() {

  // params
  const { id, pgNo, docsPerPg, shipmentType } = useParams();
  // apis
  const getOneInvoice = useApi(InvoiceApi.getInvoice);
  const wrongContact = useApi(InvoiceApi.wrongContact);
  const approveSwift = useApi(InvoiceApi.approveSwift);
  const approveContacted = useApi(InvoiceApi.approveContacted);

  // states
  const [data, setData] = useState({});
  // const [alert, setAlert] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [wrongModal, setWrongModal] = useState(false);
  const [swiftModal, setSwiftModal] = useState(false);
  const [contactedPopup, setContactedPopup] = useState(false);
  const [imgs, setImgs] = useState({});

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();

  const thisStatusIndex = env.followUpStatus.findIndex(status => status.key === data.followUpStatus);

  const isFile = file => {

    const extension = file.substr(file.lastIndexOf('.') - file.length + 1);

    if (extension === 'pdf')
        return true;
    if (extension === 'docx' || extension === 'dot')
      return true;
    if (extension === 'pptx' || extension === 'ppt' || extension === 'ppsx')
      return true;
    if (extension === 'xlsx' || extension === 'xlsm' || extension === 'xls' || extension === 'xlsb')
      return true;
    return false;
  }

  const swiftFiles = [...data?.swiftImges || ""].filter(item => isFile(item));
  const swiftImges = [...data?.swiftImges || ""].filter(img => !isFile(img));
  const files = [...data?.imges || ""].filter(item => isFile(item));
  const imges = [...data?.imges || ""].filter(img => !isFile(img))

  const getInvoice = async () => {
    const res = await getOneInvoice.request(id);

    if (res.status === 200) {
      setData(res.data.data)
    } 
  }
  
  const wrongPopupHandler = e => {
    e.preventDefault();
    setWrongModal(true);
  }

  const wrongContactHandler = async () => {
    setWrongModal(false);
    setSpinner(true);
    const res = await wrongContact.request(id);
    if (res.ok) {
      const newData = {...data};
      newData.followUpStatus = 12;
      setData(newData);
      sweetAlert("Done");
    }
    setSpinner(false);
    setWrongModal(false);
  }

  const approveSwiftChecker = () => {
    if (imgs.length)
      return approveSwiftHandler();
    
    return setSwiftModal(true);
  }

  const approveSwiftHandler = async () => {
    setSwiftModal(false);
    setSpinner(true);

    const res = await approveSwift.request(id, data.shipmentType, imgs);

    if (res.ok) {
      setData({
        ...data,
        followUpStatus: 9,
        swiftImges: res.data.data.swiftImges
      })
      sweetAlert("Done")
    }
    setSpinner(false);
  }

  const contactedPopupHandler = () => {
    setContactedPopup(true);
  }

  const handleContacted = async () => {
    setContactedPopup(false);
    setSpinner(true);
    const res = await approveContacted.request(id, { shipmentType: +shipmentType });

    if (res.ok) {
      const newData = {...data};
      newData.followUpStatus = res.data.data.followUpStatus;

      setData(newData);
      sweetAlert("Done")
    } 
    setSpinner(false);
  }

  const handleCancel = () => {
    setWrongModal(false);
    setSwiftModal(false);
    setContactedPopup(false);
  }

  useEffect(() => {
    getInvoice();
  }, [])

  const contactedChecker = () => {
    // if the follow up status is initiated
    // and one of the other field is creater than 1
    // then the contacted button should appear
    if (
      data?.followUpStatus === 1 &&
      (data?.value > 0 || 
      data?.valueIwan > 0 ||
      data?.remittanceCommissionDollar > 0||
      data?.remittanceCommissionDinar > 0)
    )
    return true;
    return false;
  }

  const wrongContactChecker = () => {
    if (
      data.followUpStatus === 1 &&
      data?.value === 0 &&
      data?.valueIwan === 0 &&
      data?.remittanceCommissionDollar === 0 &&
      data?.remittanceCommissionDinar === 0
    )
    return true;
    return false;
  }

  //const approveRenderCondition = child => !child.isChecked;
  return (
    <div>
      {alert}
      {getOneInvoice.errorAlert}
      {wrongContact.errorAlert}
      {approveSwift.errorAlert}
      {spinner && <Spinner gate="#29bb64" bg="#fff" opacity />}
      {getOneInvoice.loading ? <Spinner
        gate="#29bb64"
        bg="#fff"
      />
      : <>
        <SimpleHeader parents={["Invoice details"]} backBtn={`/en-admin/invoice-management/${pgNo}/${docsPerPg}/${shipmentType}`} />
        <Container className="mt--6" fluid>
          <Card className="card-plain">
            <CardHeader>
              <div className="d-flex justify-content-between">
                <h3 className={`mb-0 text-md-left`}>Invoice details</h3>
              </div>
            </CardHeader>
            <CardBody>
              <form onSubmit={e => e.preventDefault()}>
                <Row>
                  <Col md="6" lg='4'>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        Follow up status
                      </label>
                      <Input
                        id="example3cols1Input"
                        type="text"
                        value={env.followUpStatus[thisStatusIndex]?.text || 0}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg='4'>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        Remittance Commission Dollar
                      </label>
                      <Input
                        id="example3cols1Input"
                        type="text"
                        value={data?.remittanceCommissionDollar || 0}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg='4'>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        Remittance Commission Dinar
                      </label>
                      <Input
                        id="example3cols1Input"
                        type="text"
                        value={data?.remittanceCommissionDinar || 0}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg='4'>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        Value dollar
                      </label>
                      <Input
                        id="example3cols1Input"
                        type="text"
                        value={data?.value || 0}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg='4'>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        Value yuan
                      </label>
                      <Input
                        id="example3cols1Input"
                        type="text"
                        value={data?.valueIwan || 0}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {contactedChecker() ? 
                  <Button 
                    color="success" 
                    type="submit"
                    className='mt-4'
                    onClick={contactedPopupHandler}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-link"></i>
                    </span>
                    <span>
                      Contacted
                    </span>
                  </Button>
                  : null
                }
                {wrongContactChecker() && 
                  <Button 
                    color="danger" 
                    type="submit"
                    className='mt-4'
                    onClick={wrongPopupHandler}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-times" />
                    </span>
                    <span>
                      Wrong Contact
                    </span>
                  </Button>
                }
              </form>
            </CardBody>
            <TextPopup
              modal={wrongModal}
              text="Yes"
              handleCancel={handleCancel}
              fn={wrongContactHandler}
              color='danger'
            >
              <Container>
                <h2>Are you sure This is a wrong contact ? </h2>
              </Container>
            </TextPopup>
          </Card>
          {env.followUpStatus[thisStatusIndex]?.key === 2 && 
            <Card>
              <CardHeader>
                Swift
              </CardHeader>
              <CardBody>
                  <div>
                    <FilePicker images={imgs} setImages={setImgs} />
                    {/* <Input type="file" multiple onChange={e => setImgs(e.target.files)} /> */}
                    <Button 
                      color="primary" 
                      type="submit"
                      className='mt-4'
                      onClick={approveSwiftChecker}
                    >
                      <span>
                        Swift
                      </span>
                    </Button>
                  </div>
              </CardBody>
              <TextPopup
                modal={swiftModal}
                text="Yes"
                handleCancel={handleCancel}
                fn={approveSwiftHandler}
                color='primary'
              >
                <Container>
                  <h2>Are you sure you want to approve this without an image ? </h2>
                </Container>
              </TextPopup>
            </Card>
          }
          <TextPopup
            modal={contactedPopup}
            text="Yes"
            handleCancel={handleCancel}
            fn={handleContacted}
            color='success'
          >
            <Container>
              <h2>
                Are you sure you want to send a notification to contact the seller ?
              </h2>
            </Container>
          </TextPopup>
          {swiftFiles.length ? <DocsCard title="Swift attachments" files={swiftFiles} /> : ""}
        </Container>
        <Gallery title="Swift image" imgs={swiftImges || []} />
        <Container>
          {files.length ? <DocsCard files={files} /> : ""}
        </Container>
        <Gallery imgs={imges || []} />
      </>}
    </div>
  )
}

export default InvoiceDetails;