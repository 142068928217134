import React from 'react'
import {
  Col,
  FormGroup,
  Input,
  Row
} from "reactstrap";

import content from "./content";

function EditNews({ editSchema, setEditSchema, culture }) {

  const handleChange = (key, val) => {
    setEditSchema({
      ...editSchema,
      [key]: val
    })
  }

  return (
    <div>
      <Row>
        <Col md="12">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="example3cols1Input"
            >
              {content.title[culture]}
            </label>
            <Input
              id="example3cols1Input"
              type="text"
              value={editSchema.titel || ""}
              onChange={e => handleChange('titel', e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col md="12">
        <FormGroup>
            <label
              className="form-control-label"
              htmlFor="example3cols1Input"
            >
              {content.description[culture]}
            </label>
            <Input
              id="example3cols1Input"
              type="textarea"
              value={editSchema.descraption || ""}
              onChange={e => handleChange('descraption', e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
}

export default EditNews