import client from './client';

const endpoint = '/v1/UsersPassword';

const preload = (page, docsPerPage) => client.get(`${endpoint}/preload?Page=${page}&PostsPerPage=${docsPerPage}`);

const generatePass = schema => client.post(`${endpoint}`, schema);

const getByColumn = (col, val, page, docsPerPage) => (
  client.get(`${endpoint}/getbycoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`)
)

export default {
  preload,
  generatePass,
  getByColumn
}