/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import Alternative from "views/pages/dashboards/Alternative.js";
// import Buttons from "views/pages/components/Buttons.js";
// import Calendar from "views/pages/Calendar.js";
// import Cards from "views/pages/components/Cards.js";
// import Charts from "views/pages/Charts.js";
// import Components from "views/pages/forms/Components.js";
import Dashboard from "views/pages/dashboards/Dashboard.js";
// import Elements from "views/pages/forms/Elements.js";
// import Google from "views/pages/maps/Google.js";
// import Grid from "views/pages/components/Grid.js";
// import Icons from "views/pages/components/Icons.js";
//import Lock from "views/pages/examples/Lock.js";
import Lock from "Screen/lock/lock";
//import Login from "views/pages/examples/Login.js";
import Login from "Screen/Login/Login";
//import Notifications from "views/pages/components/Notifications.js";
import Pricing from "views/pages/examples/Pricing.js";
import Profile from "views/pages/examples/Profile.js";
//import ReactBSTables from "views/pages/tables/ReactBSTables.js";
//import Register from "views/pages/examples/Register.js";
import Register from "Screen/register/register";
import RTLSupport from "views/pages/examples/RTLSupport.js";
//import Sortable from "views/pages/tables/Sortable.js";
//import Tables from "views/pages/tables/Tables.js";
import Timeline from "views/pages/examples/Timeline.js";
// import Typography from "views/pages/components/Typography.js";
// import Validation from "views/pages/forms/Validation.js";
// import Vector from "views/pages/maps/Vector.js";
// import Widgets from "views/pages/Widgets.js";

// import PersonalFiles from "Screen/personalFiles/personalFiles.js";
// import PrivateFiles from "Screen/privateFiles/privateFiles.js";
// import PublicFiles from "Screen/publicFiles/publicFiles.js";
// import BranchFiles from "Screen/branchFiles/branchFiles.js";
// import AdminstrationFiles from "Screen/adminstrationFiles/adminstrationFiles.js";
// import DivisionFiles from "Screen/divisionFiles/divisionFiles.js";
// import UnitFiles from "Screen/unitFiles/unitFiles.js";
// import ShareableFiles from "Screen/shareableFiles/shareableFiles.js";
// import FileTypes from "Screen/fileTypes/fileTypes.js";
// import GrantingPermissions from "Screen/grantingPermissions/grantingPermissions.js";
// import RemovingPermissions from "Screen/removingPermissions/removingPermissions.js";
// import NewFile from "Screen/newFile/newFile.js";
// import OpenFolder from "Screen/openFolder/openFolder.js";
// import InsideFolder from "Screen/InsideFolder/InsideFolder.js"
import MyProfile from "Screen/profile/Profile";
import Airshipment from "Screen/Airshipment/Airshipment";
// Shaheen
import News from "Screen/News/News";
import FCL from "Screen/SeaShipments/FCL/fcl";
import LCL from "Screen/SeaShipments/LCL/lcl";
import InvoiceManagement from "Screen/InvoiceManagement/invoiceManagement";
// import Gallery from "Screen/InvoiceManagement/components/gallery";
import AirshipmentDetail from "Screen/AirshipmentDetail/AirshipmentDetail";
import Customers from "Screen/Customers/Customers";
import ApproveUsers from "Screen/ApproveUsers/ApproveUsers";
import Calculator from "Screen/Calculator/Calculator";
// import Calculator1 from "Screen/Calculator/Calculator1";
import DetailFCL from "Screen/SeaShipments/FCL/DetailFCL";
import DetailLCL from "Screen/SeaShipments/LCL/DetailLCL";
import GeneratePassword from "Screen/GeneratePassword/GeneratePassword";
import UserDetails from "Screen/UserDetails/UserDetails";
// import CreateBulk from "Screen/Bulk/CreateBulk/CreateBulk";
import BulkActions from "Screen/Bulk/BulkActtions/BulkActions";
import BulkDetails from "Screen/Bulk/BulkDetails/BulkDetails";
import InvoiceDetails from "Screen/InvoiceManagement/InvoiceDetails/InvoiceDetails";
// import BulkInPackages from "Screen/BulkInPackages/BulkInPackages";
import FollowUpStatus from "Screen/FollowUpStatus/FollowUpStatus";
import Transaction from "Screen/Transaction/Transaction";
//import userActivity from "Screen/userManagment/UserActivity/userActivity";




const routes = [
  // { // single
  //   path: "/charts",
  //   name: "Charts",
  //   icon: "ni ni-chart-pie-35 text-info",
  //   component: Charts,
  //   layout: "/admin",
  // },
  // { multi
  //   collapse: true, // in case of single dont emport it or declare false
  //   name: "Dashboards",
  //   icon: "ni ni-shop text-primary",
  //   state: "dashboardsCollapse",
  //   // views: [
  //   {
  //     path: "/dashboard",
  //     name: "Dashboard",
  //     miniName: "D",
  //     component: Dashboard,
  //     layout: "/ar-admin",
  //   },
  //   {
  //     path: "/dashboard",
  //     name: "Dashboard",
  //     miniName: "D",
  //     component: Dashboard,
  //     layout: "/en-admin",
  //   },
  //   {
  //     path: "/alternative-dashboard",
  //     name: "Alternative",
  //     miniName: "A",
  //     component: Alternative,
  //     layout: "/admin",
  //   },
  // ],
  // },
    // hidden route
    // {
    //   views: [
    //     {
    //       path: "/airshipment/:id",
    //       component: AirshipmentDetail,
    //       layout: "/en-admin",
    //     },
    //   ]
    // },
  {
    path: "/dashboard",
    name: "Dashboard",
    arName: "لوحة القيادة",
    icon: "ni ni-shop text-primary",
    component: Dashboard,
    layout: "/en-admin",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    arName: "لوحة القيادة",
    icon: "ni ni-shop text-primary",
    component: Dashboard,
    layout: "/ar-admin",
  },
  /// general 

  {
        collapse: true,
        name: "auth",
        icon: "far fa-user text-orange",
        state: "galleryCollapse",
        views: [
          {
          path: "/invoice-management/:pgNo/:docsPerPg/:shipmentType/:id",
          name:"Gallery",
          component: InvoiceDetails,
          layout: "/en-admin",
          },
          {
            path: "/invoice-management/:pgNo/:docsPerPg/:shipmentType",
            name: "Default Invoice Management",
            miniName: "IM",
            component: InvoiceManagement,
            layout: "/en-admin",
          },
          {
            path: "/airshipment/:pgNo/:docsPerPg/:id",
            component: AirshipmentDetail,
            layout: "/en-admin",
          },
          {
            path: "/airshipment/:pageNo/:docsPerPage",
            component: Airshipment,
            layout: "/en-admin",
          },
          // {
          //   path: "/fcl/:id",
          //   component: DetailFCL,
          //   layout: "/en-admin",
          // },
          {
            path: "/lcl/:pgNo/:docsPerPg/:id",
            component: DetailLCL,
            layout: "/en-admin",
          },
          {
            path: "/lcl/:pgNo/:docsPerPg",
            component: LCL,
            layout: "/en-admin"
          },
          {
            path: "/userdetails/:pgNo/:docsPerPg/:id",
            component: UserDetails,
            layout: "/en-admin",
          },
          {
            path: '/bulk/:shipmentType/:pgNo/:docsPerPg/:id/',
            component: BulkDetails,
            layout: '/en-admin'
          },
          {
            path: '/bulk/:shipmentType/:pgNo/:docsPerPg',
            component: BulkActions,
            layout: '/en-admin'
          },
          {
            path: "/approveusers/:pgNo/:docsPerPg",
            component: ApproveUsers,
            layout: "/en-admin",
          },
          {
            path: "/followupstatus/:shipmentType/:pgNo/:docsPerPg",
            component: FollowUpStatus,
            layout: "/en-admin",
          },
          {
            path: "/calculater/:shipmentType",
            component: Calculator,
            layout: "/en-admin",
          },
          // {
          //   path: "/bulkinpackages/:shipmentType/:pgNo/:docsPerPg",
          //   component: BulkInPackages,
          //   layout: "/en-admin",
          // },
      ]},

    // air shipments
  {
    collapse: true,
    name: "Airshipment",
    icon: "fas fa-plane text-primary",
    state: "airshipment",
    arName: "الشحنات",
    layout: "/en-admin",
    views: [
      {
        path: "/airshipment/1/10",
        name: "Received airshipment",
        miniName: "D",
        arName: "Default airshipment",
        component: Airshipment,
        layout: "/en-admin",
      },
      {
        path: "/bulk/aircargo/1/10",
        name: "Bulk Shipment",
        miniName: "A",
        arName: "Bulk actions",
        component: BulkActions,
        layout: "/en-admin",
      },
      {
        path: "/followupstatus/aircargo/1/10",
        name: "Follow up status air",
        miniName: "F",
        arName: "Follow up status air",
        component: FollowUpStatus,
        layout: "/en-admin",
      },
      // {
      //   path: "/bulkinpackages/aircargo/1/10",
      //   name: "Bulk in packages",
      //   miniName: "p",
      //   arName: "Bulk in packages",
      //   component: BulkInPackages,
      //   layout: "/en-admin",
      // },
    ]
  },
  {
    collapse: true,
    name: "Airshipment",
    icon: "fas fa-ship text-primary",
    state: "airshipment",
    arName: "الشحنات",
    layout: "/ar-admin",
    views: [
      {
        path: "/airshipment",
        name: "Default airshipment",
        miniName: "D",
        arName: "Default airshipment",
        component: Airshipment,
        layout: "/ar-admin",
      }
    ]
  },

  // Sea Shipments
  {
    collapse: true,
    name: "Sea Shipments",
    icon: "fas fa-ship text-purple",
    state: "seaCollapse",
    layout: "/en-admin",
    views: [
      // {
      //   path: "/fcl",
      //   name: "Recived FCL",
      //   miniName: "FCL",
      //   component: FCL,
      //   layout: "/en-admin",
      // },
      {
        path: "/lcl/1/10",
        name: "Recived LCL",
        miniName: "LCL",
        component: LCL,
        layout: "/en-admin",
      },
      {
        path: "/bulk/lcl/1/10",
        name: "LCL Bulk",
        miniName: "A",
        arName: "LCL Bulk",
        component: BulkActions,
        layout: "/en-admin",
      },
      {
        path: "/followupstatus/lcl/1/10",
        name: "Follow up status LCL",
        miniName: "A",
        arName: "Follow up status LCL",
        component: FollowUpStatus,
        layout: "/en-admin",
      },
      // {
      //   path: "/bulkinpackages/lcl/1/10",
      //   name: "Bulk in packages",
      //   miniName: "p",
      //   arName: "Bulk in packages",
      //   component: BulkInPackages,
      //   layout: "/en-admin",
      // },
    ]
  },

  // News
  {
    collapse: true,
    name: "News Settings",
    icon: "fas fa-newspaper text-red",
    state: "newsCollapse",
    layout: "/en-admin",
    views: [
      {
        path: "/news",
        name: "Default News Settings",
        miniName: "NS",
        component: News,
        layout: "/en-admin",
      },
    ]
  },

  // Invoice
  {
    collapse: true,
    name: "Invoice Management",
    icon: "fas fa-file-invoice-dollar text-green",
    state: "invoiceCollapse",
    layout: "/en-admin",
    views: [
      {
        path: "/invoice-management/1/10/1",
        name: "Default Invoice Management",
        miniName: "IM",
        component: InvoiceManagement,
        layout: "/en-admin",
      },
    ]
  },
  
  // User management
  {
    collapse: true,
    name: "User management",
    icon: "fas fa-users text-orange",
    state: "usermanagement",
    arName: "ادارة المستخدمين",
    layout: "/en-admin",
    views: [
      {
        path: "/customers",
        name: "Customers",
        miniName: "C",
        arName: "Customers",
        component: Customers,
        layout: "/en-admin",
      },
      {
        path: "/approveusers/1/10",
        name: "Approve users",
        miniName: "C",
        arName: "Approve users",
        component: ApproveUsers,
        layout: "/en-admin",
      },
      {
        path: "/generatepassword",
        name: "Generate password",
        miniName: "G",
        arName: "Generate Password",
        component: GeneratePassword,
        layout: "/en-admin",
      }
    ]
  },

  // Calculating
  {
    collapse: true,
    name: "Calculator settings",
    icon: "fas fa-square-root-alt text-yellow",
    state: "calculator",
    arName: "الاحتساب",
    layout: "/en-admin",
    views: [
      {
        path: "/calculater/air",
        name: "Calculator air",
        miniName: "C",
        arName: "Calculater",
        component: Calculator,
        layout: "/en-admin",
      },
      {
        path: "/calculater/lcl",
        name: "Calculator LCL",
        miniName: "C",
        arName: "Calculater",
        component: Calculator,
        layout: "/en-admin",
      },
      {
        path: "/transaction",
        name: "Transaction",
        miniName: "T",
        arName: "Transaction",
        component: Transaction,
        layout: "/en-admin",
      },
      // {
      //   path: "/calculater",
      //   name: "Default calculator settings",
      //   miniName: "C",
      //   arName: "Calculater",
      //   component: Calculator1,
      //   layout: "/en-admin",
      // },
    ]
  },
  {
    collapse: true,
    name: "User management",
    icon: "fas fa-users text-orange",
    state: "usermanagement",
    arName: "ادارة المستخدمين",
    layout: "/ar-admin",
    views: [
      {
        path: "/customers",
        name: "Customers",
        miniName: "C",
        arName: "Customers",
        component: Customers,
        layout: "/ar-admin",
      },
      {
        path: "/approveusers",
        name: "Approve users",
        miniName: "C",
        arName: "Approve users",
        component: ApproveUsers,
        layout: "/ar-admin",
      }
    ]
  },
  {
    collapse: true,
    name: "auth",
    icon: "far fa-user text-primary",
    state: "examplesCollapse",
    views: [
      {
        path: "/pricing",
        name: "Pricing",
        miniName: "P",
        component: Pricing,
        layout: "/auth",
      },
      {
        path: "/login",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/ar-auth",

      },
      {
        path: "/login",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/en-auth",

      },
      {
        path: "/register",
        name: "Register",
        miniName: "R",
        component: Register,
        layout: "/en-auth",
      },
      {
        path: "/register",
        name: "Register",
        miniName: "R",
        component: Register,
        layout: "/ar-auth",
      },
      {
        path: "/lock",
        name: "Lock",
        miniName: "L",
        component: Lock,
        layout: "/en-auth",
      },
      {
        path: "/lock",
        name: "Lock",
        miniName: "L",
        component: Lock,
        layout: "/ar-auth",
      },
      {
        path: "/timeline",
        name: "Timeline",
        miniName: "T",
        component: Timeline,
        layout: "/admin",
      },
      {
        path: "/profile",
        name: "Profile",
        miniName: "P",
        component: Profile,
        layout: "/admin",
      },
      {
        path: "/rtl-support",
        name: "RTL Support",
        miniName: "RS",
        component: RTLSupport,
        layout: "/rtl",
      },
      
    ],
  },
  // en files
  // {
  //   collapse: true,
  //   name: "Files",
  //   arName: "ff",
  //   icon: "far fa-copy text-orange",
  //   state: "filesCollapse",
  //   layout: "/en-admin",
  //   views: [
  //     {
  //       path: "/personal-files",
  //       name: "Personal Files",
  //       miniName: "PF",
  //       component: PersonalFiles,
  //       layout: "/en-admin",
  //     },
  //     {
  //       path: "/private-files",
  //       name: "Private Files",
  //       miniName: "PF",
  //       component: PrivateFiles,
  //       layout: "/en-admin",
  //     },
  //     {
  //       path: "/Public-files",
  //       name: "Public Files",
  //       miniName: "PF",
  //       component: PublicFiles,
  //       layout: "/en-admin",
  //     },
  //   ],
  // },

  // ar files
  // {
  //   collapse: true,
  //   name: "Files",
  //   arName: "الملفات",
  //   icon: "far fa-copy text-orange",
  //   state: "filesCollapse",
  //   layout: "/ar-admin",
  //   views: [
  //     {
  //       path: "/personal-files",
  //       name: "Personal Files",
  //       arName: "الملفات الشخصية",
  //       miniName: "PF",
  //       component: PersonalFiles,
  //       layout: "/ar-admin",
  //     },
  //     {
  //       path: "/private-files",
  //       name: "Private Files",
  //       arName: "الملفات الخاصة",
  //       miniName: "PF",
  //       component: PrivateFiles,
  //       layout: "/ar-admin",
  //     },
  //     {
  //       path: "/Public-files",
  //       name: "Public Files",
  //       arName: "الملفات العامة",
  //       miniName: "PF",
  //       component: PublicFiles,
  //       layout: "/ar-admin",
  //     },
  //   ],
  // },

  // en files within a domain
  // {
  //   collapse: true,
  //   name: "Files Within A Domain",
  //   arName: "fd",
  //   icon: "far fa-file text-green",
  //   state: "domainFilesCollapse",
  //   layout: "/en-admin",
  //   views: [
  //     {
  //       path: "/branch-files",
  //       name: "Files Inside The Branch",
  //       miniName: "BF",
  //       component: BranchFiles,
  //       layout: "/en-admin",
  //     },
  //     {
  //       path: "/adminstration-files",
  //       name: "Files Inside The Adminstration",
  //       miniName: "AF",
  //       component: AdminstrationFiles,
  //       layout: "/en-admin",
  //     },
  //     {
  //       path: "/division-files",
  //       name: "Files Inside The Division",
  //       miniName: "DF",
  //       component: DivisionFiles,
  //       layout: "/en-admin",
  //     },
  //     {
  //       path: "/unit-files",
  //       name: "Files Inside The Unit",
  //       miniName: "UF",
  //       component: UnitFiles,
  //       layout: "/en-admin",
  //     },
  //   ],
  // },

  // ar files within a domain
  // {
  //   collapse: true,
  //   name: "Files Within A Domain",
  //   arName: "ملفات داخل نطاق",
  //   icon: "far fa-file text-green",
  //   state: "domainFilesCollapse",
  //   layout: "/ar-admin",
  //   views: [
  //     {
  //       path: "/branch-files",
  //       name: "Files Inside The Branch",
  //       arName: "الملفات داخل الفرع",
  //       miniName: "BF",
  //       component: BranchFiles,
  //       layout: "/ar-admin",
  //     },
  //     {
  //       path: "/adminstration-files",
  //       name: "Files Inside The Adminstration",
  //       arName: "الملفات داخل الإدارة",
  //       miniName: "AF",
  //       component: AdminstrationFiles,
  //       layout: "/ar-admin",
  //     },
  //     {
  //       path: "/division-files",
  //       name: "Files Inside The Division",
  //       arName: "الملفات داخل القسم",
  //       miniName: "DF",
  //       component: DivisionFiles,
  //       layout: "/ar-admin",
  //     },
  //     {
  //       path: "/unit-files",
  //       name: "Files Inside The Unit",
  //       arName: "الملفات داخل الوحدة",
  //       miniName: "UF",
  //       component: UnitFiles,
  //       layout: "/ar-admin",
  //     },
  //   ],
  // },


  

  // en shareable files 
  // {
  //   path: "/shareable-files",
  //   name: "Shareable Files",
  //   arName: "SF",
  //   icon: "fas fa-file-export text-yellow",
  //   layout: "/en-admin",
  //   component: ShareableFiles,
  // },

  // ar shareable files 
  // {
  //   path: "/shareable-files",
  //   name: "Shareable Files",
  //   arName: "ملفات قابلة للمشاركة",
  //   icon: "fas fa-file-export text-yellow",
  //   layout: "/ar-admin",
  //   component: ShareableFiles,
  // },

  // en Permissions
  // {
  //   collapse: true,
  //   name: "Permissions",
  //   arName: "P",
  //   icon: "fas fa-key text-blue",
  //   state: "permissionsCollapse",
  //   layout: "/en-admin",
  //   views: [
  //     {
  //       path: "/file-types",
  //       name: "File Types",
  //       miniName: "FT",
  //       component: FileTypes,
  //       layout: "/en-admin",
  //     },
  //     {
  //       path: "/granting-permissions",
  //       name: "Granting Permissions",
  //       miniName: "GP",
  //       component: GrantingPermissions,
  //       layout: "/en-admin",
  //     },
  //     {
  //       path: "/removing-permissions",
  //       name: "Removing Permissions",
  //       miniName: "RP",
  //       component: RemovingPermissions,
  //       layout: "/en-admin",
  //     },
  //     // hidden routes
  //     {
  //       path: "/:api/:id",
  //       component: InsideFolder,
  //       layout: "/en-admin",
  //     },
  //     {
  //       path: "/profile",
  //       component: MyProfile,
  //       layout: "/en-admin",
  //     },
  //   ],
  // },

  // // ar Permissions
  // {
  //   collapse: true,
  //   name: "Permissions",
  //   arName: "الصلاحيات",
  //   icon: "fas fa-key text-blue",
  //   state: "permissionsCollapse",
  //   layout: "/ar-admin",
  //   views: [
  //     {
  //       path: "/file-types",
  //       name: "File Types",
  //       arName: "أنواع الملفات",
  //       miniName: "FT",
  //       component: FileTypes,
  //       layout: "/ar-admin",
  //     },
  //     {
  //       path: "/granting-permissions",
  //       name: "Granting Permissions",
  //       arName: "منح الصلاحيات",
  //       miniName: "GP",
  //       component: GrantingPermissions,
  //       layout: "/ar-admin",
  //     },
  //     {
  //       path: "/removing-permissions",
  //       name: "Removing Permissions",
  //       arName: "إزالة الصلاحيات",
  //       miniName: "RP",
  //       component: RemovingPermissions,
  //       layout: "/ar-admin",
  //     },
  //     // hidden routes
  //     {
  //       path: "/:api/:id",
  //       component: InsideFolder,
  //       layout: "/ar-admin",
  //     },
  //     {
  //       path: "/profile",
  //       component: MyProfile,
  //       layout: "/ar-admin",
  //     },
  //   ],
  // },



  //   // en user manegment
  //   {
  //     collapse: true,
  //     name: "User Management",
  //     icon: "far fa-user text-orange",
  //     state: "examplesCollapse",
  //     layout: "/en-admin",
  //     views: [
  //       {
  //         path: "/pricing",
  //         name: "Pricing",
  //         miniName: "P",
  //         component: Pricing,
  //         layout: "/auth",
  //       },
  //       {
  //         path: "/login",
  //         name: "Login",
  //         miniName: "L",
  //         component: Login,
  //         layout: "/ar-auth",

  //       },
  //       {
  //         path: "/login",
  //         name: "Login",
  //         miniName: "L",
  //         component: Login,
  //         layout: "/en-auth",

  //       },
  //       {
  //         path: "/register",
  //         name: "Register",
  //         miniName: "R",
  //         component: Register,
  //         layout: "/en-auth",
  //       },
  //       {
  //         path: "/register",
  //         name: "Register",
  //         miniName: "R",
  //         component: Register,
  //         layout: "/ar-auth",
  //       },
  //       {
  //         path: "/lock",
  //         name: "Lock",
  //         miniName: "L",
  //         component: Lock,
  //         layout: "/en-auth",
  //       },      {
  //         path: "/lock",
  //         name: "Lock",
  //         miniName: "L",
  //         component: Lock,
  //         layout: "/ar-auth",
  //       },
  //       {
  //         path: "/timeline",
  //         name: "Timeline",
  //         miniName: "T",
  //         component: Timeline,
  //         layout: "/admin",
  //       },
  //       {
  //         path: "/profile",
  //         name: "Profile",
  //         miniName: "P",
  //         component: Profile,
  //         layout: "/admin",
  //       },
  //       {
  //         path: "/rtl-support",
  //         name: "RTL Support",
  //         miniName: "RS",
  //         component: RTLSupport,
  //         layout: "/rtl",
  //       },
  //     ],
  //   },

  // // ar user manegment
  // {
  //   collapse: true,
  //   name: "User Management",
  //   arName: "إدارة المستخدمين",
  //   icon: "far fa-user text-orange",
  //   state: "examplesCollapse",
  //   layout: "/ar-admin",
  //   views: [
  //     {
  //       path: "/pricing",
  //       arName: "إدارة صلاحيات المستخدمين",
  //       name: "Pricing",
  //       miniName: "P",
  //       component: userActivity,
  //       layout: "/ar-admin",
  //     },
  //     {
  //       path: "/login",
  //       arName: "إدارة أدوار المستخدمين",
  //       miniName: "L",
  //       component: Login,
  //       layout: "/ar-auth",

  //     },


  //     {
  //       path: "/register",
  //       name: "Register",
  //       miniName: "R",
  //       component: Register,
  //       layout: "/ar-auth",
  //     },

  //   ],
  // },

  //   // en activitys 
  //   {
  //     collapse: true,
  //     name: "Activities",
  //     arName: "الأنشطة",
  //     icon: "fas fa-history text-info",
  //     state: "componentsCollapse",
  //     layout: "/en-admin",
  //     views: [
  //       {
  //         path: "/buttons",
  //         name: "Buttons",
  //         miniName: "B",
  //         component: Buttons,
  //         layout: "/en-admin",
  //       },
  //       {
  //         path: "/cards",
  //         name: "Cards",
  //         miniName: "C",
  //         component: Cards,
  //         layout: "/en-admin",
  //       },
  //       {
  //         path: "/grid",
  //         name: "Grid",
  //         miniName: "G",
  //         component: Grid,
  //         layout: "/en-admin",
  //       },
  //       {
  //         path: "/notifications",
  //         name: "Notifications",
  //         miniName: "N",
  //         component: Notifications,
  //         layout: "/en-admin",
  //       },
  //       {
  //         path: "/icons",
  //         name: "Icons",
  //         miniName: "I",
  //         component: Icons,
  //         layout: "/en-admin",
  //       },
  //       {
  //         path: "/typography",
  //         name: "Typography",
  //         miniName: "T",
  //         component: Typography,
  //         layout: "/en-admin",
  //       },
  //       {
  //         collapse: true,
  //         name: "Multi Level",
  //         miniName: "M",
  //         state: "multiCollapse",
  //         views: [
  //           {
  //             path: "#pablo",
  //             name: "Third level menu",
  //             component: () => { },
  //             layout: "/",
  //           },
  //           {
  //             path: "#pablo",
  //             name: "Just another link",
  //             component: () => { },
  //             layout: "/",
  //           },
  //           {
  //             path: "#pablo",
  //             name: "One last link",
  //             component: () => { },
  //             layout: "/",
  //           },
  //         ],
  //       },
  //     ],
  //   },

  //     // ar activitys 
  //     {
  //       collapse: true,
  //       name: "Activities",
  //       arName: "الأنشطة",
  //       icon: "fas fa-history text-info",
  //       state: "componentsCollapse",
  //       layout: "/ar-admin",
  //       views: [
  //         {
  //           path: "/buttons",
  //           name: "Buttons",
  //           miniName: "B",
  //           component: Buttons,
  //           layout: "/en-admin",
  //         },
  //         {
  //           path: "/cards",
  //           name: "Cards",
  //           miniName: "C",
  //           component: Cards,
  //           layout: "/en-admin",
  //         },
  //         {
  //           path: "/grid",
  //           name: "Grid",
  //           miniName: "G",
  //           component: Grid,
  //           layout: "/en-admin",
  //         },
  //         {
  //           path: "/notifications",
  //           name: "Notifications",
  //           miniName: "N",
  //           component: Notifications,
  //           layout: "/en-admin",
  //         },
  //         {
  //           path: "/icons",
  //           name: "Icons",
  //           miniName: "I",
  //           component: Icons,
  //           layout: "/en-admin",
  //         },
  //         {
  //           path: "/typography",
  //           name: "Typography",
  //           miniName: "T",
  //           component: Typography,
  //           layout: "/en-admin",
  //         },
  //         {
  //           collapse: true,
  //           name: "Multi Level",
  //           miniName: "M",
  //           state: "multiCollapse",
  //           views: [
  //             {
  //               path: "#pablo",
  //               name: "Third level menu",
  //               component: () => { },
  //               layout: "/",
  //             },
  //             {
  //               path: "#pablo",
  //               name: "Just another link",
  //               component: () => { },
  //               layout: "/",
  //             },
  //             {
  //               path: "#pablo",
  //               name: "One last link",
  //               component: () => { },
  //               layout: "/",
  //             },
  //           ],
  //         },
  //       ],
  //     },

  //   // en system anegment
  //   {
  //     collapse: true,
  //     name: "System Manegment",
  //     arName: "إدارة الأنظمة",
  //     icon: "fab fa-ubuntu text-pink",
  //     state: "formsCollapse",
  //     layout: "/en-admin",

  //     views: [
  //       {
  //         path: "/elements",
  //         name: "Elements",
  //         miniName: "E",
  //         component: Elements,
  //         layout: "/en-admin",
  //       },
  //       {
  //         path: "/components",
  //         name: "Components",
  //         miniName: "C",
  //         component: Components,
  //         layout: "/en-admin",
  //       },
  //       {
  //         path: "/validation",
  //         name: "Validation",
  //         miniName: "V",
  //         component: Validation,
  //         layout: "/en-admin",
  //       },
  //     ],
  //   },
  //   // ar system anegment
  //   {
  //     collapse: true,
  //     name: "System Manegment",
  //     arName: "إدارة الأنظمة",
  //     icon: "fab fa-ubuntu text-pink",
  //     state: "formsCollapse",
  //     layout: "/ar-admin",

  //     views: [
  //       {
  //         path: "/elements",
  //         name: "Elements",
  //         miniName: "E",
  //         component: Elements,
  //         layout: "/ar-admin",
  //       },
  //       {
  //         path: "/components",
  //         name: "Components",
  //         miniName: "C",
  //         component: Components,
  //         layout: "/ar-admin",
  //       },
  //       {
  //         path: "/validation",
  //         name: "Validation",
  //         miniName: "V",
  //         component: Validation,
  //         layout: "/ar-admin",
  //       },
  //     ],
  //   },

  //   // en reports 
  //   {
  //     collapse: true,
  //     name: "Reports",
  //     arName: "التقارير",
  //     icon: "far fa-newspaper text-default",
  //     state: "tablesCollapse",
  //     layout: "/en-admin",

  //     views: [
  //       {
  //         path: "/tables",
  //         name: "Tables",
  //         miniName: "T",
  //         component: userActivity,
  //         layout: "/en-admin",
  //       },
  //       {
  //         path: "/sortable",
  //         name: "Sortable",
  //         miniName: "S",
  //         component: Sortable,
  //         layout: "/en-admin",
  //       },
  //       {
  //         path: "/react-bs-table",
  //         name: "React BS Tables",
  //         miniName: "RBT",
  //         component: ReactBSTables,
  //         layout: "/en-admin",
  //       },
  //     ],
  //   },
  //     // ar reports 
  //     {
  //       collapse: true,
  //       name: "Reports",
  //       arName: "التقارير",
  //       icon: "far fa-newspaper text-default",
  //       state: "tablesCollapse",
  //       layout: "/ar-admin",

  //       views: [
  //         {
  //           path: "/tables",
  //           name: "Tables",
  //           miniName: "T",
  //           component: Tables,
  //           layout: "/ar-admin",
  //         },
  //         {
  //           path: "/sortable",
  //           name: "Sortable",
  //           miniName: "S",
  //           component: Sortable,
  //           layout: "/ar-admin",
  //         },
  //         {
  //           path: "/react-bs-table",
  //           name: "React BS Tables",
  //           miniName: "RBT",
  //           component: ReactBSTables,
  //           layout: "/ar-admin",
  //         },
  //       ],
  //     },


  // {
  //   collapse: true,
  //   name: "Maps",
  //   icon: "ni ni-map-big text-primary",
  //   state: "mapsCollapse",
  //   views: [
  //     {
  //       path: "/google",
  //       name: "Google",
  //       miniName: "G",
  //       component: Google,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/vector",
  //       name: "Vector",
  //       miniName: "V",
  //       component: Vector,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   path: "/widgets",
  //   name: "Widgets",
  //   icon: "ni ni-archive-2 text-green",
  //   component: Widgets,
  //   layout: "/admin",
  // },
  // {
  //   path: "/charts",
  //   name: "Charts",
  //   icon: "ni ni-chart-pie-35 text-info",
  //   component: Charts,
  //   layout: "/admin",
  // },
  // {
  //   path: "/calendar",
  //   name: "Calendar",
  //   icon: "ni ni-calendar-grid-58 text-red",
  //   component: Calendar,
  //   layout: "/admin",
  // },
];

export default routes;
