const Url = 'https://gw.mail.oneify-store.com/api/v1'
const farhat = 'http://192.168.0.107/GW.Mail.WebServerApi/api/v1'
const origin = 'http://192.168.0.100:5000'
//http://192.168.0.100:3000/#/ar-auth/login
const heroku = 'https://gw-archive.herokuapp.com'
const frontUrl = 'http://localhost:3000';
const ls = 'https://archive.ggw.ly';
const netlify = 'https://gifted-snyder-ceea35.netlify.app';
const shaheen = 'https://gisloaction.gw.ly'

// exports.Url = () => Url;
// exports.farhat = () => farhat;
// exports.Origin = () => Origin;
exports.activeServer = () => `${shaheen}/api/v1`;
exports.frontUrl = () => netlify;
exports.employee = () => "https://hr.gw.ly/api/v1/Employee";
//http://192.168.0.102:5000
exports.activeServerUrl = () => heroku;
exports.identity = () => `https://identety.herokuapp.com/api/v1`
exports.swagger = () => "https://hr.gw.ly/api/v1";
// http://192.168.0.106/GW.Mail.WebServerApi/api/v1
exports.status =  {
    ar: [ 'تم انشائها', 'تم التواصل', 'تم تسديد الفاتورة', 'تم التنسيق', 'في الطريق', 'تم الاستلام', 'مدفوعة', 'تم تسليمها'],
    en: [ 'Initiated', 'Contacted', 'Paid', 'Arranged', 'Ontheway', 'Recived', 'Invoiced', 'Delivered']
};

// en: [ 'Initiated', 'Contacted', 'Recived', 'Swift']
//          1               2           6          9
exports.followUpStatus = [
    { key: 1, text: 'Initiated' },
    { key: 2, text: 'Contacted' },
    { key: 3, text: 'Paid' },
    { key: 4, text: 'Recived' },
    { key: 5, text: 'On the way'},
    { key: 6, text: 'Arranged'},
    { key: 7, text: 'Invoiced'},
    { key: 8, text: 'Delivered'},
    { key: 9, text: 'Swift' },
    { key: 10, text: 'Shipping'},
    { key: 11, text: 'Arrived'},
    { key: 13, text: 'Rest received'},
    { key: 14, text: 'Bulked'},
];

exports.followUpStatusBulk = [
    { key: 3, text: 'Shipped' },
    { key: 4, text: 'Arrived'}
]

// These Two are used for search popup
exports.followUpStatusInvoice = [
    { key: 1, text: 'Initiated' },
    { key: 2, text: 'Contacted' },
    { key: 9, text: 'Swift' },
]

exports.followUpStatusRecived = [
    { key: 4, text: 'Recived' },
    { key: 9, text: 'Swift' },
    { key: 13, text: 'Rest received'},
]

exports.colors = ['#34eb71', '#34ebe5', '#3452eb', '#eba834', '#e9f230', '#f28130', '#00ff33', 'gray']; 

exports.country = ["Uae", "Eur", "China", "Usa"];

exports.price = ["Dollar", "Yuan"];

exports.shipmentType = ["Air", "LCL", "", "FCL"];

exports.priceValue = ["kg", "cbm"];

exports.priceType = ["Plus", "normal", "custome price"];

exports.types = ["Shipping", "Transaction"];

exports.usersType = ["Personal", "Company"];

exports.pages = [5, 10, 15, 25, 50];

exports.bulkType = ['', 'Air', 'LCL'];

exports.shippingType = ['Noraml', 'Medical', 'machine CNC', 'copy'];

exports.commidityType  = ["Normal", "Copy", "Medical"]
exports.commType  = []

exports.priceRange = ["1-25k", "26-100k", "101-150k", "> 150 k"];

exports.priceRangeMedical = ["1-20k", "> 20k"]