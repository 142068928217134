import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { 
  UncontrolledTooltip, 
  Row,
  Col,
  FormGroup,
  Button,
  Input,
} from 'reactstrap';
import Table from "./CustomTable";
import TextPopup from 'components/common/TextModal';
import Pagination from './Pagination';
import env from 'env';

const SearchModal = ({ searchPopup, updateData, shipmentType, page = '' }) => {

  const [disabledPagination, setDisabledPagination] = useState(true);

  const shipmentTypeArr = ["aircargo", "lcl", "fcl"];

  const {
    searchField,
    setSearchField,
    hookData,
    openPopup,
    handleClose,
    column,
    header,
    pageNo,
    setPageNo,
    docsPerPage,
    setDocsPerPage,
    initPagination,
    handleColumnName, 
  } = searchPopup;
  
  const columns = [column];
  const headers = ['actions', header];
  // in recieved and invoice screen we need to show the order id beside
  // but if the order id is the one selected don't show another order id
  if (
    (page === 'Recived' || page === 'Invoice' || page === '')
    && column !== 'orderId'
  ) {
    columns.push('orderId');
    headers.push('order id');
  } else if (page === 'Bulk' && column !== 'bulkPckgNum') {
    headers.push('code');
    columns.push('bulkPckgNum');
  } else if (page === 'generatePassword' && column !== 'customerName') {
    headers.push('customer name');
    columns.push('customerName');
  }

  // if the column name has 'text' in the name that means it's an enum
  // remove 'text' 
  const editedColumn = useCallback(() => handleColumnName())();

  const params = useParams(); 

  const shipmentTypeText = shipmentType ? shipmentTypeArr[shipmentType - 1] : '';

  const handleSearch = async () => {   

    const args = shipmentTypeText || params.shipmentType
    
    searchPopup.onSearch(args, () => {
      setDisabledPagination(false);
    });
  }

  const handleSelect = () => {
    if (searchField)
      searchPopup.setIsSelected(true);
    updateData(hookData);
    handleClose();
  }
  
  useEffect(() => {
    initPagination();
  }, [])

  useEffect(() => {
    // This shouldn't be run if there's nothing in search field
    if (disabledPagination) return;
 
    handleSearch();
  }, [pageNo, docsPerPage])

  return (
    <TextPopup
      modal={openPopup}
      handleCancel={handleClose}
      fn={() => {}}
      color='success'
      noBtn
    >
      <div className='padding-around pb-0'>
        <Row>
          {
            editedColumn === 'followUpStatus' ? 
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="customerCode"
                  >
                    {header}
                  </label>
                  <Input
                    id="cutomerCode"
                    type="select"
                    value={searchField || true}
                    onChange={e => setSearchField(e.target.value)}
                  >
                    <option disabled value> -- select an option -- </option>
                    {/* This could be 'followUpStatus' or 'followUpStatusInvoice' or 'followUpStatusRecived' */}
                    {/* Depends on page value */}
                    {env[`followUpStatus${page}`].map((status, i) => <option key={i} value={status.key}>{status.text}</option>)}
                  </Input>
                </FormGroup>
              </Col>
            :
            column.includes("Text") && editedColumn !== 'followUpStatus' ?
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="customerCode"
                  >
                    {header}
                  </label>
                  <Input
                    id="cutomerCode"
                    type="select"
                    value={searchField || true}
                    onChange={e => setSearchField(e.target.value)}
                  >
                    <option disabled value> -- select an option -- </option>
                    {env[editedColumn].map((status, i) => <option key={i} value={i + 1}>{status}</option>)}
                  </Input>
                </FormGroup>
              </Col>
            : 
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="customerCode"
                  >
                    {header}
                  </label>
                  <Input
                    id="customerCode"
                    placeholder="search"
                    type="text"
                    onChange={e => setSearchField(e.target.value) }
                    onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                  />
                </FormGroup>
              </Col>
          }
          <span style={{paddingTop: 7}}>
            <Button 
              color="success" 
              type="button"
              className='mt-4'
              onClick={handleSearch}
              disabled={!searchField}
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-search" />
              </span>
              <span>
                Search
              </span>
            </Button>
          </span>
        </Row>
      </div>
      <Pagination
        dataLength={hookData.length}
        pageNo={pageNo} 
        setPageNo={setPageNo}
        docsPerPage={docsPerPage}
        setDocsPerPage={setDocsPerPage}
        disabled={disabledPagination}
      >
        <Table
          data={hookData}
          headers={headers}
          columns={columns}
          handleSelect={handleSelect}
        >
          <span className='me-1' fun="handleSelect">
            <div id="check" className="table-action cursor-pointer">
              <i className="far fa-check-circle hover-success fa-lg" />
            </div>
            <UncontrolledTooltip
              delay={0}
              target="check"
            >
              check
            </UncontrolledTooltip>
          </span>
        </Table>
      </Pagination>
    </TextPopup>
  )
}

export default SearchModal;